/**
 * Navigation components.
 * @module components/theme/Navigation/Navigation
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, injectIntl } from 'react-intl';
import { Menu } from 'semantic-ui-react';
import cx from 'classnames';
import { BodyClass, getBaseUrl, hasApiExpander } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { getNavigation } from '@plone/volto/actions';
import { CSSTransition } from 'react-transition-group';
import NavItems from '@plone/volto/components/theme/Navigation/NavItems';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import Icon from '../../../../components/Utils/Icon/Icon';
import { colorAdjust } from 'volto-unite-web/utils';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';

const messages = defineMessages({
  closeMobileMenu: {
    id: 'Close menu',
    defaultMessage: 'Close menu',
  },
  openMobileMenu: {
    id: 'Open menu',
    defaultMessage: 'Open menu',
  },
});
/**
 * Navigation container class.
 * @class Navigation
 * @extends Component
 */
class Navigation extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    getNavigation: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    topLinks: PropTypes.array,
    universityLinks: PropTypes.object,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
    lang: PropTypes.string.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Navigation
   */
  constructor(props) {
    super(props);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
    this.state = {
      isMobileMenuOpen: false,
    };
  }

  componentDidMount() {
    const { settings } = config;
    if (!hasApiExpander('navigation', getBaseUrl(this.props.pathname))) {
      this.props.getNavigation(
        getBaseUrl(this.props.pathname),
        settings.navDepth,
      );
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { settings } = config;
    if (
      nextProps.pathname !== this.props.pathname ||
      nextProps.token !== this.props.token
    ) {
      if (!hasApiExpander('navigation', getBaseUrl(this.props.pathname))) {
        this.props.getNavigation(
          getBaseUrl(nextProps.pathname),
          settings.navDepth,
        );
      }
    }
  }

  /**
   * Toggle mobile menu's open state
   * @method toggleMobileMenu
   * @returns {undefined}
   */
  toggleMobileMenu() {
    this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen });
  }

  /**
   * Close mobile menu
   * @method closeMobileMenu
   * @returns {undefined}
   */
  closeMobileMenu() {
    if (!this.state.isMobileMenuOpen) {
      return;
    }
    this.setState({ isMobileMenuOpen: false });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { topLinks, universityLinks, footerInfo } = this.props;
    return (
      <nav className="navigation" id="navigation" aria-label="navigation">
        <div className="hamburger-wrapper mobile tablet computer only d-flex align-items-center px-0 w-100 ms-3">
          <button
            className={cx('hamburger hamburger--spin', {
              'is-active': this.state.isMobileMenuOpen,
            })}
            aria-label={
              this.state.isMobileMenuOpen
                ? this.props.intl.formatMessage(messages.closeMobileMenu, {
                    type: this.props.type,
                  })
                : this.props.intl.formatMessage(messages.openMobileMenu, {
                    type: this.props.type,
                  })
            }
            title={
              this.state.isMobileMenuOpen
                ? this.props.intl.formatMessage(messages.closeMobileMenu, {
                    type: this.props.type,
                  })
                : this.props.intl.formatMessage(messages.openMobileMenu, {
                    type: this.props.type,
                  })
            }
            type="button"
            onClick={this.toggleMobileMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
        <Menu
          stackable
          pointing
          secondary
          className="large screen widescreen only ms-0 flex-wrap row-gap-2 align-content-center align-items-center"
          onClick={this.closeMobileMenu}
        >
          <NavItems
            items={this.props.items}
            lang={this.props.lang}
            onClick={this.closeMobileMenu}
            closeMenu={this.closeMobileMenu}
          />
          {universityLinks.links &&
            universityLinks.links.items &&
            universityLinks.links.items.length > 0 && (
              <Dropdown
                title={universityLinks.text}
                icon={
                  <Link
                    to="#"
                    aria-label={universityLinks.text}
                    className="icon-link rounded-circle d-flex justify-content-center px-2"
                  >
                    <Icon iconName="bank" size="20px" />
                    <span className="visually-hidden">Unite in</span>
                  </Link>
                }
                className="item me-2"
                key="unite-in"
              >
                <Dropdown.Menu className="rounded-4 py-2 overflow-hidden left">
                  <Dropdown.Header content={universityLinks.text} />
                  {universityLinks.links.items.map((link) => (
                    <Dropdown.Item
                      key={link.text}
                      as="a"
                      href={flattenToAppURL(link.link[0]['@id'])}
                      value={link.text}
                      text={link.text}
                      title={link.text}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {link.text}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
        </Menu>
        <CSSTransition
          in={this.state.isMobileMenuOpen}
          timeout={500}
          classNames="mobile-menu"
          unmountOnExit
        >
          <div key="mobile-menu-key" className="mobile-menu">
            <BodyClass className="has-mobile-menu-open" />
            <div className="mobile-menu-nav">
              <Menu stackable pointing secondary onClick={this.closeMobileMenu}>
                <NavItems
                  items={this.props.items}
                  lang={this.props.lang}
                  closeMenu={this.closeMobileMenu}
                />
                {universityLinks.links &&
                  universityLinks.links.items &&
                  universityLinks.links.items.length > 0 && (
                    <Dropdown
                      text={universityLinks.text}
                      className="item me-3"
                      key={universityLinks.text}
                    >
                      <Dropdown.Menu className="rounded-4 py-2 overflow-hidden left">
                        {universityLinks.links.items.map((link) => (
                          <Dropdown.Item
                            key={link.text}
                            as="a"
                            href={flattenToAppURL(link.link[0]['@id'])}
                            value={link.text}
                            text={link.text}
                            title={link.text}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {link.text}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                <div className="public-links d-flex flex-column align-items-start border-top w-100">
                  {topLinks &&
                    topLinks.map(
                      (link, index) =>
                        link.link &&
                        link.link.length > 0 && (
                          <Link
                            key={link.link[0]['id']}
                            to={link.link[0]['@id']} // link.link[0]['getURL']
                            style={{
                              '--pill-color': link.color,
                              '--pill-color-hover': colorAdjust(
                                link.color,
                                -10,
                              ),
                            }}
                            className="public-link mt-3 py-1 px-3 rounded-pill"
                          >
                            {link.text}
                          </Link>
                        ),
                    )}
                </div>
                {footerInfo.follow_us?.items?.length > 0 && (
                  <div className="social-media-links d-flex flex-wrap column-gap-3 row-gap-3 mt-3">
                    {footerInfo.follow_us.items.map((item) => (
                      <a
                        key={item.id}
                        aria-label={item.text}
                        title={item.text}
                        href={flattenToAppURL(item.link[0]['@id'])}
                        className="icon-link rounded-circle d-flex justify-content-center px-2"
                        rel="noopener noreferrer"
                        target={item.open_in_new_tab ? '_blank' : '_self'}
                      >
                        <Icon iconName={item.icon} size="20px" />
                        <span className="visually-hidden">{item.text}</span>
                      </a>
                    ))}
                  </div>
                )}
              </Menu>
            </div>
          </div>
        </CSSTransition>
      </nav>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      token: state.userSession.token,
      items: state.navigation.items,
      lang: state.intl.locale,
    }),
    { getNavigation },
  ),
)(Navigation);
