import config from '@plone/volto/registry';

export const ItemSchema = (props) => {
  return {
    title: 'Item',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'info'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        type: 'string',
      },
      info: {
        title: 'Information',
        type: 'string',
        widget: 'richtext',
      },
    },
    required: ['title'],
  };
};

export const AccordionItemSchema = (props) => {
  return {
    title: 'Accordion Item',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'color', 'items'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        type: 'string',
      },
      color: {
        title: 'Color',
        type: 'color',
        widget: 'style_simple_color',
        available_colors: config.settings.dark_colors,
        default: '#644380',
      },
      items: {
        title: 'Item',
        widget: 'object_list',
        schema: ItemSchema(props),
        activeObject: props.activeObject,
        setActiveObject: props.setActiveObject,
      },
    },
    required: ['title'],
  };
};

export const WhoIsWhoSchema = (props) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'items'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        type: 'string',
      },
      items: {
        title: 'Accordion Item',
        widget: 'object_list',
        schema: AccordionItemSchema(props),
        activeObject: props.activeObject,
        setActiveObject: props.setActiveObject,
      },
    },
    required: [],
  };
};

export default WhoIsWhoSchema;
