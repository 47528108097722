import React from 'react';

import './less/View.less';

const View = (props) => {
  const { data } = props;

  return (
    <blockquote
      className={`cite-unite border-start border-light-subtle border-5 py-2 ps-3 mb-3${
        data.highlighted ? ' h5' : ''
      }`}
      {...(data.cite_url && { cite: data.cite_url })}
    >
      {data.text && data.text.data && (
        <div
          className="mb-1"
          dangerouslySetInnerHTML={{ __html: data.text.data }}
        ></div>
      )}
      {data.footer_text && data.footer_text.data && (
        <footer className="text-secondary d-flex align-items-center">
          –{' '}
          <span
            className="ms-2"
            dangerouslySetInnerHTML={{ __html: data.footer_text.data }}
          ></span>
        </footer>
      )}
    </blockquote>
  );
};

export default View;
