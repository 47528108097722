import { BlockDataForm } from '@plone/volto/components';
import Schema from './Schema';
import PropTypes from 'prop-types';

const Video = (props) => {
  const {
    block,
    blocksConfig,
    contentType,
    data,
    navRoot,
    onChangeBlock,
    blocksErrors,
  } = props;
  const schema = Schema({ ...props });

  return (
    <BlockDataForm
      block={block}
      schema={schema}
      title={schema.title}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      onChangeBlock={onChangeBlock}
      formData={data}
      blocksConfig={blocksConfig}
      navRoot={navRoot}
      contentType={contentType}
      errors={blocksErrors}
    />
  );
};

Video.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default Video;
