import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Pagination } from 'semantic-ui-react';

import { Icon } from '@plone/volto/components';
import { When } from '@plone/volto/components/theme/View/EventDatesInfo';
import { flattenToAppURL } from '@plone/volto/helpers';

import FullCalendar from '@fullcalendar/react';
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import messages from '@mbarde/volto-fullcalendar-block/components/manage/Blocks/FullCalendar/messages';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useIntl } from 'react-intl';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Select from 'react-select';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { ImArrowDownRight2 } from 'react-icons/im';
import paginationLeftSVG from '@plone/volto/icons/left-key.svg';
import paginationRightSVG from '@plone/volto/icons/right-key.svg';

import axios from 'axios';
import moment from 'moment';

import './less/View.less';

const EventsToogleListCalendarView = (props) => {
  const { data } = props;
  const [toggle, setToggle] = useState(false);
  const [results, setResults] = useState([]);
  const [visibleResults, setVisibleResults] = useState([]);

  const [formats, setFormats] = useState([]);
  const [universities, setUniversities] = useState([]);

  const [valueFormat, setValueFormat] = useState(null);
  const [valueUniversity, setValueUniversity] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState([]);
  const [selectedDate, setSelectedDate] = useState({
    startDate: null,
    endDate: null,
  });

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [prevBatch, setPrevBatch] = useState(false);
  const [nextBatch, setNextBatch] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const maxItemsPerPage = 6;

  /* server-side rendering with FullCalendar does not work here,
     so we need to render after client-side hydration - as described here:
     https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85#option-2-lazily-show-component-with-uselayouteffect
  */
  const [isClientSide, setIsClientSide] = useState(false);

  useEffect(() => {
    setIsClientSide(true);
  }, []);

  useEffect(() => {
    // Obtenemos todos los contenidos de tipo 'unite.testimonial'
    const getResults = async () => {
      const queryURL = '/++api++/@eventsDateRange';

      let query = {
        review_state: ['published'],
        sort_on: 'start',
      };

      if (data.keywords && data.keywords.length > 0) {
        query['Subject'] = data.keywords;
      }

      if (selectedUniversity && selectedUniversity.length > 0) {
        query['university'] = selectedUniversity;
      }

      if (selectedFormat && selectedFormat.length > 0) {
        query['formats'] = selectedFormat;
      }

      try {
        let data = {
          query: query,
          extra_fields: ['eventlanguage', 'university', 'formats', 'audience'],
        };

        if (selectedDate.startDate && selectedDate.endDate) {
          data['start'] = moment(selectedDate.startDate).format(
            'YYYY/MM/DD 00:00:00',
          );
          data['end'] = moment(selectedDate.endDate).format(
            'YYYY/MM/DD 23:59:59',
          );
        } else {
          data['start'] = moment().format('YYYY/MM/DD HH:mm:ss');
          data['end'] = null;
        }

        const response = await axios.post(queryURL, { query: data });
        const items = response.data;
        setResults(items);
        setTotalPages(Math.ceil(items.length / maxItemsPerPage));
      } catch (error) {
        console.error('Error fetching eventsDateRange', error);
      }
    };

    getResults();
  }, [data.keywords, selectedDate, selectedUniversity, selectedFormat, toggle]);

  useEffect(() => {
    const page = currentPage - 1;
    setVisibleResults(
      results.slice(page * maxItemsPerPage, currentPage * maxItemsPerPage),
    );
    setNextBatch(currentPage * maxItemsPerPage < results.length);
    setPrevBatch(currentPage !== 1);
  }, [currentPage, results]);

  useEffect(() => {
    const getFormats = async () => {
      const queryURLFormats = '/++api++/@vocabularies/unite.format?b_size=-1';
      try {
        const responseFormats = await axios.get(queryURLFormats);
        setFormats(responseFormats.data.items);
      } catch (error) {
        console.error('Error fetching unite.format', error);
      }
    };

    const getUniversities = async () => {
      const queryURLUniversities =
        '/++api++/@vocabularies/unite.university?b_size=-1';
      try {
        const responseUniversities = await axios.get(queryURLUniversities);
        setUniversities(responseUniversities.data.items);
      } catch (error) {
        console.error('Error fetching unite.university', error);
      }
    };

    getFormats();
    getUniversities();
  }, []);

  const handleToggle = () => {
    setToggle(!toggle);
    setSelectedDate({
      startDate: null,
      endDate: null,
    });
    setValueFormat(null);
    setSelectedFormat([]);
    setValueUniversity(null);
    setSelectedUniversity([]);
  };

  const handleDate = (e) => {
    setSelectedDate({
      startDate: e[0],
      endDate: e[1],
    });
  };

  const handleUniversity = (e) => {
    setValueUniversity(e);
    setSelectedUniversity(e.map((item) => item.value));
  };

  const handleFormat = (e) => {
    setValueFormat(e);
    setSelectedFormat(e.map((item) => item.value));
  };

  // FullCalendar

  function handleDatesSet(arg) {
    setSelectedDate({
      startDate: arg.start,
      endDate: arg.end,
    });
  }

  const intl = useIntl();

  const fcOptions = {
    initialDate: undefined,
    plugins: [dayGridPlugin, listPlugin, timeGridPlugin],
    buttonText: {
      dayGridMonth: intl.formatMessage(messages.labelDayGridMonth),
      timeGridWeek: intl.formatMessage(messages.labelTimeGridWeek),
      timeGridDay: intl.formatMessage(messages.labelTimeGridDay),
      listDay: intl.formatMessage(messages.labelListDay),
      listWeek: intl.formatMessage(messages.labelListWeek),
      listMonth: intl.formatMessage(messages.labelListMonth),
      today: intl.formatMessage(messages.labelToday),
    },
    headerToolbar: {
      left: 'title',
      center: undefined,
      right: 'today,prev,next',
    },
    initialView: 'dayGridMonth',
    titleFormat: {
      year: 'numeric',
      month: 'long',
      day: undefined,
    },
    locales: allLocales,
    locale: intl.locale ?? 'en',
  };

  function renderEventContent(eventInfo) {
    return (
      <>
        <Link to={flattenToAppURL(eventInfo.event.extendedProps['@id'])}>
          <div className="fc-event-main-frame">
            <div className="fc-event-title-container">
              <p className="fc-event-title px-3 py-1 mb-0">
                {eventInfo.event.extendedProps.formats
                  ? `[ ${eventInfo.event.extendedProps.formats.title} ] `
                  : ''}
                {eventInfo.event.title}
              </p>
            </div>
          </div>
        </Link>
      </>
    );
  }

  // END FullCalendar

  return (
    <div className="block eventstogglelistcalendar">
      <div className="d-flex justify-content-end">
        <label htmlFor="toggle-switch" className="me-3">
          List
        </label>
        <Form.Check
          type="switch"
          id="toggle-switch"
          checked={toggle}
          onChange={handleToggle}
        />
        <label htmlFor="toggle-switch" className="ms-2">
          Calendar
        </label>
      </div>

      <Row className="mb-3 selectors">
        <Col md={4} className="mb-3">
          {!toggle && (
            <Form.Group>
              <Form.Label className="fw-bold" htmlFor="select-date">
                Date
              </Form.Label>
              <Row>
                <DatePicker
                  id="select-date"
                  selected={selectedDate.startDate}
                  onChange={handleDate}
                  placeholderText="Click to select a range date"
                  dateFormat="dd/MM/yyyy"
                  className={`rounded-pill select-start form-control form-control-lg${
                    selectedDate.startDate && selectedDate.endDate
                      ? ' has-value'
                      : ''
                  }`}
                  startDate={selectedDate.startDate}
                  endDate={selectedDate.endDate}
                  selectsRange
                  isClearable
                  showIcon
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="react-datepicker__calendar-icon"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9 7a1 1 0 0 1 1-1h5v2h-5a1 1 0 0 1-1-1M1 9h4a1 1 0 0 1 0 2H1z" />
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                    </svg>
                  }
                />
              </Row>
            </Form.Group>
          )}
        </Col>
        <Col md={4} className="mb-3">
          <Form.Group>
            <Form.Label className="fw-bold" htmlFor="select-university">
              University
            </Form.Label>
            <Select
              inputId="select-university"
              className="select-university react-select-bootstrap"
              value={valueUniversity}
              onChange={handleUniversity}
              closeMenuOnSelect={false}
              isMulti
              options={universities.map((item) => ({
                value: item.token,
                label: item.title,
              }))}
            />
          </Form.Group>
        </Col>
        <Col md={4} className="mb-3">
          <Form.Group>
            <Form.Label className="fw-bold" htmlFor="select-format">
              Format
            </Form.Label>
            <Select
              inputId="select-format"
              className="select-format react-select-bootstrap"
              value={valueFormat}
              onChange={handleFormat}
              closeMenuOnSelect={false}
              isMulti
              options={formats.map((item) => ({
                value: item.token,
                label: item.title,
              }))}
            />
          </Form.Group>
        </Col>
      </Row>

      {toggle ? (
        isClientSide && (
          <FullCalendar
            events={results}
            eventContent={renderEventContent}
            datesSet={handleDatesSet}
            {...fcOptions}
          />
        )
      ) : (
        <Row>
          {visibleResults.length === 0 && <p>No events found</p>}
          {visibleResults.map((event, index) => (
            <Col md={4} key={index} className=" mb-4">
              <Card className="h-100 border-0 event-card">
                <Card.Header className="bg-event pb-4"></Card.Header>
                <Card.Body className="d-flex flex-column justify-content-between">
                  <div>
                    <div className="d-flex flex-wrap align-items-baseline mb-2">
                      {event.Subject &&
                        event.Subject.length > 0 &&
                        event.Subject.map((item) => (
                          <a
                            className="ui label label-s"
                            href={`/search-unite?Subject=${item}`}
                          >
                            {item}
                          </a>
                        ))}
                      <Card.Text className="text-muted fs-s">
                        <When
                          start={event.start}
                          end={event.end}
                          whole_day={event.whole_day}
                          open_end={event.open_end}
                        />
                      </Card.Text>
                    </div>
                    <Card.Text className="card-title fw-bold mb-3 fs-xl">
                      {event.title}
                    </Card.Text>
                    {event.description && (
                      <Card.Text
                        className="mb-3 text-truncate-x"
                        style={{ '--text-lines': 10 }}
                      >
                        {event.description}
                      </Card.Text>
                    )}
                  </div>
                  <div>
                    {event.location && (
                      <>
                        <hr className="mt-0 mb-2" />
                        <Card.Text className="fw-bold fs-xs mb-1">
                          LOCATION
                        </Card.Text>
                        <Card.Text className="mb-3">{event.location}</Card.Text>
                      </>
                    )}
                    {event.university && (
                      <>
                        <hr className="mt-0 mb-2" />
                        <Card.Text className="fw-bold fs-xs mb-1">
                          UNIVERSITY
                        </Card.Text>
                        <Card.Text className="mb-3">
                          {event.university.title}
                        </Card.Text>
                      </>
                    )}
                    {event.formats && (
                      <>
                        <hr className="mt-0 mb-2" />
                        <Card.Text className="fw-bold fs-xs mb-1">
                          FORMAT
                        </Card.Text>
                        <Card.Text className="mb-3 d-flex flex-wrap">
                          <span className="px-3 me-2 mb-2 border border-dark rounded-pill fs-s">
                            {event.formats.title}
                          </span>
                        </Card.Text>
                      </>
                    )}
                    {event.audience && (
                      <>
                        <hr className="mt-0 mb-2" />
                        <Card.Text className="fw-bold fs-xs mb-1">
                          AUDIENCE
                        </Card.Text>
                        <Card.Text className="mb-3 d-flex flex-wrap">
                          {event.audience.map((item, index) => (
                            <span
                              key={item}
                              className="px-3 me-2 mb-2 border border-dark rounded-pill fs-s"
                            >
                              {item.title}
                            </span>
                          ))}
                        </Card.Text>
                      </>
                    )}
                    {event.eventlanguage && (
                      <>
                        <hr className="mt-0 mb-2" />
                        <Card.Text className="fw-bold fs-xs mb-1">
                          LANGUAGE
                        </Card.Text>
                        <Card.Text className="mb-3">
                          {event.eventlanguage}
                        </Card.Text>
                      </>
                    )}
                    <Card.Text className="mt-4">
                      <Link
                        className="link-readmore d-block"
                        to={flattenToAppURL(event['@id'])}
                      >
                        <ImArrowDownRight2 /> Read More
                      </Link>
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
          {totalPages > 1 && (
            <div className="pagination-wrapper">
              <Pagination
                activePage={currentPage}
                totalPages={totalPages}
                onPageChange={(e, { activePage }) => {
                  setCurrentPage(activePage);
                }}
                firstItem={null}
                lastItem={null}
                prevItem={{
                  content: <Icon name={paginationLeftSVG} size="18px" />,
                  icon: true,
                  'aria-disabled': !prevBatch,
                  className: !prevBatch ? 'disabled' : null,
                }}
                nextItem={{
                  content: <Icon name={paginationRightSVG} size="18px" />,
                  icon: true,
                  'aria-disabled': !nextBatch,
                  className: !nextBatch ? 'disabled' : null,
                }}
              />
            </div>
          )}
        </Row>
      )}
    </div>
  );
};

export default EventsToogleListCalendarView;
