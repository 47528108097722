import React from 'react';
import { Link } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const TitleTagsBlockView = ({ properties, metadata }) => {
  return (
    <>
      <Row className="mb-4">
        <Col>
          <div className="d-flex flex-wrap">
            {properties.subjects &&
              properties.subjects.length > 0 &&
              properties.subjects.map((item) => (
                <Link to={`/search-unite?Subject=${item}`} className="ui label">
                  {item}
                </Link>
              ))}
          </div>
          <h1 className="mt-0 h2">{(metadata || properties)['title'] || ''}</h1>
        </Col>
      </Row>
    </>
  );
};

export default TitleTagsBlockView;
