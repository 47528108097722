import TestimonialCard from '../../components/Testimonial/TestimonialCard';

import { Container } from 'semantic-ui-react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const UniteTestimonialBlockView = (props) => {
  const { properties } = props;

  if (
    !properties ||
    !(
      properties['@type'] === 'unite_testimonial' ||
      props.contentType === 'unite_testimonial'
    )
  ) {
    return (
      <div>This block is reserved for Unite Testimonial content type only.</div>
    );
  }

  return (
    <Container>
      <Row>
        <Col sm={4}>
          <TestimonialCard key={properties.UID} item={properties} />
        </Col>
      </Row>
    </Container>
  );
};

export default UniteTestimonialBlockView;
