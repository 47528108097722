import PropTypes from 'prop-types';
import config from '@plone/volto/registry';
import { FormattedMessage } from 'react-intl';
import { Icon } from '@plone/volto/components';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { useSelector, shallowEqual } from 'react-redux';

import userSVG from '@plone/volto/icons/user.svg';
import addUserSVG from '@plone/volto/icons/add-user.svg';

const Anontools = () => {
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const content = useSelector((state) => state.content.data, shallowEqual);

  const { settings } = config;
  return (
    !token && (
      <>
        <Link
          aria-label="login"
          to={`/login${
            content?.['@id']
              ? `?return_url=${flattenToAppURL(content['@id'])}`
              : ''
          }`}
          className="icon-link rounded-circle d-flex justify-content-center px-2"
        >
          <Icon name={userSVG} size="20px" />
          <span className="visually-hidden">
            <FormattedMessage id="Log in" defaultMessage="Log in" />
          </span>
        </Link>
        {settings.showSelfRegistration && (
          <Link
            aria-label="register"
            to="/register"
            className="icon-link rounded-circle d-flex justify-content-center px-2"
          >
            <Icon name={addUserSVG} size="20px" />
            <span className="visually-hidden">
              <FormattedMessage id="Register" defaultMessage="Register" />
            </span>
          </Link>
        )}
      </>
    )
  );
};

export default Anontools;

Anontools.propTypes = {
  token: PropTypes.string,
  content: PropTypes.shape({
    '@id': PropTypes.string,
  }),
};

Anontools.defaultProps = {
  token: null,
  content: {
    '@id': null,
  },
};
