export const AgoraFeaturedCatalogueBlockSchema = (props) => {
  return {
    title: 'Featured Catalogue Block',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'catalogues', 'btn_text', 'link', 'openInNewTab'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        type: 'string',
      },
      catalogues: {
        title: 'Select Catalogues',
        type: 'array',
        widget: 'array',
        vocabulary: {
          '@id': '/@vocabularies/unite.agora_catalogues',
        },
      },
      btn_text: {
        title: 'Button text',
        widget: 'text',
      },
      link: {
        title: 'Button URL',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      openInNewTab: {
        title: 'Open link in new tab',
        type: 'boolean',
      },
    },
    required: ['catalogues'],
  };
};

export default AgoraFeaturedCatalogueBlockSchema;
