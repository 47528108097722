import config from '@plone/volto/registry';

export const BoxMediaItemSchema = (props) => {
  return {
    title: 'Item',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'title',
          'description',
          'image',
          'image_alt',
          'showMediaIcon',
          'link',
          'openInNewTab',
        ],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      description: {
        title: 'Description',
        widget: 'richtext',
      },
      image: {
        title: 'Image',
        description: 'This block is designed to insert a vertical image',
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
        selectedItemAttrs: ['image_field', 'image_scales'],
        allowedContentTypes: ['Image'],
      },
      image_alt: {
        title: 'Image alt',
      },
      showMediaIcon: {
        title: 'Show media icon',
        type: 'boolean',
      },
      link: {
        title: 'URL',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      openInNewTab: {
        title: 'Open link in new tab',
        type: 'boolean',
      },
    },
    required: ['title', 'description', 'image'],
  };
};

export const BoxMediaSchema = (props) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'items'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      items: {
        title: 'Items',
        widget: 'object_list',
        schema: BoxMediaItemSchema(props),
        activeObject: props.activeObject,
        setActiveObject: props.setActiveObject,
      },
    },
    required: ['title'],
  };
};

export default BoxMediaSchema;
