import React from 'react';

import './less/View.less';

const View = (props) => {
  const { data } = props;

  return (
    <div
    className="block-color-unite text-decoration-none mb-3 rounded-4 text-white"
      style={{
        '--block-bg': data.color || '#644380',
      }}
    >
      <p className="h4 mb-3">{data.title}</p>
      {data.text && (
        <div dangerouslySetInnerHTML={{ __html: data.text.data }} />
      )}
    </div>
  );
};

export default View;
