/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isInternalURL } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';

import ImageWithTextBelow from '../../components/ImageWithTextBelow/ImageWithTextBelow';
import Slider from '../../components/Slider/Slider';

import { ImArrowDownRight2 } from 'react-icons/im';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from 'axios';

import './less/View.less';

const View = (props) => {
  const { data } = props;
  const [results, setResults] = useState([]);

  useEffect(() => {
    // Obtenemos todos los contenidos de los criterios de la query
    const getResults = async () => {
      const queryURL = '/++api++/@querystring-search';

      try {
        data.querystring = {
          ...data.querystring,
          metadata_fields: '_all',
          fullobjects: 'True',
          b_size: 100,
        };
        const response = await axios.post(queryURL, data.querystring);

        const items = response.data.items;
        setResults(items);
      } catch (error) {
        console.error('Error fetching newsevents', error);
      }
    };

    getResults();
  }, [data]);

  let resultsHtml = [];
  results.map((item) =>
    resultsHtml.push(
      <ImageWithTextBelow item={item} />,
    ),
  );

  return (
    <div className="newsevents-wrapper mb-unite">
      {resultsHtml && resultsHtml.length > 0 && (
        <>
          <Row className="mb-5">
            <Col md={12}>
              <Row className="mb-4">
                <Col md={5} className="mb-sm-4">
                  {data.title ? <h2>{data.title}</h2> : null}
                </Col>
                <Col md={{ span: 6, offset: 1 }}>
                  {data.description ? <p>{data.description}</p> : null}
                </Col>
              </Row>
              <Row>
                <Col
                  md={6}
                  className="d-flex justify-content-start align-items-end"
                >
                  {data.link &&
                    data.btn_text &&
                    (isInternalURL(data.link[0]?.['@id']) ? (
                      <Link
                        to={flattenToAppURL(data.link[0]?.['@id'])}
                        {...(data.openInNewTab && { target: '_blank' })}
                        className="link-readmore"
                      >
                        <ImArrowDownRight2 className="me-2" />
                        {data.btn_text}
                      </Link>
                    ) : (
                      <a
                        href={data.link[0]?.['@id']}
                        rel="noopener noreferrer"
                        {...(data.openInNewTab && { target: '_blank' })}
                        className="text-block"
                      >
                        <ImArrowDownRight2 className="me-2" />
                        {data.btn_text}
                      </a>
                    ))}
                </Col>
              </Row>
            </Col>
          </Row>
          <Slider id="newsevents" items={resultsHtml} items_col="4" items_md_col="6" />
        </>
      )}
    </div>
  );
};

export default View;
