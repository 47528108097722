export const schemaRepresentatives = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'keywords', 'filters'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      keywords: {
        title: 'Keywords',
        type: 'array',
        widget: 'array',
        items: {
          vocabulary: { '@id': 'plone.app.vocabularies.Keywords' },
        },
      },
      filters: {
        title: 'Representative categories filters',
        type: 'array',
        widget: 'array',
        items: {
          vocabulary: { '@id': 'unite.representative_categories' },
        },
      },
    },
  };
};

export default schemaRepresentatives;
