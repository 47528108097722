import React, { useContext } from 'react';
import config from '@plone/volto/registry';
import { Link } from 'react-router-dom';
import { isInternalURL } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';

import { ImArrowDownRight2 } from 'react-icons/im';

import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import './less/View.less';

function MoreProjectToggle({ eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const isCurrentEventKey = activeEventKey === eventKey;

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  return (
    <Button variant="primary" onClick={decoratedOnClick}>
      {isCurrentEventKey ? 'View less' : 'View more'}
    </Button>
  );
}

const ProjectItem = ({ item }) => {
  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;

  return (
    <Col
      xl={4}
      lg={6}
      md={6}
      sm={12}
      key={item}
      className="d-flex align-items-stretch"
    >
      <Card className="mb-3 w-100">
        {item.image && (
          <Card.Header>
            {isInternalURL(item.link[0]?.['@id']) ? (
              <>
                <Link
                  className="text-block-moreinfo"
                  to={flattenToAppURL(item.link[0]?.['@id'])}
                  {...(item.openInNewTab && {
                    target: '_blank',
                  })}
                  aria-labelledby={item?.description_link}
                >
                  <PreviewImage
                    item={item.image[0]}
                    alt={item.image_alt}
                    image_field="image"
                    imageField="image"
                    className="ui image rounded-4 w-100"
                    responsive={true}
                    loading="lazy"
                  />
                </Link>
              </>
            ) : (
              <>
                <a
                  className="text-block-moreinfo"
                  href={item.link[0]?.['@id']}
                  rel="noopener noreferrer"
                  {...(item.openInNewTab && {
                    target: '_blank',
                  })}
                  aria-labelledby={item?.description_link}
                >
                  <PreviewImage
                    item={item.image[0]}
                    alt={item.image_alt}
                    image_field="image"
                    imageField="image"
                    className="ui image rounded-4 w-100"
                    responsive={true}
                    loading="lazy"
                  />
                </a>
              </>
            )}
          </Card.Header>
        )}
        <Card.Body>
          <Card.Title className="mt-0 pb-2 border-bottom">
            {item?.title}
          </Card.Title>
          <Card.Text>
            {item.description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: item.description.data,
                }}
              />
            )}
          </Card.Text>
        </Card.Body>
        {item.link && (
          <Card.Footer>
            {isInternalURL(item.link[0]?.['@id']) ? (
              <Link
                className="text-block-moreinfo"
                to={flattenToAppURL(item.link[0]?.['@id'])}
                {...(item.openInNewTab && {
                  target: '_blank',
                })}
                aria-labelledby={item?.description_link}
              >
                <ImArrowDownRight2 className="me-2" /> More Info
              </Link>
            ) : (
              <a
                className="text-block-moreinfo"
                href={item.link[0]?.['@id']}
                rel="noopener noreferrer"
                {...(item.openInNewTab && {
                  target: '_blank',
                })}
                aria-labelledby={item?.description_link}
              >
                <ImArrowDownRight2 className="me-2" /> More Info
              </a>
            )}
          </Card.Footer>
        )}
      </Card>
    </Col>
  );
};

const View = (props) => {
  const { data } = props;

  return (
    <Row className="mb-unite projects-wrapper">
      <Col md={12} className="mb-3 w-100">
        {data.title && <h2>{data.title}</h2>}
        {data.description && (
          <div dangerouslySetInnerHTML={{ __html: data.description.data }} />
        )}
      </Col>
      {data.ProjectsBlocks &&
        data.ProjectsBlocks.length > 0 &&
        (data.num_principal_projects &&
        data.num_principal_projects < data.ProjectsBlocks.length ? (
          <Col md={12}>
            <Row>
              {data.ProjectsBlocks.slice(0, data.num_principal_projects).map(
                (item, index) => (
                  <ProjectItem key={index} item={item} />
                ),
              )}
            </Row>
            <Accordion>
              <div className="d-flex justify-content-center w-100">
                <MoreProjectToggle eventKey="0" />
              </div>
              <Accordion.Collapse eventKey="0">
                <Row className="mt-3">
                  {data.ProjectsBlocks.slice(
                    data.num_principal_projects,
                    data.ProjectsBlocks.length,
                  ).map((item, index) => (
                    <ProjectItem key={index} item={item} />
                  ))}
                </Row>
              </Accordion.Collapse>
            </Accordion>
          </Col>
        ) : (
          data.ProjectsBlocks.map((item, index) => (
            <ProjectItem key={index} item={item} />
          ))
        ))}
    </Row>
  );
};

export default View;
