export const ItemSettingsSchema = (props) => {
  return {
    title: 'Items',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'text'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        type: 'string',
      },
      text: {
        title: 'Text',
        type: 'string',
        widget: 'richtext',
      },
    },
    required: ['title', 'text'],
  };
};

export const schemaVinculatedColumn = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['column_left', 'column_right', 'items'],
      },
    ],
    properties: {
      column_left: {
        title: 'Title Column Left',
        type: 'string',
      },
      column_right: {
        title: 'Title Column Right',
        type: 'string',
      },
      items: {
        title: 'Items',
        widget: 'object_list',
        schema: ItemSettingsSchema(props),
        activeObject: props.activeObject,
        setActiveObject: props.setActiveObject,
      },
    },
  };
};

export default schemaVinculatedColumn;
