import React from 'react';

import config from '@plone/volto/registry';

const PreviewImagePT = ({ item, className, alt, imageField, ...rest }) => {
  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;
  if (item[imageField]?.data) {
    return (
      <img
        src={`data:${item[imageField]['content-type']};base64,${item[imageField].data}`}
        alt={alt}
        className={className}
        loading="lazy"
        {...rest}
      />
    );
  } else {
    let image_field =
      item[imageField]?.scales || item.image_scales ? imageField : null;
    if (item.image_scales) {
      item[imageField] = item.image_scales[imageField][0];
    }

    return (
      <PreviewImage
        item={item}
        alt={alt}
        className={className}
        imageField={image_field}
        image_field={image_field}
        responsive={true}
        loading="lazy"
        {...rest}
      />
    );
  }
};

export default PreviewImagePT;
