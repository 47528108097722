// Este es un schema normal. Servirá de schema para los subbloques.
export const BoxesSettingsSchema = (props) => {
  return {
    title: 'Boxes',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['number', 'title', 'description', 'link', 'description_link', 'openInNewTab'],
      },
    ],
    properties: {
      number: {
        title: 'Number',
        widget: 'textarea',
      },
      title: {
        title: 'Title',
        widget: 'textarea',
      },
      description: {
        title: 'Description',
        widget: 'textarea',
      },
      link: {
        title: 'Internal Link',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      description_link: {
        title: 'Description Link',
        widget: 'text',
      },
      openInNewTab: {
        title: 'Open link in new tab',
        type: 'boolean',
      },
    },
    required: [],
  };
};

/*
Este schema engloba sus propios campos (Título del bloque + Color de fondo)
Y además da una lista de bloques de 'texto' de tamaño indefinido. Se
Crea un campo para esto, con widget 'object_list' y se le asigna un schema para
cada objeto (o subloque) de esta lista
*/

export const BoxesBlockSchema = (props) => {
  return {
    title: 'Boxes Block',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'image', 'image_alt', 'boxesBlocks'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      image: {
        title: 'Image',
        description: 'This block is designed to insert a vertical image',
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
        selectedItemAttrs: ['image_field', 'image_scales'],
        allowedContentTypes: ['Image'],
      },
      image_alt: {
        title: 'Image alt',
      },
      boxesBlocks: {
        title: 'Boxes Blocks',
        widget: 'object_list',
        schema: BoxesSettingsSchema(props),
        activeObject: props.activeObject,
        setActiveObject: props.setActiveObject,
      },
    },
    required: [],
  };
};

export default BoxesBlockSchema;
