import config from '@plone/volto/registry';
// Este es un schema normal. Servirá de schema para los subbloques.
export const AssociatePartnersSettingsSchema = (props) => {
  return {
    title: 'Associate Partners',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'name',
          'location',
          'logo',
          'negative_logo',
          'link',
          'description_link',
          'hexcolor',
        ],
      },
    ],
    properties: {
      name: {
        title: 'Name',
        widget: 'textarea',
      },
      location: {
        title: 'City / Country',
        widget: 'textarea',
      },
      logo: {
        title: 'Logo',
        description: 'The logo of the associate partner',
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
        selectedItemAttrs: ['image_field', 'image_scales'],
        allowedContentTypes: ['Image'],
      },
      negative_logo: {
        title: 'Negative logo',
        description: 'The negative logo of the associate partner',
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
        selectedItemAttrs: ['image_field', 'image_scales'],
        allowedContentTypes: ['Image'],
      },
      link: {
        title: 'Link',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      description_link: {
        title: 'Description Link',
        widget: 'text',
      },
      hexcolor: {
        title: 'Color',
        description: 'Hexadecimal color code. By default: #644380',
        type: 'color',
        widget: 'style_simple_color',
        available_colors: config.settings.dark_colors,
      },
    },
    required: ['name', 'logo', 'link'],
  };
};

/*
Este schema engloba sus propios campos (Título del bloque + Color de fondo)
Y además da una lista de bloques de 'texto' de tamaño indefinido. Se
Crea un campo para esto, con widget 'object_list' y se le asigna un schema para
cada objeto (o subloque) de esta lista
*/

export const AssociatePartnersBlockSchema = (props) => {
  return {
    title: 'AssociatePartners Block',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'associatePartnersBlocks'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      associatePartnersBlocks: {
        title: 'AssociatePartners Blocks',
        widget: 'object_list',
        schema: AssociatePartnersSettingsSchema(props),
        activeObject: props.activeObject,
        setActiveObject: props.setActiveObject,
      },
    },
    required: [],
  };
};

export default AssociatePartnersBlockSchema;
