import React, { useEffect, useRef, useState } from 'react';

import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowRight } from 'react-icons/bs';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import './less/Slider.less';

/**
 * Componente Slider
 *
 * @param {string} id - El identificador único del slider. Es útil para casos en los que hay múltiples sliders en una misma página.
 * @param {Array} items - Un array de cards HTML que representan los elementos del slider.
 * @param {number} items_col - El número de col que ocupara cada elemento.
 * @param {number} [items_md_col] - El número de col que ocupara cada elemento en md.
 * @param {number} [items_xs_col] - El número de col que ocupara cada elemento en sm.
 * @param {boolean} [rotate_color] - Booleano que indica si se debe rotar el color de fondo de las cards del slider.
 */

const Slider = ({
  id,
  items,
  items_col,
  items_md_col = null,
  items_xs_col = null,
  rotate_color = false,
}) => {
  const [sliderCurrent, setSliderCurrent] = useState(1);
  const [sliderItemWidth, setSliderItemWidth] = useState(0);
  const [sliderContainerWidth, setSliderContainerWidth] = useState(0);
  const [enableSlider, setEnableSlider] = useState(false);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const refContainer = useRef(null);
  const refItem = useRef(null);

  const updateSliderItemWidth = () => {
    setSliderContainerWidth(
      refContainer.current ? refContainer.current.offsetWidth + 20 : 0,
    );
    setSliderItemWidth(
      refItem.current
        ? parseFloat(refItem.current.getBoundingClientRect().width.toFixed(2))
        : 0,
    );
  };

  const updateSliderItemWidthAndReset = () => {
    updateSliderItemWidth();
    setSliderCurrent(1);
  };

  useEffect(() => {
    updateSliderItemWidth();
    window.addEventListener('resize', updateSliderItemWidthAndReset);
    window.addEventListener('orientationchange', updateSliderItemWidthAndReset);
  }, []);

  useEffect(() => {
    updateSliderItemWidth();
    setEnableSlider(
      Math.floor(sliderContainerWidth / sliderItemWidth) < items.length,
    );
  }, [items.length, sliderContainerWidth, sliderItemWidth]);

  const total_items = items.length;

  const updateSliderCurrent = (increment) => {
    let newCurrent = sliderCurrent + increment;
    if (newCurrent > total_items) {
      newCurrent = 1;
    } else if (newCurrent === 0) {
      newCurrent = total_items;
    }
    setSliderCurrent(newCurrent);
  };

  const handleLeftClick = () => {
    updateSliderCurrent(-1);
    updateSliderItemWidth();
  };

  const handleRightClick = () => {
    updateSliderCurrent(1);
    updateSliderItemWidth();
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart > touchEnd + 75) {
      handleRightClick();
    }

    if (touchStart + 75 < touchEnd) {
      handleLeftClick();
    }
  };

  return (
    <div className={`d-flex w-100 flex-row unite-slider unite-slider-${id}`}>
      {enableSlider && (
        <div className="slider-info me-5 d-flex flex-column row-gap-3">
          <div className="slider-arrows d-flex flex-column row-gap-3">
            <BsArrowRight
              size={48}
              className="slider-btn-right"
              onClick={handleRightClick}
            />
            <BsArrowLeft
              size={48}
              className="slider-btn-left"
              onClick={handleLeftClick}
            />
          </div>
          <div className="slider-number w-100 text-center fs-s">
            <span className="slider-current">{sliderCurrent}</span> /{' '}
            <span className="slider-total">{total_items}</span>
          </div>
        </div>
      )}
      <div className="slider-results-container flex-grow-1 mx-1 overflow-hidden">
        <Row
          className={`slider-results flex-grow-1 d-flex flex-nowrap ${
            rotate_color ? 'bgcolor-rotate' : ''
          }`}
          style={{
            transform: `translateX(-${
              (sliderCurrent - 1) * sliderItemWidth
            }px)`,
          }}
          ref={refContainer}
        >
          {items.map((item, index) => (
            <Col
              lg={items_col}
              md={items_md_col || items_col}
              xs={items_xs_col || null}
              key={index}
              className={`slider-item slider-item-${index}${
                rotate_color ? ' bgcolor-item-rotate' : ''
              }`}
              ref={index === 1 ? refItem : null}
              {...(enableSlider
                ? {
                    onTouchStart: handleTouchStart,
                    onTouchMove: handleTouchMove,
                    onTouchEnd: handleTouchEnd,
                  }
                : {})}
            >
              {item}
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Slider;
