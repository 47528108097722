import React, { useEffect, useState } from 'react';
import ClampLines from 'react-clamp-lines';
import { Link } from 'react-router-dom';
import { isInternalURL } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';

import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { ImArrowDownRight2 } from 'react-icons/im';
import { PiArrowDownRightThin } from 'react-icons/pi';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';

import './less/View.less';
import PreviewImagePT from '../../Utils/PreviewImage/PreviewImagePT';

const View = (props) => {
  const { data } = props;
  const [index, setIndex] = useState(0);
  const [loadedIndex, setLoadedIndex] = useState([0]);

  const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);
  const token = Math.random().toString(36).substring(2, 10);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
    if (!loadedIndex.includes(selectedIndex)) {
      setLoadedIndex((prevIndex) => [...prevIndex, selectedIndex]);
    }
  };

  const updateTextContainerHeight = () => {
    const carousel = document.querySelector('.carousel-slider-home');
    const textColumn = document.querySelector('.text-column');

    if (carousel && textColumn) {
      textColumn.style.maxHeight = `${carousel.offsetHeight}px`;
    }
  };

  useEffect(() => {
    updateTextContainerHeight();
    window.addEventListener('resize', updateTextContainerHeight);
    if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      setPrefersReducedMotion(true);
    }
  }, []);

  const hasValidTitle = (html) => {
    if (!html) return false;
    // Elimina espacios y etiquetas HTML
    const cleanText = html.replace(/<[^>]*>/g, '').trim();
    // No renderiza si está vacío o es solo un <p> vacío
    return cleanText.length > 0;
  };

  return (
    <>
      <Row className="mb-5 mb-md-4 carousel-slider-wrapper">
        <Col md={data.onlySliders ? 12 : 11} className="mb-2 mb-md-0">
          <Carousel
            className="carousel-slider-home rounded-4"
            activeIndex={index}
            onSelect={handleSelect}
            interval={null}
            prevIcon={<BsArrowLeft size={48} className="slider-btn-left" />}
            nextIcon={<BsArrowRight size={48} className="slider-btn-right" />}
          >
            {data.sliderBlocks &&
              data.sliderBlocks.map((slider, itemIndex) => (
                <Carousel.Item
                  key={itemIndex}
                  data-index={itemIndex}
                  className="rounded-4 overflow-hidden"
                  data-prefetch={
                    itemIndex === index + 1 || itemIndex === index - 1
                  }
                >
                  {loadedIndex.includes(itemIndex) && (
                    <>
                      {slider.image && slider.image[0] ? (
                        <PreviewImagePT
                          item={slider.image[0]}
                          className="carousel-resource"
                          alt={slider.image_alt || ''}
                          imageField="image"
                          loading={itemIndex === 0 ? 'eager' : 'lazy'}
                          decoding="async"
                        />
                      ) : slider.video && slider.video[0] ? (
                        <>
                          <div
                            id={`desc${index}-${token}`}
                            className="visually-hidden"
                          >
                            {slider.video_description}
                          </div>
                          <video
                            className="carousel-resource"
                            autoPlay={!prefersReducedMotion}
                            controls={prefersReducedMotion}
                            loop
                            muted
                            playsInline
                            preload="metadata"
                            aria-describedby={`desc${index}-${token}`}
                          >
                            <source
                              src={
                                slider.video[0]['@id'].includes(
                                  '/@@download/file',
                                )
                                  ? slider.video[0]['@id']
                                  : `${slider.video[0]['@id']}/@@download/file`
                              }
                              type={slider.video[0].mime_type}
                              allowFullScreen
                            />
                            Your browser does not support the video tag.
                          </video>
                        </>
                      ) : null}
                    </>
                  )}
                  <Carousel.Caption className="text-start align-content-end">
                    <Row>
                      <Col md={5} className="align-content-end">
                        {hasValidTitle(slider.title?.data) && (
                          <h2
                            className="slider-title mb-2 mb-md-0"
                            dangerouslySetInnerHTML={{
                              __html: slider.title.data,
                            }}
                          ></h2>
                        )}
                      </Col>
                      <Col
                        md={{ span: 4, offset: 3 }}
                        className="align-content-end"
                      >
                        <div className="mb-2 mb-md-0">
                          {slider.description ? (
                            <p
                              className="text-truncate-x"
                              style={{ '--text-lines': 5 }}
                            >
                              {slider.description}
                            </p>
                          ) : null}
                        </div>
                        <div className="slider-block-moreinfo fw-bolder">
                          {slider.link &&
                            (isInternalURL(slider.link[0]?.['@id']) ? (
                              <>
                                <Link
                                  className="slider-text-block-moreinfo"
                                  to={flattenToAppURL(slider.link[0]?.['@id'])}
                                  {...(slider.openInNewTab && {
                                    target: '_blank',
                                  })}
                                >
                                  <ImArrowDownRight2 className="me-2" />
                                  {slider.text_link || 'More Information'}
                                </Link>
                              </>
                            ) : (
                              <>
                                <a
                                  className="slider-text-block-moreinfo"
                                  href={slider.link[0]?.['@id']}
                                  rel="noopener noreferrer"
                                  {...(slider.openInNewTab && {
                                    target: '_blank',
                                  })}
                                >
                                  <ImArrowDownRight2 className="me-2" />
                                  {slider.text_link || 'More Information'}
                                </a>
                              </>
                            ))}
                        </div>
                      </Col>
                    </Row>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
          </Carousel>
        </Col>
        {!data.onlySliders && (
          <Col md={1}>
            {data.link && (
              <Link
                to={flattenToAppURL(data.link[0]?.['@id'] || '#')}
                {...(data.openInNewTab && { target: '_blank' })}
                className="h-100 rounded-4 text-column d-flex flex-column justify-content-between align-items-center"
              >
                <div className="mt-3 mb-4 circle-container">
                  <div className="circle-text-container rounded-circle d-flex align-items-center justify-content-center">
                    <span className="circle-text fs-xxxs fw-bold">
                      {data.label}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center text-truncate flex-grow-1 flex-shrink-1 text-container">
                  <p className="vertical-text h3">{data.text}</p>
                </div>
                <div className="mb-3 mt-4 arrow-container">
                  <PiArrowDownRightThin size={40} />
                </div>
              </Link>
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

export default View;
