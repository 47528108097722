export const schemaSuggestionBox = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['description', 'btn_text', 'link', 'openInNewTab'],
      },
    ],
    properties: {
      description: {
        title: 'Description',
        widget: 'textarea',
      },
      btn_text: {
        title: 'Button text',
        widget: 'text',
      },
      link: {
        title: 'Button URL',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      openInNewTab: {
        title: 'Open link in new tab',
        type: 'boolean',
      },
    },
  };
};

export default schemaSuggestionBox;
