export const ImagesSettingsSchema = (props) => {
  return {
    title: 'Image',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['image', 'image_alt', 'image_caption'],
      },
    ],
    properties: {
      image: {
        title: 'Image',
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
        selectedItemAttrs: ['image_field', 'image_scales'],
        allowedContentTypes: ['Image'],
      },
      image_alt: {
        title: 'Image alt',
      },
      image_caption: {
        title: 'Image caption',
      },
    },
    required: ['image'],
  };
};

export const NewsItemBlockSchema = (props) => {
  return {
    title: 'News Item Block',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['images'],
      },
    ],
    properties: {
      images: {
        title: 'News Item Blocks',
        widget: 'object_list',
        schema: ImagesSettingsSchema(props),
        activeObject: props.activeObject,
        setActiveObject: props.setActiveObject,
      },
    },
    required: [],
  };
};

export default NewsItemBlockSchema;
