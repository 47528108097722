import React from 'react';

import './less/View.less';

const View = (props) => {
  const { data, properties } = props;

  return (
    <h1 className="visually-hidden visually-view-in-edit documentFirstHeading">
      {data.text || properties.title}
    </h1>
  );
};

export default View;
