import React, { useEffect, useState } from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import './less/View.less';

const VinculatedColumnView = (props) => {
  const { data } = props;

  const [selectedItem, setSelectedItem] = useState(0);

  return (
    <div className="vinculated-column-wrapper mb-unite">
      <div className="d-none d-lg-block d-xl-block">
        <Row>
          <Col xl={3} lg={6} className="mb-3">
            {(data.column_left || data.column_right) && (
              <span
                className={`py-1 px-3 border rounded-pill ${
                  data.column_right && !data.column_left
                    ? ' border-white'
                    : ' border-dark'
                }`}
              >
                {data.column_left}
              </span>
            )}
            <div className="vinculated-column-items mt-4 border-end text-end">
              {data.items &&
                data.items.map((item, index) => (
                  <p
                    key={index}
                    onClick={() => setSelectedItem(index)}
                    className={`vinculated-column-item fs-l${
                      index == selectedItem ? ' active' : ''
                    }`}
                  >
                    {item.title}
                  </p>
                ))}
            </div>
          </Col>
          <Col xl={9} lg={6} className="mb-3">
            {(data.column_left || data.column_right) && (
              <span
                className={`py-1 px-3 border rounded-pill ${
                  data.column_left && !data.column_right
                    ? ' border-white'
                    : ' border-dark'
                }`}
              >
                {data.column_right}
              </span>
            )}
            {data.items && data.items[selectedItem] && (
              <div
                className="vinculated-column-info mt-4 ps-3"
                dangerouslySetInnerHTML={{
                  __html: data.items[selectedItem]?.text?.data,
                }}
              ></div>
            )}
          </Col>
        </Row>
      </div>
      <div className="d-lg-none d-xl-none">
        {data.column_left && (
          <span className="py-1 px-3 border rounded-pill border-dark d-inline-block">
            {data.column_left}
          </span>
        )}
        <Accordion defaultActiveKey={['0']}>
          {data.items &&
            data.items.map((item, index) => (
              <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header>{item.title}</Accordion.Header>
                <Accordion.Body>
                  {item.text && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.text.data,
                      }}
                    ></div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))}
        </Accordion>
      </div>
    </div>
  );
};

export default VinculatedColumnView;
