import { SidebarPortal } from '@plone/volto/components';
import { withBlockExtensions } from '@plone/volto/helpers';
import VinculatedColumnData from './Data';
import VinculatedColumnView from './View';

const VinculatedColumnEdit = (props) => {
  const { data, onChangeBlock, block, selected } = props;
  return (
    <>
      <VinculatedColumnView {...props} />
      <SidebarPortal selected={selected}>
        <VinculatedColumnData
          key={block}
          {...props}
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </>
  );
};

export default withBlockExtensions(VinculatedColumnEdit);
