import React from 'react';

import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const ItemSchema = {
  title: 'Value',
  properties: {
    icon: {
      title: 'Bootstrap Icon',
      description:
        'Get the icon name from https://icons.getbootstrap.com/, e.g. "instagram"',
      widget: 'text',
    },
    text: {
      title: 'Text',
      widget: 'text',
    },
    link: {
      title: 'Link',
      widget: 'object_browser',
      mode: 'link',
      allowExternals: true,
    },
    open_in_new_tab: {
      title: 'Open link in new tab',
      default: true,
      type: 'boolean',
    },
  },
  fieldsets: [
    {
      id: 'default',
      title: 'Value',
      fields: ['icon', 'text', 'link', 'open_in_new_tab'],
    },
  ],
  required: ['icon', 'text', 'link', 'open_in_new_tab'],
};

const IconsLinkWidget = (props) => {
  return (
    <ObjectListWidget
      schema={ItemSchema}
      {...props}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

export default IconsLinkWidget;
