import * as icons from 'react-icons/bs';

const Icon = ({ iconName, type = 'bs', ...props }) => {
  const camelCaseIcon =
    type.charAt(0).toUpperCase() +
    type.slice(1) +
    iconName
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');

  const BootstrapIcon = icons[camelCaseIcon];
  return BootstrapIcon ? <BootstrapIcon {...props} /> : null;
};

export default Icon;

// import * as icons from 'react-icons/bs';

// const Icon = ({ iconName, ...props }) => {
//   const camelCaseIcon =
//     'Bs' +
//     iconName
//       .split('-')
//       .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
//       .join('');

//   const BootstrapIcon = icons[camelCaseIcon];
//   return BootstrapIcon ? <BootstrapIcon {...props} /> : null;
// };

// export default Icon;
