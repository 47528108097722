export const schemaResource = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'title',
          'description',
          'btn_text',
          'link',
          'openInNewTab',
          'keywords',
          'audience',
          'limit',
        ],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      description: {
        title: 'Description',
        widget: 'textarea',
      },
      btn_text: {
        title: 'Button text',
        widget: 'text',
      },
      link: {
        title: 'Button URL',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      openInNewTab: {
        title: 'Open link in new tab',
        type: 'boolean',
      },
      keywords: {
        title: 'Keywords',
        type: 'array',
        widget: 'array',
        items: {
          vocabulary: { '@id': 'plone.app.vocabularies.Keywords' },
        },
      },
      audience: {
        title: 'Audience',
        description: 'Add audience to categorize your events',
        widget: 'token',
        vocabulary: {
          '@id': '/@vocabularies/unite.audience',
        },
      },
      limit: {
        title: 'Element limit',
        widget: 'integer',
      },
    },
  };
};

export default schemaResource;
