import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const DisclaimerSchema = (props) => {
  const [disclaimers, setDisclaimers] = useState([]);

  useEffect(() => {
    // Obtenemos todos los contenidos de tipo 'unite_partner'
    const getDisclaimers = async () => {
      const queryURL = '/++api++/@querystring-search';

      try {
        const response = await axios.post(queryURL, {
          query: [
            {
              i: 'portal_type',
              o: 'plone.app.querystring.operation.string.is',
              v: ['unite_disclaimer'],
            },
            {
              i: 'review_state',
              o: 'plone.app.querystring.operation.selection.any',
              v: ['published'],
            },
          ],
          metadata_fields: '_all',
          fullobjects: 'True',
          b_size: 100,
        });
        const items = response.data.items;
        setDisclaimers(items);
      } catch (error) {
        console.error('Error fetching disclaimers', error);
      }
    };

    getDisclaimers();
  }, []);

  return {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['disclaimer'],
      },
    ],
    properties: {
      disclaimer: {
        title: 'Disclaimer',
        type: 'select',
        choices: disclaimers.map((item) => [
          item, // Este será el valor 'key' en el formato original
          item.title, // Este será el valor 'value' en el formato original
        ]),
      },
    },
    required: ['disclaimer'],
  };
};

export default DisclaimerSchema;
