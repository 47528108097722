import React, { useState } from 'react';

import Accordion from 'react-bootstrap/Accordion';
import { Col } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { Row } from 'react-bootstrap';

import './less/View.less';

const View = (props) => {
  const { data } = props;

  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [infoModal, setInfoModal] = useState('');

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (title, info) => {
    setTitleModal(title);
    setInfoModal(info);
    setShowModal(true);
  };

  return (
    <div className="whoiswho-wrapper mb-unite">
      {data.title && <h2 className="mb-5">{data.title}</h2>}
      <Row>
        {data.items &&
          data.items.map((who, index) => (
            <Col
              xl={4}
              lg={6}
              sm={12}
              className="mb-5"
              style={{
                '--whoiswho-bg': who.color || '#644380',
              }}
            >
              {who.items && who.items.length > 0 ? (
                <Accordion defaultActiveKey={who['@id']}>
                  <Accordion.Item eventKey="0" className="border-0">
                    <Accordion.Header className="whoiswho-item rounded-4">
                      {who.title}
                    </Accordion.Header>
                    <Accordion.Body className="whoiswho-extra p-0">
                      {who.items.map((item, index) => (
                        <>
                          <div className="whoiswho-line d-flex justify-content-center">
                            <div className="line h-100"></div>
                          </div>
                          <div className="whoiswho-item d-flex flex-column align-items-center justify-content-center text-white rounded-4 py-2 px-4 text-center">
                            <p className="mb-0">{item.title}</p>
                            {item.info && item.info.data && (
                              <p
                                className="whoiswho-btn border border-white text-white rounded-pill mt-2 px-4 py-1 fs-xs"
                                onClick={() => {
                                  handleShowModal(item.title, item.info.data);
                                }}
                              >
                                + INFO
                              </p>
                            )}
                          </div>
                        </>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ) : (
                <div className="whoiswho-item d-flex flex-column align-items-center justify-content-center text-white rounded-4 py-2 px-4 text-center">
                  <p>{who.title}</p>
                </div>
              )}
            </Col>
          ))}
      </Row>

      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">{titleModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: infoModal }} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default View;
