import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Slider from '../../components/Slider/Slider';

import './less/View.less';

const View = (props) => {
  const { data } = props;

  return (
    <div className="numbers-wrapper">
      <Row className="align-items-end">
        <h2 className="mb-0">{data.title}</h2>
      </Row>
      <hr className="my-4" />
      <div className="slider-container mb-unite">
        {data.Numbers && data.Numbers.length > 0 && (
          <Slider
            id="numbers"
            items={data.Numbers.map((number) => (
              <div className="w-100 ">
                <p className="number mb-0">{number.value}</p>
                <hr className="my-3" />
                <p className="fs-xxl text-truncate-x text-break">
                  {number.text}
                </p>
              </div>
            ))}
            items_col="2"
            items_md_col="3"
            items_xs_col="6"
          />
        )}
      </div>
    </div>
  );
};

export default View;
