import config from '@plone/volto/registry';

export const CiteSchema = (props) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['text', 'footer_text', 'cite_url', 'highlighted'],
      },
    ],
    properties: {
      text: {
        title: 'Text',
        type: 'string',
        widget: 'richtext',
        default:
          '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>',
      },
      footer_text: {
        title: 'Footer Text',
        type: 'string',
        widget: 'richtext',
        default: '<p>Someone famous in <strong>Source Title</strong></p>',
      },
      cite_url: {
        title: 'Cite URL',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      highlighted: {
        title: 'Highlighted',
        type: 'boolean',
        default: false,
      },
    },
    required: ['text', 'footer_text'],
  };
};

export default CiteSchema;
