import React, { useEffect, useState } from 'react';

import { FaTimes } from 'react-icons/fa';
import { ImArrowDownRight2 } from 'react-icons/im';

import { Card, Col, Row, Modal, Button } from 'react-bootstrap';

import RepresentativeInfo from '../../components/Representative/RepresentativeInfo';
import PreviewImagePT from '../../Utils/PreviewImage/PreviewImagePT';
import './less/View.less';

import axios from 'axios';

const View = (props) => {
  const [representatives, setRepresentatives] = useState([]);
  const [representativeCategories, setRepresentativeCategories] = useState([]);

  const [selectedRepresentative, setSelectedRepresentative] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const { data } = props;

  useEffect(() => {
    // Obtenemos todos los contenidos de tipo 'unite.representatives'
    const getRepresentatives = async () => {
      const queryURL = '/++api++/@querystring-search';

      let query = [
        {
          i: 'portal_type',
          o: 'plone.app.querystring.operation.string.is',
          v: ['unite_representative'],
        },
        {
          i: 'review_state',
          o: 'plone.app.querystring.operation.selection.any',
          v: ['published'],
        },
      ];

      if (data.keywords && data.keywords.length > 0) {
        query.push({
          i: 'Subject',
          o: 'plone.app.querystring.operation.list.contains',
          v: data.keywords,
        });
      }

      if (selectedFilters && selectedFilters.length > 0) {
        query.push({
          i: 'representative_categories',
          o: 'plone.app.querystring.operation.list.contains',
          v: selectedFilters,
        });
      }

      try {
        const response = await axios.post(queryURL, {
          query: query,
          metadata_fields: '_all',
          fullobjects: 'True',
          sort_on: 'getObjPositionInParent',
          b_size: 100, // Aumenta el límite de resultados devueltos el endpoint querystring-search de Plone tiene un límite predeterminado de 25
        });

        const items = response.data.items;
        setRepresentatives(items);
      } catch (error) {
        console.error('Error fetching representatives', error);
      }
    };

    getRepresentatives();
  }, [data.keywords, selectedFilters]);

  useEffect(() => {
    const getRepresentativeCategories = async () => {
      const url =
        '/++api++/@vocabularies/unite.representative_categories?b_size=-1';
      try {
        const response = await axios.get(url);
        const items = response.data.items;
        setRepresentativeCategories(items);
      } catch (error) {
        console.error('Error fetching unite.representative_categories', error);
      }
    };
    getRepresentativeCategories();
  }, []);

  const mapLiteralFilter = (filter) => {
    const category = representativeCategories.find(
      (cat) => cat['token'] === filter,
    );
    return category ? category['title'] : filter;
  };

  const toggleFilter = (filter) => {
    setSelectedFilters((prevState) =>
      prevState.includes(filter)
        ? prevState.filter((fil) => fil !== filter)
        : [...prevState, filter],
    );
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedRepresentative(null);
  };

  const handleShow = (rep) => {
    setShowModal(true);
    setSelectedRepresentative(rep);
  };

  return (
    <div className="representatives-wrapper mb-unite">
      {representatives && representatives.length > 0 && (
        <>
          {data.title && <h2>{data.title}</h2>}
          {data.filters && (
            <div className="unite-filters">
              {data.filters.map((filter, index) => (
                <button
                  key={index}
                  className={`filter-button${
                    selectedFilters.includes(filter) ? ' selected' : ''
                  }`}
                  onClick={() => toggleFilter(filter)}
                >
                  {selectedFilters.includes(filter) && (
                    <FaTimes className="me-1" />
                  )}
                  {mapLiteralFilter(filter)}
                </button>
              ))}
            </div>
          )}
          <Row className="representatives-list">
            {representatives.map((representative, index) => (
              <Col lg={4} md={6} sm={12} key={index}>
                <Card
                  className="representative-img-block mh-100 border-0"
                  onClick={() => handleShow(representative)}
                >
                  <PreviewImagePT
                    item={representative.image}
                    alt={representative.title}
                    imageField="image"
                    className="ui image h-100 w-100 representative-img rounded-4 object-fit-cover"
                  />
                  <div className="representative-info text-white pb-5 d-flex flex-column align-items-baseline justify-content-end w-100 h-75">
                    <Card.Text className="mb-1 h4">
                      {representative.title}
                    </Card.Text>
                    {representative.rol && (
                      <Card.Text className="mb-1">
                        {representative.rol}
                      </Card.Text>
                    )}
                    {representative.university_name && (
                      <Card.Text>{representative.university_name}</Card.Text>
                    )}
                    <Card.Text className="card-hover-text">
                      <ImArrowDownRight2 size={18} className="me-2" />
                      See biography
                    </Card.Text>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <Modal
            show={showModal}
            onHide={handleClose}
            size="xl"
            className="representative-modal"
          >
            <Modal.Header
              className="border-bottom-0"
              closeButton
            ></Modal.Header>
            <Modal.Body>
              {selectedRepresentative && (
                <RepresentativeInfo item={selectedRepresentative} />
              )}
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
};

export default View;
