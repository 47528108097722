import React from 'react';
import config from '@plone/volto/registry';

import { ImArrowDownRight2 } from 'react-icons/im';

import Card from 'react-bootstrap/Card';

import './less/PartnerCard.less';

const PartnerCard = ({
  id,
  name,
  location,
  logo,
  negative_logo,
  hexcolor,
  url,
}) => {
  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;
  return (
    <a
      className="w-100"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      title={name}
    >
      <Card
        key={id}
        className="partner-card mb-4"
        style={{ '--card-hover-hexcolor': hexcolor || '#644380' }}
      >
        <Card.Body>
          <PreviewImage
            item={logo}
            alt={`${name} logo`}
            image_field="image"
            imageField="image"
            className="mb-4 partner-logo w-auto"
            responsive={true}
            loading="lazy"
          />
          <PreviewImage
            item={negative_logo || logo}
            alt={`${name} logo`}
            image_field="image"
            imageField="image"
            className="mb-4 partner-negative-logo w-auto"
            responsive={true}
            loading="lazy"
          />
          <div className="card-info d-flex flex-column justify-content-end">
            <Card.Text className="fw-bold fs-xxl text-truncate-x">
              {name}
            </Card.Text>
            <Card.Text className="text-truncate-x">{location}</Card.Text>
            <Card.Text className="card-hover-text">
              <ImArrowDownRight2 className="me-2" />
              More Info
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
    </a>
  );
};

export default PartnerCard;
