import React, { useEffect, useState } from 'react';
import ClampLines from 'react-clamp-lines';
import { Link } from 'react-router-dom';
import { isInternalURL, toBackendLang } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';

import { ImArrowDownRight2 } from 'react-icons/im';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import './less/View.less';

const View = (props) => {
  const { data } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const sectionBlocks =
    data?.SectionBlocks?.filter((item) => item.link?.[0]?.['@id']) || [];

  const renderLink = (item, index) => {
    const linkId = item.link[0]['@id'];
    if (isInternalURL(linkId)) {
      return (
        <Link
          to={flattenToAppURL(linkId)}
          {...(item.openInNewTab && { target: '_blank' })}
          className="me-3"
          key={index}
        >
          <Button variant="primary" className="text-start">
            <ImArrowDownRight2 className="me-2" />
            {item.btn_text}
          </Button>
        </Link>
      );
    } else {
      return (
        <a
          href={linkId}
          rel="noopener noreferrer"
          {...(item.openInNewTab && { target: '_blank' })}
          className="me-3"
          key={index}
        >
          <Button variant="primary">
            <ImArrowDownRight2 className="me-2" />
            {item.btn_text}
          </Button>
        </a>
      );
    }
  };

  return (
    <div className="mb-unite">
      <Row>
        <Col md={8}>
          {data.title && <h2>{data.title}</h2>}
          <div>
            {data.description ? (
              <ClampLines
                text={data.description}
                id="really-unique-id"
                lines={isExpanded ? 'auto' : 3}
                ellipsis="..."
                moreText="Read More"
                lessText="Read Less"
                buttons={true}
                innerElement="p"
              />
            ) : null}
          </div>
        </Col>
      </Row>
      {sectionBlocks.length > 0 && (
        <Row className="mt-4">
          <Col
            md={8}
            className="d-flex flex-wrap justify-content-start align-items-end row-gap-2"
          >
            {sectionBlocks.map(renderLink)}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default View;
