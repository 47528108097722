import React from 'react';
import { Link } from 'react-router-dom';
import { isInternalURL } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';

import { ImArrowDownRight2 } from 'react-icons/im';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const View = (props) => {
  const { data } = props;

  return (
    <>
      <Row className="mb-unite">
        <Col
          xl={9}
          lg={8}
          md={7}
          className="d-flex flex-column justify-content-center mb-3 mb-md-0"
        >
          <Card className="p-4 h-100 d-flex flex-column justify-content-center">
            <p>{data.description}</p>
          </Card>
        </Col>
        <Col
          xl={3}
          lg={4}
          md={5}
          className="d-flex flex-column justify-content-center"
        >
          <Card className="p-4 h-100 d-flex justify-content-center align-items-center">
            {data.link &&
              data.btn_text &&
              (isInternalURL(data.link[0]?.['@id']) ? (
                <Link
                  to={flattenToAppURL(data.link[0]?.['@id'])}
                  {...(data.openInNewTab && { target: '_blank' })}
                >
                  <Button variant="primary">
                    <ImArrowDownRight2 className="me-2" />
                    {data.btn_text}
                  </Button>
                </Link>
              ) : (
                <a
                  href={data.link[0]?.['@id']}
                  rel="noopener noreferrer"
                  {...(data.openInNewTab && { target: '_blank' })}
                >
                  <Button variant="primary">
                    <ImArrowDownRight2 className="me-2" />
                    {data.btn_text}
                  </Button>
                </a>
              ))}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default View;
