import React from 'react';

import { Link } from 'react-router-dom';
import { isInternalURL } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';

import { colorAdjust } from 'volto-unite-web/utils';

import Icon from '../../../Utils/Icon/Icon';

import './less/View.less';

const View = (props) => {
  const { data, isEditMode } = props;

  return (
    <>
      {!isEditMode && data.link && isInternalURL(data.link[0]?.['@id']) ? (
        <Link
          to={flattenToAppURL(data.link[0]?.['@id'])}
          style={{
            '--banner-bg': data.color || '#644380',
            '--banner-bg-hover': data.color
              ? colorAdjust(data.color, -10)
              : colorAdjust('#644380', -10),
          }}
          className="banner-unite text-decoration-none py-3 px-4 mb-3 d-flex flex-row align-items-center rounded-4 text-white"
          {...(data.open_in_new_tab && { target: '_blank' })}
          {...(data.description_link && {
            'aria-labelledby': data.description_link,
          })}
        >
          <p className="d-flex flex-column mb-0 me-auto">
            <span
              className={`fw-bold${data.highlighted ? ' h5' : ' fs-xxxl'}${
                data.subtitle ? ' mb-2' : ''
              }`}
            >
              {data.title}
            </span>
            <span className={`${data.highlighted ? 'fs-xxxl' : ''}`}>
              {data.subtitle}
            </span>
          </p>
          <Icon
            iconName={
              data.open_in_new_tab
                ? 'arrow-up-right-circle'
                : 'arrow-right-circle'
            }
            size={36}
            className="ms-4 flex-shrink-0"
          />
        </Link>
      ) : (
        <a
          href={
            data.link && data.link[0]?.['@id'] ? data.link[0]?.['@id'] : '#'
          }
          style={{
            '--banner-bg': data.color || '#644380',
            '--banner-bg-hover': data.color
              ? colorAdjust(data.color, -10)
              : colorAdjust('#644380', -10),
          }}
          className="banner-unite text-decoration-none py-3 px-4 mb-3 d-flex flex-row align-items-center rounded-4 text-white"
          rel="noopener noreferrer"
          {...(data.open_in_new_tab && { target: '_blank' })}
          {...(data.description_link && {
            'aria-labelledby': data.description_link,
          })}
          onClick={(e) => isEditMode && e.preventDefault()}
        >
          <p className="d-flex flex-column mb-0 me-auto">
            <span
              className={`fw-bold${data.highlighted ? ' h5' : ' fs-xxxl'}${
                data.subtitle ? ' mb-2' : ''
              }`}
            >
              {data.title}
            </span>
            <span className={`${data.highlighted ? 'fs-xxxl' : ''}`}>
              {data.subtitle}
            </span>
          </p>
          <Icon
            iconName={
              data.open_in_new_tab
                ? 'arrow-up-right-circle'
                : 'arrow-right-circle'
            }
            size={36}
            className="ms-4 flex-shrink-0"
          />
        </a>
      )}
    </>
  );
};

export default View;