
export const ButtonSchema = (props) => {
  return {
    title: 'Button',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['text', 'link', 'openInNewTab'],
      },
    ],
    properties: {
      text: {
        title: 'Text',
        widget: 'text',
      },
      link: {
        title: 'URL',
        widget: 'url',
      },
      openInNewTab: {
        title: 'Open link in new tab',
        type: 'boolean',
      },
    },
    required: ['text', 'link'],
  };
};

export const AlertSchema = (props) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['style', 'icon', 'heading', 'text', 'buttons','show_close'],
      },
    ],
    properties: {
      style: {
        title: 'Style',
        widget: 'select',
        choices: [
          ['success', 'Success'],
          ['danger', 'Danger'],
          ['warning', 'Warning'],
          ['info', 'Info'],
        ],
      },
      icon: {
        title: 'Icon',
        widget: 'select',
        choices: [
          ['exclamation-triangle-fill', 'Exclamation Triangle'],
          ['exclamation-circle-fill', 'Exclamation Circle'],
          ['info-circle-fill', 'Info Circle'],
          ['check-circle-fill', 'Check Circle'],
        ],
      },
      heading: {
        title: 'Heading',
      },
      text: {
        title: 'Text',
        type: 'string',
        widget: 'richtext',
      },
      buttons: {
        title: 'Buttons',
        widget: 'object_list',
        schema: ButtonSchema(props),
        activeObject: props.activeObject,
        setActiveObject: props.setActiveObject,
      },
      show_close: {
        title: 'Show button to close alert',
        type: 'boolean',
      },
    },
    required: ['style', 'text'],
  };
};

export default AlertSchema;
