export const VideoYoutubeSchema = (props) => {
  return {
    title: 'Video YouTube',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['url_youtube'],
      },
    ],
    properties: {
      url_youtube: {
        title: 'YouTube URL',
        widget: 'text',
      },
    },
    required: ['url_youtube'],
  };
};

export default VideoYoutubeSchema;
