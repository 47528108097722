/**
 * View video block.
 * @module components/manage/Blocks/Video/View
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withBlockExtensions } from '@plone/volto/helpers';
import './less/View.less';

/**
 * View video block class.
 * @class View
 * @extends Component
 */

const View = (props) => {
  const { data } = props;

  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayVideo = () => {
    setIsPlaying(true);
  };

  var videoID = '';
  if (data.url && data.url.match('youtu')) {
    if (data.url.match('live')) {
      videoID = data.url.match(/^.*\/live\/(.*)/)[1];
    } else if (data.url.match(/\.be\//)) {
      videoID = data.url.match(/^.*\.be\/(.*)/)[1];
    } else if (data.url.match(/\?v=/)) {
      videoID = data.url.match(/^.*\?v=(.*)$/)[1];
    }
  }
  videoID = videoID.includes('&') ? videoID.split('&')[0] : videoID;

  const embedURL = videoID
    ? `https://www.youtube.com/embed/${videoID}?autoplay=1&rel=0`
    : '';
  const imageURL = videoID
    ? `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`
    : '';

  return (
    <div class="video-wrapper mb-unite">
      {videoID ? (
        !isPlaying ? (
          <div
            className="video-preview ratio ratio-21x9"
            onClick={handlePlayVideo}
          >
            <img
              src={imageURL}
              alt="Video preview"
              className="video-thumbnail rounded-4"
            />
            <svg
              className="play-button"
              height="100%"
              version="1.1"
              viewBox="0 0 68 48"
              width="100%"
            >
              <path
                class="ytp-large-play-button-bg"
                d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                fill="#f00"
              ></path>
              <path d="M 45,24 27,14 27,34" fill="#fff"></path>
            </svg>
          </div>
        ) : (
          <div className="video-container ratio ratio-21x9">
            <iframe
              className="rounded-4"
              src={embedURL}
              title="YouTube video player"
              allow="autoplay; encrypted-media; rel=0;"
              frameborder="0"
              allowFullScreen
            ></iframe>
          </div>
        )
      ) : (
        <div className="video-error">
          <p>{data.url ? 'Invalid Video URL' : 'Configure a Video URL'}</p>
        </div>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withBlockExtensions(View);
