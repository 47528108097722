import React from 'react';
import config from '@plone/volto/registry';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Marquee from '../../components/Marquee/Marquee';

import './less/View.less';

const View = (props) => {
  const { data } = props;
  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;

  return (
    <>
      <div className="container-section-marquee position-relative mb-unite">
        <Row className="row container-section">
          <Col md={7} className="px-6 text">
            {data.title && <h2>{data.title}</h2>}
            <div>{data.description && <p>{data.description}</p>}</div>
          </Col>
          <Col md={5} className="px-6 img">
            {data.image && data.image.length > 0 ? (
              <PreviewImage
                item={data.image[0]}
                alt={data.image_caption || ''}
                className="img-fluid rounded-4"
                responsive={true}
                loading="lazy"
              />
            ) : null}
          </Col>
        </Row>
        <Marquee data={data.textBlocks} base_speed={data.animationSpeed} />
      </div>
    </>
  );
};

export default View;
