// Este es un schema normal. Servirá de schema para los subbloques.
export const NumbersSettingsSchema = (props) => {
  return {
    title: 'Numbers',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['value', 'text'],
      },
    ],
    properties: {
      value: {
        title: 'Value',
        widget: 'text',
      },
      text: {
        title: 'Text',
        widget: 'text',
      },
    },
    required: [],
  };
};

/*
Este schema engloba sus propios campos (Título del bloque + Color de fondo)
Y además da una lista de bloques de 'texto' de tamaño indefinido. Se
Crea un campo para esto, con widget 'object_list' y se le asigna un schema para
cada objeto (o subloque) de esta lista
*/

export const NumbersBlockSchema = (props) => {
  return {
    title: 'Numbers Block',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'Numbers'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      Numbers: {
        title: 'In Numbers',
        widget: 'object_list',
        schema: NumbersSettingsSchema(props),
        activeObject: props.activeObject,
        setActiveObject: props.setActiveObject,
      },
    },
    required: [],
  };
};

export default NumbersBlockSchema;
