import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';
import { isInternalURL } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';

import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

import { ImArrowDownRight2 } from 'react-icons/im';

import Slider from '../../components/Slider/Slider';

import './less/View.less';
import '../../components/ImageWithTextBelow/less/ImageWithTextBelow.less';

const View = (props) => {
  const { data } = props;

  const [featuredInfo, setFeaturedInfo] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const getFeaturedInfo = async () => {
      const url = '/++api++/@agora-featured-info';
      const query = { catalogues: data.catalogues };
      try {
        setShowLoading(true);
        const response = await axios.post(url, { query: query });
        setShowLoading(false);
        const data = response.data.items;
        var fomatedData = data.map((item, index) => (
          <Card className="mb-3 w-100 image-with-text-below ">
            {item?.image && (
              <Card.Header>
                {item?.link ? (
                  <a
                    className="text-block-moreinfo"
                    href={item.link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src={item.image}
                      className="ui image rounded-4 w-100"
                      alt=""
                    />
                  </a>
                ) : (
                  <img
                    src={item.image}
                    className="ui image rounded-4 w-100"
                    alt=""
                  />
                )}
              </Card.Header>
            )}
            <Card.Body>
              <Card.Title className="mt-0 pb-2 border-bottom">
                {item?.name}
              </Card.Title>
              {item?.summary && (
                <Card.Text
                  className="text-truncate-x"
                  style={{ '--text-lines': 5 }}
                >
                  {item.summary}
                </Card.Text>
              )}
            </Card.Body>
            {item?.link && (
              <Card.Footer>
                <a
                  className="text-block-moreinfo"
                  href={item.link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <ImArrowDownRight2 className="me-2" /> More Info
                </a>
              </Card.Footer>
            )}
          </Card>
        ));
        setFeaturedInfo(fomatedData);
      } catch (error) {
        setShowLoading(false);
        console.error('Error fetching agora featured info', error);
      }
    };

    getFeaturedInfo();
  }, [data.catalogues]);

  return (
    <Row className="mb-unite featured-wrapper">
      {(showLoading || (featuredInfo && featuredInfo.length > 0)) &&
        data.title && (
          <div className="mb-4">
            <h2>{data.title}</h2>
            {data.link &&
              data.btn_text &&
              (isInternalURL(data.link[0]?.['@id']) ? (
                <Link
                  to={flattenToAppURL(data.link[0]?.['@id'])}
                  {...(data.openInNewTab && { target: '_blank' })}
                  className="link-readmore"
                >
                  <ImArrowDownRight2 className="me-2" />
                  {data.btn_text}
                </Link>
              ) : (
                <a
                  href={data.link[0]?.['@id']}
                  rel="noopener noreferrer"
                  {...(data.openInNewTab && { target: '_blank' })}
                  className="text-block"
                >
                  <ImArrowDownRight2 className="me-2" />
                  {data.btn_text}
                </a>
              ))}
          </div>
        )}

      {showLoading ? (
        <div className="spinner-container w-100 d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        featuredInfo &&
        featuredInfo.length > 0 && (
          <Slider
            id="featuredInfo"
            items={featuredInfo}
            items_col="4"
            items_md_col="6"
            items_xs_col="12"
          />
        )
      )}
    </Row>
  );
};

export default View;
