import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { Segment, Header, List } from 'semantic-ui-react';
import {
  When,
  Recurrence,
} from '@plone/volto/components/theme/View/EventDatesInfo';
import { Icon } from '@plone/volto/components';
import { expandToBackendURL } from '@plone/volto/helpers';

import calendarSVG from '@plone/volto/icons/calendar.svg';
import config from '@plone/volto/registry';

const messages = defineMessages({
  what: {
    id: 'event_what',
    defaultMessage: 'What',
  },
  when: {
    id: 'event_when',
    defaultMessage: 'When',
  },
  allDates: {
    id: 'event_alldates',
    defaultMessage: 'All dates',
  },
  downloadEvent: {
    id: 'Download Event',
    defaultMessage: 'Download Event',
  },
  where: {
    id: 'event_where',
    defaultMessage: 'Location',
  },
  contactName: {
    id: 'event_contactname',
    defaultMessage: 'Contact Name',
  },
  contactPhone: {
    id: 'event_contactphone',
    defaultMessage: 'Telephone',
  },
  attendees: {
    id: 'event_attendees',
    defaultMessage: 'Attendees',
  },
  website: {
    id: 'event_website',
    defaultMessage: 'Url',
  },
  visitWebsite: {
    id: 'visit_external_website',
    defaultMessage: 'Visit external website',
  },
  format: {
    id: 'event_format',
    defaultMessage: 'Format',
  },
  media: {
    id: 'event_media',
    defaultMessage: 'Media',
  },
  university: {
    id: 'event_university',
    defaultMessage: 'University',
  },
  eventlanguage: {
    id: 'event_language',
    defaultMessage: 'Event Language',
  },
});

const EventDetails = ({ content, display_as = 'aside' }) => {
  const intl = useIntl();
  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;

  const [showAllAttendees, setShowAllAttendees] = useState(false);

  const attendeesToShow = showAllAttendees
    ? content?.attendees
    : content?.attendees?.slice(0, 3);

  return (
    <Segment
      as={display_as}
      {...(display_as === 'aside' ? { floated: 'right' } : {})}
    >
      <Header dividing sub>
        {intl.formatMessage(messages.when)}
      </Header>
      <When
        start={content.start}
        end={content.end}
        whole_day={content.whole_day}
        open_end={content.open_end}
      />
      {content.recurrence && (
        <>
          <Header dividing sub>
            {intl.formatMessage(messages.allDates)}
          </Header>
          <Recurrence recurrence={content.recurrence} start={content.start} />
        </>
      )}
      {content.location && (
        <>
          <Header dividing sub>
            {intl.formatMessage(messages.where)}
          </Header>
          <p>{content.location}</p>
        </>
      )}
      {content.university && (
        <>
          <Header dividing sub>
            {intl.formatMessage(messages.university)}
          </Header>
          <p>{content.university.title}</p>
        </>
      )}
      {content.event_url && (
        <>
          <Header dividing sub>
            {intl.formatMessage(messages.website)}
          </Header>
          <p>
            <a
              href={content.event_url}
              target="_blank"
              rel="noopener noreferrer"
              className="ui label"
            >
              website
            </a>
          </p>
        </>
      )}
      {content.format && (
        <>
          <Header dividing sub>
            {intl.formatMessage(messages.format)}
          </Header>
          {content.format.map((item, index, array) => (
            <span key={item.token}>
              {item.title}
              {index < array.length - 1 ? ', ' : ''}
            </span>
          ))}
        </>
      )}
      {content.attendees?.length > 0 && (
        <>
          <Header dividing sub>
            {intl.formatMessage(messages.attendees)}
          </Header>
          <List items={attendeesToShow} />
          {content.attendees.length > 3 && !showAllAttendees && (
            <a onClick={() => setShowAllAttendees(true)}>
              {intl.formatMessage({
                id: 'readMore',
                defaultMessage: 'READ MORE',
              })}
            </a>
          )}
        </>
      )}
      {content.eventlanguage && (
        <>
          <Header dividing sub>
            {intl.formatMessage(messages.eventlanguage)}
          </Header>
          <p>{content.eventlanguage}</p>
        </>
      )}
      {content.contact_phone && (
        <>
          <Header dividing sub>
            {intl.formatMessage(messages.contactPhone)}
          </Header>
          <p>{content.contact_phone}</p>
        </>
      )}
      {content.contact_name && (
        <>
          <Header dividing sub>
            {intl.formatMessage(messages.contactName)}
          </Header>
          <p>
            {content.contact_email ? (
              <a href={`mailto:${content.contact_email}`}>
                {content.contact_name}
              </a>
            ) : (
              content.contact_name
            )}
          </p>
        </>
      )}
      {content.preview_image ? (
        <>
          <Header dividing sub>
            {intl.formatMessage(messages.media)}
          </Header>

          <PreviewImage
            item={content}
            alt={content.preview_caption || ''}
            image_field="preview_image"
            imageField="preview_image"
            className="img-fluid"
            responsive={true}
            loading="lazy"
          />
        </>
      ) : null}
    </Segment>
  );
};

export default EventDetails;
