import config from '@plone/volto/registry';

export const BlockColorSchema = (props) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'text', 'color'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        type: 'string',
        default: 'Lorem Ipsum',
      },
      text: {
        title: 'Text',
        type: 'string',
        widget: 'richtext',
        default: {
          data: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ac nisl augue.</p><p>Aliquam erat volutpat. Sed commodo ipsum nibh. Aliquam erat volutpat. Pellentesque volutpat lorem ac faucibus cursus.</p><p>Donec tempor, nisl ac vehicula imperdiet, leo erat vestibulum velit, non cursus sem sem quis dolor.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p><p>In eu placerat ligula. Vestibulum ut lacus nec eros aliquet efficitur.</p>',
        },
      },
      color: {
        title: 'Color',
        type: 'color',
        widget: 'style_simple_color',
        available_colors: config.settings.dark_colors,
        default: '#644380',
      },
    },
    required: ['title', 'text', 'color'],
  };
};

export default BlockColorSchema;
