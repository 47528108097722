import React, { useEffect, useState } from 'react';

import { Pagination } from 'semantic-ui-react';

import paginationLeftSVG from '@plone/volto/icons/left-key.svg';
import paginationRightSVG from '@plone/volto/icons/right-key.svg';
import { Icon } from '@plone/volto/components';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import axios from 'axios';

import Select from 'react-select';

import './less/View.less';
import ImageWithTextBelow from '../../components/ImageWithTextBelow/ImageWithTextBelow';

const WhatsNewsView = (props) => {
  const { data } = props;
  const [results, setNewsItems] = useState([]);

  const [audiences, setAudiences] = useState([]);
  const [valueAudience, setValueAudience] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState([]);
  const [selectedText, setSelectedText] = useState(null);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [prevBatch, setPrevBatch] = useState(false);
  const [nextBatch, setNextBatch] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const maxItemsPerPage = 6;

  useEffect(() => {
    const getNewsItems = async () => {
      const queryURL = '/++api++/@querystring-search';

      let query = [
        {
          i: 'portal_type',
          o: 'plone.app.querystring.operation.string.is',
          v: ['News Item'],
        },
        {
          i: 'review_state',
          o: 'plone.app.querystring.operation.selection.any',
          v: ['published'],
        },
      ];

      if (data.keywords && data.keywords.length > 0) {
        query.push({
          i: 'Subject',
          o: 'plone.app.querystring.operation.list.contains',
          v: data.keywords,
        });
      }

      if (selectedAudience && selectedAudience.length > 0) {
        query.push({
          i: 'audience',
          o: 'plone.app.querystring.operation.list.contains',
          v: selectedAudience,
        });
      }

      if (selectedText && selectedText.length >= 3) {
        query.push({
          i: 'SearchableText',
          o: 'plone.app.querystring.operation.string.contains',
          v: selectedText,
        });
      }

      try {
        const response = await axios.post(queryURL, {
          query: query,
          metadata_fields: '_all',
          fullobjects: 'True',
          sort_on: 'effective',
          sort_order: 'descending',
          b_size: maxItemsPerPage,
          b_start: (currentPage - 1) * maxItemsPerPage,
        });

        setNextBatch(currentPage * maxItemsPerPage < response.data.items_total);
        setPrevBatch(currentPage !== 1);
        setTotalPages(Math.ceil(response.data.items_total / maxItemsPerPage));

        const items = response.data.items;
        setNewsItems(items);
      } catch (error) {
        console.error('Error fetching news', error);
      }
    };

    getNewsItems();
  }, [selectedAudience, selectedText, currentPage]);

  useEffect(() => {
    const getAudience = async () => {
      const queryURLAudience =
        '/++api++/@vocabularies/unite.audience?b_size=-1';

      try {
        const responseAudience = await axios.get(queryURLAudience);
        setAudiences(responseAudience.data.items);
      } catch (error) {
        console.error('Error fetching unite.audience', error);
      }
    };

    getAudience();
  }, []);

  const handleAudience = (e) => {
    setValueAudience(e);
    setSelectedAudience(e.map((item) => item.value));
  };

  const handleText = (e) => {
    setSelectedText(e.target.value);
  };

  return (
    <div className="whats-news-wrapper mb-unite">
      <Row className="search-container mb-3">
        <Col md={8} className="mb-3">
          <Form.Group>
            <Form.Label className="fw-bold" htmlFor="search-text-news">
              Text
            </Form.Label>
            <Form.Control
              id="search-text-news"
              className="search-news input-text-unite rounded-pill w-100"
              type="text"
              onChange={handleText}
            />
          </Form.Group>
        </Col>
        <Col md={4} className="mb-3">
          <Form.Group>
            <Form.Label className="fw-bold" htmlFor="select-audience-news">
              Audience
            </Form.Label>
            <Select
              inputId="select-audience-news"
              className="select-audience react-select-bootstrap w-100"
              value={valueAudience}
              onChange={handleAudience}
              closeMenuOnSelect={false}
              isMulti
              options={audiences.map((item) => ({
                value: item.token,
                label: item.title,
              }))}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        {results.length === 0 && <p>No news items found</p>}
        {results.map((news_item) => {
          return (
            <Col md={4} sm={6} className="d-flex">
              <ImageWithTextBelow item={news_item} addAudiences={true} />
            </Col>
          );
        })}
        {totalPages > 1 && (
          <div className="pagination-wrapper">
            <Pagination
              activePage={currentPage}
              totalPages={totalPages}
              onPageChange={(e, { activePage }) => {
                setCurrentPage(activePage);
              }}
              firstItem={null}
              lastItem={null}
              prevItem={{
                content: <Icon name={paginationLeftSVG} size="18px" />,
                icon: true,
                'aria-disabled': !prevBatch,
                className: !prevBatch ? 'disabled' : null,
              }}
              nextItem={{
                content: <Icon name={paginationRightSVG} size="18px" />,
                icon: true,
                'aria-disabled': !nextBatch,
                className: !nextBatch ? 'disabled' : null,
              }}
            />
          </div>
        )}
      </Row>
    </div>
  );
};

export default WhatsNewsView;
