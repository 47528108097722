import React, { useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Modal } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import PreviewImagePT from '../../Utils/PreviewImage/PreviewImagePT';

import './less/View.less';

const PreviewImageBlockView = (props) => {
  const { properties } = props;
  const [showModal, setShowModal] = useState(false);
  const [imageModal, setImageModal] = useState('');
  const [captionModal, setCaptionModal] = useState('');

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (e) => {
    setImageModal(e.target.src);
    setCaptionModal(e.target.getAttribute('data-caption'));
    setShowModal(true);
  };

  return (
    <>
      <Row className="mb-4">
        <Col>
          {properties.preview_image ? (
            <>
              <PreviewImagePT
                onClick={handleShowModal}
                item={properties}
                alt={properties.image_alt || properties.preview_caption || ''}
                imageField="preview_image"
                className="img-fluid rounded-4"
                showDefault={false}
                data-caption={properties.preview_caption || ''}
              />
              {properties.preview_caption ? (
                <>
                  <p className="text-muted mt-2 mb-1">
                    {properties.preview_caption}
                  </p>
                </>
              ) : null}
            </>
          ) : null}
          {properties.image ? (
            <>
              <PreviewImagePT
                onClick={handleShowModal}
                item={properties}
                alt={properties.image_caption || ''}
                imageField="image"
                className="img-fluid rounded-4"
                showDefault={false}
                data-caption={properties.image_caption || ''}
              />
              {properties.image_caption ? (
                <>
                  <p className="text-muted mt-2 mb-1">
                    {properties.image_caption}
                  </p>
                </>
              ) : null}
            </>
          ) : null}
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>{captionModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center align-items-center">
          <Image src={imageModal} alt={captionModal} fluid loading="lazy" />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PreviewImageBlockView;
