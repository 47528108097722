import React from 'react';
import config from '@plone/volto/registry';
import { Link } from 'react-router-dom';
import { isInternalURL } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';

import { ImArrowDownRight2 } from 'react-icons/im';

import Card from 'react-bootstrap/Card';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import './less/View.less';

const View = (props) => {
  const { data } = props;
  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;

  return (
    <>
      <Row className="mb-unite boxes-wrapper">
        <Col lg={4} md={6} sm={12} className="d-flex align-items-stretch">
          <Card className="image-card mb-3 w-100">
            {data.image && data.image.length > 0 ? (
              <PreviewImage
                item={data.image[0]}
                alt={data.image_alt || ''}
                className="rounded-4 object-fit-cover"
                loading="lazy"
                showDefault={true}
              />
            ) : null}
            <div className="title-card">{data.title}</div>
          </Card>
        </Col>
        {data.boxesBlocks &&
          data.boxesBlocks.length > 0 &&
          data.boxesBlocks.map((item, index) => (
            <Col
              lg={4}
              md={6}
              sm={12}
              key={item}
              className="d-flex align-items-stretch"
            >
              <Card className="text-card mb-3 w-100">
                <Card.Body>
                  <Card.Title className="mb-3">{item?.number}</Card.Title>
                  <Card.Subtitle className="mb-3">{item?.title}</Card.Subtitle>
                  <Card.Text>{item?.description}</Card.Text>
                  {item.link &&
                    (isInternalURL(item.link[0]?.['@id']) ? (
                      <Link
                        className="text-block-moreinfo text-center"
                        to={flattenToAppURL(item.link[0]?.['@id'])}
                        {...(item.openInNewTab && {
                          target: '_blank',
                        })}
                        aria-labelledby={item?.description_link}
                      >
                        <ImArrowDownRight2 className="me-2" /> More Info
                      </Link>
                    ) : (
                      <a
                        className="text-block-moreinfo text-center"
                        href={item.link[0]?.['@id']}
                        rel="noopener noreferrer"
                        {...(item.openInNewTab && {
                          target: '_blank',
                        })}
                        aria-labelledby={item?.description_link}
                      >
                        <ImArrowDownRight2 className="me-2" /> More Info
                      </a>
                    ))}
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default View;
