import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import axios from 'axios';

import Slider from '../../components/Slider/Slider';
import TestimonialCard from '../../components/Testimonial/TestimonialCard';
import { fetchAudience } from '../../../actions/audience';

const View = (props) => {
  const [testimonials, setTestimonials] = useState([]);
  const { data } = props;
  const dispatch = useDispatch();

  // Accede a los datos de audiencia desde Redux
  const fetchAndMapAudience = useSelector(
    (state) => state.audience?.items || [],
    shallowEqual,
  );

  // Si `fetchAndMapAudience` aún no está disponible, se despacha la acción para obtenerlo
  useEffect(() => {
    if (fetchAndMapAudience.length === 0) {
      dispatch(fetchAudience()); // Despachar la acción si no hay audiencia cargada
    }
  }, [dispatch, fetchAndMapAudience.length]);

  useEffect(() => {
    // Obtenemos todos los contenidos de tipo 'unite.testimonial'
    const getTestimonials = async () => {
      const queryURL = '/++api++/@querystring-search';

      let query = [
        {
          i: 'portal_type',
          o: 'plone.app.querystring.operation.string.is',
          v: ['unite_testimonial'],
        },
        {
          i: 'review_state',
          o: 'plone.app.querystring.operation.selection.any',
          v: ['published'],
        },
      ];

      if (data.keywords && data.keywords.length > 0) {
        query.push({
          i: 'Subject',
          o: 'plone.app.querystring.operation.list.contains',
          v: data.keywords,
        });
      }

      // Verificar si fetchAndMapAudience y data.audience están definidos
      let mappedAudience = [];
      if (
        fetchAndMapAudience.length > 0 &&
        data.audience &&
        data.audience.length > 0
      ) {
        mappedAudience = data.audience
          .map(
            (aud) =>
              fetchAndMapAudience.find((item) => item.text === aud)?.['@id'],
          )
          .filter(Boolean);
      }

      if (mappedAudience.length > 0) {
        query.push({
          i: 'audience',
          o: 'plone.app.querystring.operation.list.contains',
          v: mappedAudience,
        });
      }

      try {
        const response = await axios.post(queryURL, {
          query: query,
          metadata_fields: '_all',
          fullobjects: 'True',
          limit: data.limit,
          sort_on: 'getObjPositionInParent',
          b_size: data.limit,
        });

        const items = response.data.items;
        setTestimonials(items);
      } catch (error) {
        console.error('Error fetching testimonials', error);
      }
    };

    getTestimonials();
  }, [data.limit, data.keywords]);

  let testimonial_cards = [];
  testimonials.map((testimonial) =>
    testimonial_cards.push(
      <TestimonialCard key={testimonial.UID} item={testimonial} />,
    ),
  );

  return (
    <div className="mb-unite">
      {testimonial_cards && testimonial_cards.length > 0 && (
        <>
          <Row className="mb-4">
            <Col md={5} className="mb-sm-4">
              {data.title ? <h2>{data.title}</h2> : null}
            </Col>
            <Col md={{ span: 6, offset: 1 }}>
              {data.description ? <p>{data.description}</p> : null}
            </Col>
          </Row>
          <Slider
            id="testimonials"
            items={testimonial_cards}
            items_col="4"
            items_md_col="6"
            items_xs_col="12"
            rotate_color={true}
          />
        </>
      )}
    </div>
  );
};

export default View;
