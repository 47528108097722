import React, { useEffect, useState } from 'react';

import { SlArrowUp, SlArrowDown } from 'react-icons/sl';
import { FaTimes } from 'react-icons/fa';

import axios from 'axios';

import Row from 'react-bootstrap/Row';

import './less/View.less';

const View = (props) => {
  const { data } = props;
  const [activeIndex, setActiveIndex] = useState(null);
  const [audienceObjects, setAudienceObjects] = useState([]);
  const [selectedAudiences, setSelectedAudiences] = useState([]);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toggleAudience = (audience) => {
    setSelectedAudiences((prevState) =>
      prevState.includes(audience)
        ? prevState.filter((aud) => aud !== audience)
        : [...prevState, audience],
    );
  };

  useEffect(() => {
    const getAudience = async () => {
      const audienceURL = '/++api++/@audience';
      try {
        const response = await axios.get(audienceURL);
        const items = response.data.items;
        setAudienceObjects(items);
      } catch (error) {
        console.error('Error fetching audience', error);
      }
    };
    getAudience();
  }, []);

  const filteredFaqs = data.FaqsBlocks?.filter(
    (faq) =>
      selectedAudiences.length === 0 ||
      faq.tags.some((tag) => selectedAudiences.includes(tag)),
  );

  return (
    <>
      <Row className="mb-unite faqs-wrapper">
        {data.title && <h2>{data.title}</h2>}
        {data.showAudiences && (
          <div className="unite-filters">
            {audienceObjects?.map((audience) => (
              <button
                key={audience['@id']}
                className={`filter-button${
                  selectedAudiences.includes(audience.text) ? ' selected' : ''
                }`}
                onClick={() => toggleAudience(audience.text)}
              >
                {selectedAudiences.includes(audience.text) && (
                  <FaTimes className="me-1" />
                )}
                {audience.text}
              </button>
            ))}
          </div>
        )}
        <div className="faq-items">
          {filteredFaqs && filteredFaqs.length > 0 ? (
            filteredFaqs?.map((item, index) => (
              <div key={index} className="faq-item rounded-4 mb-3 py-4 px-5">
                <div
                  className="faq-question d-flex justify-content-between"
                  onClick={() => handleToggle(index)}
                >
                  <p className="h5 mb-0 me-3">{item?.question}</p>
                  {item.answer &&
                    item.answer.data &&
                    (activeIndex === index ? (
                      <SlArrowUp
                        className="iconUpDown flex-shrink-0"
                        size="24px"
                      />
                    ) : (
                      <SlArrowDown
                        className="iconUpDown flex-shrink-0"
                        size="24px"
                      />
                    ))}
                </div>
                {data.showAudiences && item?.tags && (
                  <div className="d-flex flex-wrap column-gap-3 row-gap-2 mt-3">
                    {item?.tags.map((tag, index) => {
                      const audienceObject = audienceObjects.find(
                        (audience) => audience.text === tag,
                      );

                      return (
                        <span
                          key={audienceObject?.['@id']}
                          className="py-1 px-3 border border-dark rounded-pill text-uppercase fs-xs fw-bolder"
                        >
                          {audienceObject?.text || tag}
                        </span>
                      );
                    })}
                  </div>
                )}
                {item.answer && item.answer.data && (
                  <div
                    className="faq-answer"
                    style={{
                      maxHeight: activeIndex === index ? '1000px' : '0',
                    }}
                  >
                    <p
                      className="mt-3"
                      dangerouslySetInnerHTML={{ __html: item.answer.data }}
                    ></p>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No results found.</p>
          )}
        </div>
      </Row>
    </>
  );
};

export default View;
