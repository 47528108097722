import React from 'react';

import PartnerCard from '../../components/Partner/PartnerCard';

import { Container } from 'semantic-ui-react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const UnitePartnerBlockView = (props) => {
  const { properties } = props;

  if (
    !properties ||
    !(
      properties['@type'] === 'unite_partner' ||
      props.contentType === 'unite_partner'
    )
  ) {
    return (
      <div> This block is reserved for Unite Partner content type only. </div>
    );
  }

  return (
    <Container>
      <Row>
        <Col md={4} sm={6} xs={12}>
          <PartnerCard
            key={properties.UID}
            id={properties.id}
            name={properties.title}
            location={properties.partner_location}
            logo={properties.logo || undefined}
            negative_logo={properties.negative_logo || undefined}
            hexcolor={
              typeof properties.partner_hexcolor === 'object' &&
              properties.partner_hexcolor !== null
                ? properties.partner_hexcolor['title'] || '#644380'
                : properties.partner_hexcolor || '#644380'
            }
            url={properties.partner_url}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default UnitePartnerBlockView;
