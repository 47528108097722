import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Container, Segment } from 'semantic-ui-react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import {
  Anontools,
  LanguageSelector,
  Logo,
  Navigation,
  SearchWidget,
} from '@plone/volto/components';

import axios from 'axios';
import { fetchAudience } from '../../../../actions/audience';
import { colorAdjust } from 'volto-unite-web/utils';

import './less/Header.less';

const Header = ({ pathname }) => {
  const dispatch = useDispatch();

  // Accede a los datos de Redux
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const topLinks = useSelector(
    (state) => state.audience?.items || [],
    shallowEqual,
  );
  const [universityLinks, setUniversityLinks] = useState({
    text: '',
    links: {},
  });
  const [footerInfo, setFooterInfo] = useState({});

  // Si `topLinks` aún no está disponible, se despacha la acción para obtenerlo
  useEffect(() => {
    if (topLinks.length === 0 && topLinks !== undefined) {
      dispatch(fetchAudience());
    }
  }, [dispatch, topLinks.length]);

  // Fetch para `universityLinks`
  useEffect(() => {
    const getUniversityLinks = async () => {
      const url = '/++api++/@university-links';
      try {
        const response = await axios.get(url);
        const data = response.data;
        setUniversityLinks(data);
      } catch (error) {
        console.error('Error fetching header university links', error);
      }
    };

    const getFooterInfo = async () => {
      const url = '/++api++/@footer-info';
      try {
        const response = await axios.get(url);
        const items = response.data;
        setFooterInfo(items);
      } catch (error) {
        console.error('Error fetching audience', error);
      }
    };

    getFooterInfo();
    getUniversityLinks();
  }, []);

  const [sticky, setSticky] = useState(false);
  const handleSticky = () => {
    setSticky(
      window.scrollY > 1 && !document.body.classList.contains('view-editview'),
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', handleSticky, false);
    return () => {
      window.removeEventListener('scroll', handleSticky, false);
    };
  }, []);

  return (
    <div
      className={cx('header-wrapper', {
        'padding-bottom': sticky,
      })}
    >
      <div className={sticky ? 'ui fixed top sticky' : null}>
        <Segment basic className="header-wrapper" role="banner">
          <Container>
            <div className="header d-flex flex-row flex-nowrap column-gap-3 align-items-center">
              <div className="header-logo logo-nav-wrapper">
                <div className="logo">
                  <Logo />
                </div>
              </div>
              <div className="header-links d-flex flex-column row-gap-2">
                <div className="header-links-top large screen widescreen only">
                  <div className="public-links justify-content-end d-none d-md-flex flex-wrap column-gap-3 row-gap-2">
                    {topLinks &&
                      topLinks.map(
                        (link, index) =>
                          link.link &&
                          link.link.length > 0 && (
                            <Link
                              key={link.link[0]['id']}
                              to={link.link[0]['@id']}
                              style={{
                                '--pill-color': link.color,
                                '--pill-color-hover': colorAdjust(
                                  link.color,
                                  -10,
                                ),
                              }}
                              className="public-link py-1 px-3 rounded-pill"
                            >
                              {link.text}
                            </Link>
                          ),
                      )}
                  </div>
                </div>
                <div className="header-links-bottom d-flex justify-content-end">
                  <Navigation
                    pathname={pathname}
                    topLinks={topLinks}
                    universityLinks={universityLinks}
                    footerInfo={footerInfo}
                  />
                  <div className="tools-search-wrapper ms-0 flex-grow-0 column-gap-2">
                    {!token && (
                      <div className="tools tablet computer large screen widescreen only">
                        <Anontools />
                      </div>
                    )}
                    <div className="search">
                      <SearchWidget />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Segment>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  token: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  content: PropTypes.objectOf(PropTypes.any),
};

Header.defaultProps = {
  token: null,
  content: null,
};
