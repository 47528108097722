import React from 'react';

import Slider from '../../components/Slider/Slider';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PartnerCard from '../../components/Partner/PartnerCard';

import './less/View.less';

const View = (props) => {
  const { data } = props;

  let associate_partners_cards = [];
  if (data.associatePartnersBlocks && data.associatePartnersBlocks.length > 0) {
    associate_partners_cards = data.associatePartnersBlocks.map(
      (item, index) => (
        <PartnerCard
          key={item['@id']}
          id={item['@id']}
          name={item?.name}
          location={item?.location}
          logo={item?.logo && item.logo.length > 0 ? item.logo[0] : null}
          negative_logo={
            item?.negative_logo && item.negative_logo.length > 0
              ? item.negative_logo[0]
              : null
          }
          hexcolor={
            typeof item.partner_hexcolor === 'object' &&
            item.partner_hexcolor !== null
              ? item.partner_hexcolor['title'] || '#644380'
              : item.partner_hexcolor || '#644380'
          }
          url={item?.link && item.link.length > 0 ? item.link[0]['@id'] : null}
        />
      ),
    );
  }

  return (
    <>
      <Row className="mb-unite associate-partners-wrapper">
        <Col>
          <Row className="mb-4 align-items-end">
            <Col md={6}>{data.title && <h2>{data.title}</h2>}</Col>
          </Row>
          {associate_partners_cards && associate_partners_cards.length > 0 && (
            <Slider
              id="associatePartner"
              items={associate_partners_cards}
              items_col="3"
              items_md_col="6"
              items_xs_col="12"
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default View;
