import moment from 'moment';

import Card from 'react-bootstrap/Card';
import CardSubtitle from 'react-bootstrap/CardSubtitle';
import { BsArrowRight, BsDownload, BsBoxArrowUpRight } from 'react-icons/bs';

import './less/ResourceCard.less';

const ResourceCard = ({
  id,
  title,
  description,
  date,
  related_info,
  links_download,
  categories,
}) => {
  const formattedDate = moment(date).format('D MMMM YYYY');
  return (
    <Card key={id} className="resource-card text-bg-light border-0 mb-4">
      <Card.Body>
        {/* <Card.Text className="text-muted mb-1">{formattedDate}</Card.Text> */}
        <Card.Text className="card-title fw-bold mb-3">{title}</Card.Text>
        <Card.Text>{description}</Card.Text>
        {related_info && related_info.length > 0 && (
          <>
            <hr className="hr pt-3" />
            <CardSubtitle className="mb-3">RELATED INFO</CardSubtitle>
            {related_info.map(
              (item) =>
                item && (
                  <p className="mb-2">
                    <BsArrowRight className="me-2" />
                    <a
                      href={item['@id']}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </a>
                  </p>
                ),
            )}
          </>
        )}
        {/* <hr className="hr pt-3" />
        <CardSubtitle className="mb-3">CATEGORIES</CardSubtitle>
        {categories &&
          categories.length > 0 &&
          categories.map((item) => (
            <a className="ui label" href={`/search-unite?Subject=${item}`}>
              {item}
            </a>
          ))} */}
        {links_download && links_download.length > 0 && (
          <>
            <hr className="hr pt-3" />
            <CardSubtitle className="mb-3">LINKS & DOWNLOADS</CardSubtitle>
            <div className="d-flex flex-wrap">
              {links_download.map(
                (item) =>
                  item && (
                    <a
                      href={
                        item['@type'] === 'File'
                          ? `${item['@id']}/@@download/file`
                          : item['@id']
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ui label reverse d-flex align-items-center"
                    >
                      {item['@type'] === 'File' ? (
                        <BsDownload className="me-2 flex-shrink-0" />
                      ) : (
                        <BsBoxArrowUpRight className="me-2 flex-shrink-0" />
                      )}{' '}
                      {item.title}
                    </a>
                  ),
              )}
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ResourceCard;
