import React from 'react';
import config from '@plone/volto/registry';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import './less/Disclaimer.less';

const Disclaimer = ({ item }) => {
  const Image = config.getComponent({ name: 'Image' }).component;

  return (
    <>
      <Row className="disclaimer-wrapper mt-5">
        <Col
          lg={4}
          md={12}
          className="d-flex flex-column justify-content-center"
        >
          {item?.left_logo && (
            <Image
              item={item.left_logo}
              alt={item.alt_left_logo || ''}
              image_field="image"
              className="ui image object-fit-cover img-fluid"
              responsive={true}
              loading="lazy"
            />
          )}
        </Col>
        <Col
          lg={8}
          md={12}
          className="d-flex flex-column justify-content-center"
        >
          {item?.text_above && (
            <div
              className="mb-3"
              dangerouslySetInnerHTML={{ __html: item.text_above.data }}
            ></div>
          )}
          <Row>
            <Col lg={8} md={12}>
              {item?.text_below && (
                <div
                  className="fs-s text_below_disclaimer"
                  dangerouslySetInnerHTML={{
                    __html: item.text_below.data,
                  }}
                ></div>
              )}
            </Col>
            <Col
              lg={4}
              md={12}
              className="d-flex flex-column justify-content-center align-items-end"
            >
              {item?.right_logo && (
                <Image
                  item={item.right_logo}
                  alt={item.alt_right_logo || ''}
                  image_field="image"
                  className="ui image object-fit-cover img-fluid"
                  responsive={true}
                  loading="lazy"
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Disclaimer;
