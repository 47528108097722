import React, { useState } from 'react';
import ClampLines from 'react-clamp-lines';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import config from '@plone/volto/registry';
import './less/View.less';

const View = (props) => {
  const { data } = props;
  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (index) => {
    // Reduce la opacidad de todos los contenidos de tab
    document.querySelectorAll('.content-transition').forEach((el) => {
      el.style.opacity = 0;
    });

    // Espera a que la transición termine antes de cambiar el contenido
    setTimeout(() => {
      setSelectedTab(index);
      // Restaura la opacidad después de cambiar el contenido
      setTimeout(() => {
        document.querySelectorAll('.content-transition').forEach((el) => {
          el.style.opacity = 1;
        });
      }, 50); // Un pequeño retardo para asegurar que el contenido se haya actualizado
    }, 200); // Ajusta este tiempo al de la duración de tu transición
  };

  return (
    <>
      <Row className="mb-unite tabs-wrapper">
        {data.title && <h2>{data.title}</h2>}
        <Col lg={4} className="image-column mb-3 mb-lg-0">
          {data?.TabsBlocks?.[selectedTab]?.image &&
          data.TabsBlocks[selectedTab].image.length > 0 ? (
            <PreviewImage
              item={data.TabsBlocks[selectedTab].image[0]}
              alt={data.TabsBlocks[selectedTab].image_alt || ''}
              className="img-fluid content-transition rounded-4"
              responsive={true}
              loading="lazy"
            />
          ) : null}
        </Col>
        <Col
          lg={{ offset: 1, span: 6 }}
          className="text-column align-content-center"
        >
          <div className="unite-filters">
            {data?.TabsBlocks?.map((tab, index) => (
              <button
                key={index}
                className={`filter-button${
                  selectedTab === index ? ' selected' : ''
                }`}
                onClick={() => handleTabClick(index)}
              >
                {tab.title}
              </button>
            ))}
          </div>
          {data?.TabsBlocks?.[selectedTab] && (
            <div className="tab-content content-transition">
              <p className="h2">{data.TabsBlocks[selectedTab].title}</p>
              <div>
                {data.TabsBlocks[selectedTab].description ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.TabsBlocks[selectedTab].description.data,
                    }}
                  />
                ) : null}
              </div>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default View;
