import React from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import Icon from '../../../Utils/Icon/Icon';

import './less/View.less';

const View = (props) => {
  const { data } = props;
  const style = data.style || 'primary';

  return (
    <>
      <Alert variant={style} dismissible={data.show_close} className="alert-unite rounded-4">
        {data.heading &&
          <Alert.Heading>
            {data.icon &&
              <Icon
                iconName={data.icon}
                type="bs"
                size={20}
                className="me-2 mb-1"
              />
            }
            {data.heading}
          </Alert.Heading>
        }
        {!data.heading && data.icon && (
          <Icon
            iconName={data.icon}
            type="bs"
            size={16}
            className="me-2 float-start"
          />
        )}
        {data.text &&
          <div className="alert-text" dangerouslySetInnerHTML={{ __html: data.text.data }} />
        }
        {data.buttons && data.buttons.length > 0 && (
          <>
            <hr />
            <div className="d-flex justify-content-end">
              {data.buttons.map((button, index) => (
                <Button
                  key={index}
                  variant={`outline-${style}`}
                  href={button.link}
                  target={button.openInNewTab ? '_blank' : '_self'}
                >
                  {button.text}
                </Button>
              ))}
            </div>
          </>
        )}
      </Alert>
    </>
  );
};

export default View;
