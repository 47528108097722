import audienceReducer from './reducers/audience';

import 'bootstrap/dist/css/bootstrap.min.css';

import {
  PartnersView,
  PartnersEdit,
  TestimonialsView,
  TestimonialsEdit,
  UnitePartnerBlockView,
  UnitePartnerBlockEdit,
  UniteResourceBlockView,
  UniteResourceBlockEdit,
  UniteTestimonialBlockView,
  UniteTestimonialBlockEdit,
  SectionView,
  SectionEdit,
  MarqueeBlockView,
  MarqueeBlockEdit,
  UniteNewsItemBlockView,
  UniteNewsItemBlockEdit,
  SliderHomeBlockView,
  SliderHomeBlockEdit,
  SectionMarqueeView,
  SectionMarqueeEdit,
  NewsEventsView,
  NewsEventsEdit,
  EventsView,
  EventsEdit,
  EventsToogleListCalendarView,
  EventsToogleListCalendarEdit,
  NumbersView,
  NumbersEdit,
  UniteSummaryBlockView,
  EventMetadataView,
  TitleTagsBlockView,
  PreviewImageBlockView,
  BoxesView,
  BoxesEdit,
  AssociatePartnersView,
  AssociatePartnersEdit,
  FaqsView,
  FaqsEdit,
  TabsView,
  TabsEdit,
  UniteDisclaimerBlockView,
  UniteDisclaimerBlockEdit,
  CirclesView,
  CirclesEdit,
  DisclaimerBlockView,
  DisclaimerBlockEdit,
  UniteRepresentativeBlockView,
  UniteRepresentativeBlockEdit,
  WhatsNewsEdit,
  WhatsNewsView,
  RepresentativesView,
  RepresentativesEdit,
  WhoIsWhoView,
  WhoIsWhoEdit,
  VinculatedColumnView,
  VinculatedColumnEdit,
  AlertView,
  AlertEdit,
  BannerView,
  BannerEdit,
  MoreInformationView,
  MoreInformationEdit,
  BlockColorView,
  BlockColorEdit,
  CiteView,
  CiteEdit,
  VideoYoutubeView,
  VideoYoutubeEdit,
  LatestsResourcesView,
  LatestsResourcesEdit,
  WhatsResourcesEdit,
  WhatsResourcesView,
  SuggestionBoxEdit,
  SuggestionBoxView,
  ProjectsView,
  ProjectsEdit,
  AgoraFeaturedCatalogueView,
  AgoraFeaturedCatalogueEdit,
  BoxMediaView,
  BoxMediaEdit,
  InvisibleH1View,
  InvisibleH1Edit,
  ForceReloadView,
} from './components';

// import FullCalendarBlockSchema from '@mbarde/volto-fullcalendar-block/components/manage/Blocks/FullCalendar/schema';

import TopLinksWidget from './components/Widgets/TopLinksWidget';
import LinksWidget from './components/Widgets/LinksWidget';
import IconsLinkWidget from './components/Widgets/IconsLinkWidget';
import BlockSettingsSchema from '@plone/volto/components/manage/Blocks/Block/Schema';

import alertSVG from '@plone/volto/icons/alert.svg';
import appsSVG from '@plone/volto/icons/apps.svg';
import calendarSVG from '@plone/volto/icons/calendar.svg';
import cardsSVG from '@plone/volto/icons/cards.svg';
import circleMenuSVG from '@plone/volto/icons/circle-menu.svg';
import clockSVG from '@plone/volto/icons/clock.svg';
import collapseDownSVG from '@plone/volto/icons/collapse-down.svg';
import contentListingSVG from '@plone/volto/icons/content-listing.svg';
import contentSVG from '@plone/volto/icons/content.svg';
import descriptionSVG from '@plone/volto/icons/description.svg';
import enterpriseSVG from '@plone/volto/icons/enterprise.svg';
import eventSVG from '@plone/volto/icons/event.svg';
import forceSVG from '@plone/volto/icons/reload.svg';
import groupSVG from '@plone/volto/icons/group.svg';
import heroSVG from '@plone/volto/icons/hero.svg';
import imageFullSVG from '@plone/volto/icons/image-full.svg';
import imageLeftSVG from '@plone/volto/icons/image-left.svg';
import infoSVG from '@plone/volto/icons/info.svg';
import linkSVG from '@plone/volto/icons/link.svg';
import listArrowsSVG from '@plone/volto/icons/list-arrows.svg';
import newsSVG from '@plone/volto/icons/news.svg';
import paintSVG from '@plone/volto/icons/paint.svg';
import playSVG from '@plone/volto/icons/play.svg';
import quoteSVG from '@plone/volto/icons/quote.svg';
import registrySVG from '@plone/volto/icons/registry.svg';
import showBlocksSVG from '@plone/volto/icons/show-blocks.svg';
import sliderSVG from '@plone/volto/icons/slider.svg';
import summarySVG from '@plone/volto/icons/summary.svg';
import textSVG from '@plone/volto/icons/text.svg';
import themeSVG from '@plone/volto/icons/theme.svg';
import userSVG from '@plone/volto/icons/user.svg';
import videoSVG from '@plone/volto/icons/video.svg';
import warningSVG from '@plone/volto/icons/warning.svg';
import yieldSVG from '@plone/volto/icons/yield.svg';

import { Icon } from '@plone/volto/components';

import SocialSharing from '@codesyntax/volto-social-sharing/SocialSharing';
import { DEFAULT_SOCIAL } from '@codesyntax/volto-social-sharing/defaultSettings';

const applyConfig = (config) => {
  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['en'],
    defaultLanguage: 'en',
    navDepth: 3,
    showTags: false,
    siteTitleFormat: {
      includeSiteTitle: true,
      titleAndSiteTitleSeparator: '-',
    },
    openExternalLinkInNewTab: true,
    cookiebotDomainGroupId: '42f017b2-94ee-480a-ad95-d0297d836c64',
  };

  if (__SERVER__) {
    const express = require('express');
    const middleware = express.Router();

    middleware.use((req, res, next) => {
      res.setHeader(
        'Strict-Transport-Security',
        'max-age=604800; includeSubDomains; preload',
      );
      res.setHeader('X-Frame-Options', 'SAMEORIGIN');
      // res.setHeader('X-XSS-Protection', '1; mode=block');
      // res.setHeader('X-Content-Type-Options', 'nosniff');
      // res.setHeader(
      //   'Content-Security-Policy',
      //   "default-src 'self'; img-src * data:; script-src 'self' 'unsafe-inline' 'unsafe-eval' www.upc.edu https://*.cookiebot.com tagmanager.google.com https://*.twimg.com https://*.twitter.com *.gstatic.com *.google-analytics.com https://*.googleapis.com https://*.google.com https://*.googletagmanager.com https://*.usercentrics.eu data: http://localhost:3001; font-src * data:; style-src * data: 'unsafe-inline' 'unsafe-eval'; child-src *.upc.edu https://*.twitter.com https://*.google.com; worker-src *.upc.edu https://*.twitter.com https://*.google.com; media-src *.upc.edu http://localhost:3000 http://backend:8080 https://novawebunite.pre.upcnet.es; frame-src https://agora.unite-university.eu *.youtube-nocookie.com youtu.be *.vimeo.com *.youtube.com *.twitter.com https://*.cookiebot.com twitter.com *.upc.edu www.google.com; connect-src https://*.usercentrics.eu https://consentcdn.cookiebot.com https://stats.g.doubleclick.net https://*.analytics.google.com https://*.google-analytics.com *.amazonaws.com ws://localhost:3001 http://localhost:3001 'self'",
      // );
      // res.setHeader('X-Permitted-Cross-Domain-Policies', 'none');
      // res.setHeader('Referrer-Policy', 'strict-origin-when-cross-origin');
      // res.setHeader('Clear-Site-Data', '"cache", "cookies", "storage"');
      // res.setHeader('Cross-Origin-Embedder-Policy', 'same-origin');
      // res.setHeader('Cross-Origin-Opener-Policy', 'same-origin');
      // res.setHeader('Cross-Origin-Resource-Policy', 'same-origin');
      next();
    });
    middleware.id = 'custom';
    config.settings.expressMiddleware.push(middleware);
  }

  config.addonReducers = {
    ...config.addonReducers,
    audience: audienceReducer,
  };

  config.blocks.blocksConfig.unite_partner_block = {
    id: 'unite_partner_block',
    title: 'Unite Partner Block',
    icon: enterpriseSVG,
    group: 'content_types',
    view: UnitePartnerBlockView,
    edit: UnitePartnerBlockEdit,
    restricted: true,
    mostUsed: false,
    sidebarTab: 0,
    blockHasOwnFocusManagement: true,
  };

  config.blocks.blocksConfig.partners = {
    id: 'partners',
    title: 'Partners',
    icon: enterpriseSVG,
    group: 'unite',
    view: PartnersView,
    edit: PartnersEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.unite_testimonial_block = {
    id: 'unite_testimonial_block',
    title: 'Unite Testimonial Block',
    icon: listArrowsSVG,
    group: 'unite_content_types',
    view: UniteTestimonialBlockView,
    edit: UniteTestimonialBlockEdit,
    restricted: true,
    mostUsed: false,
    sidebarTab: 0,
    blockHasOwnFocusManagement: true,
  };

  config.blocks.blocksConfig.testimonials = {
    id: 'testimonials',
    title: 'Testimonial',
    icon: userSVG,
    group: 'unite',
    view: TestimonialsView,
    edit: TestimonialsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.unite_resource_block = {
    id: 'unite_resource_block',
    title: 'Unite Resource Block',
    icon: listArrowsSVG,
    group: 'unite_content_types',
    view: UniteResourceBlockView,
    edit: UniteResourceBlockEdit,
    restricted: true,
    mostUsed: false,
    sidebarTab: 0,
    blockHasOwnFocusManagement: true,
  };

  config.blocks.blocksConfig.section = {
    id: 'section',
    title: 'Short description + button',
    icon: contentListingSVG,
    group: 'unite',
    view: SectionView,
    edit: SectionEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.marquee = {
    id: 'marquee',
    title: 'Marquee',
    icon: imageFullSVG,
    group: 'unite',
    view: MarqueeBlockView,
    edit: MarqueeBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.sliderhome = {
    id: 'sliderhome',
    title: 'Slider Home',
    icon: sliderSVG,
    group: 'unite',
    view: SliderHomeBlockView,
    edit: SliderHomeBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.unite_newsitem_block = {
    id: 'unite_newsitem_block',
    title: 'Unite News Item Block',
    icon: enterpriseSVG,
    group: 'unite_content_types',
    view: UniteNewsItemBlockView,
    edit: UniteNewsItemBlockEdit,
    restricted: true,
    mostUsed: false,
    sidebarTab: 0,
  };

  config.blocks.blocksConfig.unite_summary_block = {
    id: 'unite_summary_block',
    title: 'Summary',
    icon: summarySVG,
    group: 'unite_extras',
    view: UniteSummaryBlockView,
    edit: UniteSummaryBlockView,
    restricted: false,
    mostUsed: false,
    sidebarTab: 0,
    blocks: {},
  };

  config.blocks.blocksConfig.eventMetadata = {
    id: 'eventMetadata',
    title: 'EventMetadata',
    icon: descriptionSVG,
    group: 'unite',
    view: EventMetadataView,
    edit: EventMetadataView,
    schema: BlockSettingsSchema,
    restricted: true,
    mostUsed: false,
    sidebarTab: 0,
    blocks: {},
  };

  config.blocks.blocksConfig.titletags = {
    id: 'titletags',
    title: 'Title Tags',
    icon: textSVG,
    group: 'unite',
    view: TitleTagsBlockView,
    edit: TitleTagsBlockView,
    restricted: true, //Esto permite que lo puedas usar en layouts pero que no este disponible para el usuario
    mostUsed: false,
    sidebarTab: 0,
  };

  config.blocks.blocksConfig.previewimage = {
    id: 'previewimage',
    title: 'Preview Image',
    icon: textSVG,
    group: 'unite',
    view: PreviewImageBlockView,
    edit: PreviewImageBlockView,
    restricted: true,
    mostUsed: false,
    sidebarTab: 0,
  };

  config.blocks.blocksConfig.sectionmarquee = {
    id: 'sectionmarquee',
    title: 'Banner + photo + link',
    icon: imageLeftSVG,
    group: 'unite_extras',
    view: SectionMarqueeView,
    edit: SectionMarqueeEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.newsevents = {
    id: 'newsevents',
    title: 'Latest News',
    icon: eventSVG,
    group: 'unite',
    view: NewsEventsView,
    edit: NewsEventsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.events = {
    id: 'events',
    title: 'Latest Events',
    icon: eventSVG,
    group: 'unite',
    view: EventsView,
    edit: EventsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.eventstogglelistcalendar = {
    id: 'eventstogglelistcalendar',
    title: 'Calendar filters',
    icon: calendarSVG,
    group: 'unite',
    view: EventsToogleListCalendarView,
    edit: EventsToogleListCalendarEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.numbers = {
    id: 'numbers',
    title: 'Fact & figures',
    icon: contentSVG,
    group: 'unite',
    view: NumbersView,
    edit: NumbersEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.boxes = {
    id: 'boxes',
    title: 'Boxes',
    icon: registrySVG,
    group: 'unite',
    view: BoxesView,
    edit: BoxesEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.associatepartners = {
    id: 'associatepartners',
    title: 'Associated partners',
    icon: groupSVG,
    group: 'unite',
    view: AssociatePartnersView,
    edit: AssociatePartnersEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.faqs = {
    id: 'faqs',
    title: 'Faqs',
    icon: infoSVG,
    group: 'unite',
    view: FaqsView,
    edit: FaqsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.tabs = {
    id: 'tabs',
    title: 'Horizontal dropdown text',
    icon: collapseDownSVG,
    group: 'unite',
    view: TabsView,
    edit: TabsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.unite_disclaimer_block = {
    id: 'unite_disclaimer_block',
    title: 'Unite Disclaimer Block',
    icon: listArrowsSVG,
    group: 'unite_content_types',
    view: UniteDisclaimerBlockView,
    edit: UniteDisclaimerBlockEdit,
    restricted: true,
    mostUsed: false,
    sidebarTab: 0,
    blockHasOwnFocusManagement: true,
  };

  config.blocks.blocksConfig.circles = {
    id: 'circles',
    title: 'Circles dropdown text',
    icon: circleMenuSVG,
    group: 'unite',
    view: CirclesView,
    edit: CirclesEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.whats_news = {
    id: 'whats_news',
    title: 'News catalogue',
    icon: newsSVG,
    group: 'unite',
    view: WhatsNewsView,
    edit: WhatsNewsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.unite_representative_block = {
    id: 'unite_representative_block',
    title: 'Unite Representative Block',
    icon: listArrowsSVG,
    group: 'unite_content_types',
    view: UniteRepresentativeBlockView,
    edit: UniteRepresentativeBlockEdit,
    restricted: true,
    mostUsed: false,
    sidebarTab: 0,
    blockHasOwnFocusManagement: true,
  };

  config.blocks.blocksConfig.representatives = {
    id: 'representatives',
    title: 'Organigram',
    icon: yieldSVG,
    group: 'unite',
    view: RepresentativesView,
    edit: RepresentativesEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.whoiswho = {
    id: 'whoiswho',
    title: 'Flow Chart text',
    icon: contentSVG,
    group: 'unite',
    view: WhoIsWhoView,
    edit: WhoIsWhoEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.vinculatedcolumn = {
    id: 'vinculatedcolumn',
    title: 'Vinculated Column',
    icon: linkSVG,
    group: 'unite',
    view: VinculatedColumnView,
    edit: VinculatedColumnEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.latestsresources = {
    id: 'latestsresources',
    title: 'Latests Resources',
    icon: clockSVG,
    group: 'unite',
    view: LatestsResourcesView,
    edit: LatestsResourcesEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.whats_resources = {
    id: 'whats_resources',
    title: 'Resources catalog',
    icon: appsSVG,
    group: 'unite',
    view: WhatsResourcesView,
    edit: WhatsResourcesEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.suggestion_box = {
    id: 'suggestion_box',
    title: 'Banner + button',
    icon: showBlocksSVG,
    group: 'unite',
    view: SuggestionBoxView,
    edit: SuggestionBoxEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.projects = {
    id: 'projects',
    title: 'Projects',
    icon: appsSVG,
    group: 'unite',
    view: ProjectsView,
    edit: ProjectsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.invisible_h1 = {
    id: 'invisible_h1',
    title: 'Invisible H1',
    icon: textSVG,
    group: 'unite_extras',
    view: InvisibleH1View,
    edit: InvisibleH1Edit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.force_reload = {
    id: 'force_reload',
    title: 'Force Reload',
    icon: forceSVG,
    group: 'unite_extras',
    view: ForceReloadView,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.agora_featured_catalogue = {
    id: 'agora_featured_catalogue',
    title: 'Agora Featured Catalogue',
    icon: cardsSVG,
    group: 'unite',
    view: AgoraFeaturedCatalogueView,
    edit: AgoraFeaturedCatalogueEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.box_media = {
    id: 'box_media',
    title: 'Boxes with image + media link',
    icon: playSVG,
    group: 'unite',
    view: BoxMediaView,
    edit: BoxMediaEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  /* TEMPLATES */

  config.blocks.blocksConfig.disclaimer = {
    id: 'disclaimer',
    title: 'Disclaimer',
    icon: warningSVG,
    group: 'unite_templates',
    view: DisclaimerBlockView,
    edit: DisclaimerBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.alert = {
    id: 'alert',
    title: 'Banner + icon',
    icon: alertSVG,
    group: 'unite_templates',
    view: AlertView,
    edit: AlertEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.banner = {
    id: 'banner',
    title: 'Clickable banner',
    icon: heroSVG,
    group: 'unite_templates',
    view: BannerView,
    edit: BannerEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.more_information = {
    id: 'more_information',
    title: 'More Information',
    icon: infoSVG,
    group: 'unite_templates',
    view: MoreInformationView,
    edit: MoreInformationEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.block_color = {
    id: 'block_color',
    title: 'Highlights',
    icon: themeSVG,
    group: 'unite_templates',
    view: BlockColorView,
    edit: BlockColorEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.cite = {
    id: 'cite',
    title: 'Quote',
    icon: quoteSVG,
    group: 'unite_templates',
    view: CiteView,
    edit: CiteEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  config.blocks.blocksConfig.video_youtube = {
    id: 'video_youtube',
    title: 'Video Youtube',
    icon: videoSVG,
    group: 'unite_templates',
    view: VideoYoutubeView,
    edit: VideoYoutubeEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blocks: {},
  };

  /* FIN TEMPLATES */

  // config.blocks.blocksConfig.listing.variations = [
  //   ...config.blocks.blocksConfig.listing.variations,
  //   {
  //     id: 'eventstogglelistcalendar',
  //     isDefault: false,
  //     title: 'Events Toggle List / Calendar',
  //     template: EventsListCalendar,
  //     /* use schemaEnhancer to add fields of FullCalendarBlock here */
  //     // schemaEnhancer: ({ schema, formData, intl }) => {
  //     //   const blockSchema = FullCalendarBlockSchema(intl);
  //     //   Object.keys(blockSchema.properties).forEach((key) => {
  //     //     if (key !== 'calendar_url') {
  //     //       schema.properties[key] = blockSchema.properties[key];
  //     //       schema.fieldsets[0].fields.push(key);
  //     //     }
  //     //   });
  //     //   return schema;
  //     // },
  //   },
  // ];

  // config.blocks.blocksConfig.gridBlock.allowedBlocks = [
  //   ...config.blocks.blocksConfig.gridBlock.allowedBlocks,
  //   'partners',
  // ];

  // config.blocks.blocksConfig.gridBlock.blocksConfig = {
  //   ...config.blocks.blocksConfig.gridBlock.blocksConfig,
  //   partners: config.blocks.blocksConfig.partners,
  // };

  config.settings.appExtras = [
    ...config.settings.appExtras,
    {
      match: {
        path: ['/unite/unitenews/**'],
        exact: true,
      },
      component: SocialSharing,
    },
  ];

  // Find social item by id
  let twitter = DEFAULT_SOCIAL.find((social) => social.id == 'tw');
  if (twitter) {
    twitter.sharing_url = 'https://twitter.com/intent/tweet?url=';
  }

  // Push new item
  DEFAULT_SOCIAL.push({
    name: 'LinkedIn',
    fa_name: ['fab', 'linkedin'],
    color: '#0e76a8',
    sharing_url: 'https://www.linkedin.com/sharing/share-offsite/?url=',
    id: 'lk',
  });

  // Remove social item by id
  const index = DEFAULT_SOCIAL.findIndex((social) => social.id === 'fb');
  if (index !== undefined) DEFAULT_SOCIAL.splice(index, 1);

  config.settings.contentIcons = {
    ...config.settings.contentIcons,
    unite_partner: enterpriseSVG,
    unite_resource: listArrowsSVG,
    unite_testimonial: listArrowsSVG,
    unite_disclaimer: listArrowsSVG,
  };

  config.blocks.groupBlocksOrder = [
    { id: 'mostUsed', title: 'Most used' },
    { id: 'text', title: 'Text' },
    { id: 'media', title: 'Media' },
    { id: 'common', title: 'Common' },
    { id: 'unite', title: 'Unite' },
    { id: 'unite_content_types', title: 'Unite content types' },
    { id: 'unite_templates', title: 'Unite templates' },
    { id: 'unite_extras', title: 'Unite extras' },
  ];

  config.blocks.initialBlocks = {
    unite_partner: [
      { '@type': 'unite_partner_block', fixed: true, required: true },
    ],
    unite_resource: [
      { '@type': 'unite_resource_block', fixed: true, required: true },
    ],
    unite_testimonial: [
      { '@type': 'unite_testimonial_block', fixed: true, required: true },
    ],
    unite_disclaimer: [
      { '@type': 'unite_disclaimer_block', fixed: true, required: true },
    ],
    unite_representative: [
      { '@type': 'unite_representative_block', fixed: true, required: true },
    ],
    'News Item': [
      { '@type': 'unite_newsitem_block', fixed: true, required: true },
      { '@type': 'slate', fixed: true, required: true },
    ],
  };

  config.settings.slate.styleMenu = {
    ...(config.settings.slate.styleMenu || {}),
    inlineStyles: [
      {
        cssClass: 'mx-2 btn btn-primary',
        label: 'Button',
        icon: () => <Icon name={paintSVG} size="18px" />,
      },
    ],
  };

  config.widgets.widget.top_links_widget = TopLinksWidget;
  config.widgets.widget.links_widget = LinksWidget;
  config.widgets.widget.icons_link_widget = IconsLinkWidget;

  config.blocks.requiredBlocks = [];

  config.settings.available_colors = [
    '#4b4b4b',
    '#836999',
    '#644380',
    '#4B3260',
    '#7D82A9',
    '#5C6394',
    '#525985',
    '#4A7DA5',
    '#3A6382',
    '#57A6BF',
    '#2C7A91',
    '#276D82',
    '#65ADAE',
    '#357D7F',
    '#2F7072',
    '#4FA789',
    '#469479',
    '#3F856C',
    '#4D9457',
    '#43804B',
    '#416E91',
    '#3C7343',
    '#e3e3e3',
    '#ffffff',
  ];

  config.settings.dark_colors = [
    '#564C60',
    '#644380',
    '#5C6390',
    '#1E3F5E',
    '#416e91',
    '#277892',
    '#317A7B',
    '#334733',
    '#387B63',
    '#3F7C46',
  ];

  return config;
};

export default applyConfig;
