import React from 'react';

import { flattenToAppURL } from '@plone/volto/helpers';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { BiSolidQuoteAltLeft } from 'react-icons/bi';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';

import './less/RepresentativeInfo.less';
import PreviewImagePT from '../../Utils/PreviewImage/PreviewImagePT';

const isLinkedinURL = (value) => {
  const pattern = /^https?:\/\/(www\.)?linkedin\.com\/.*$/;
  if (pattern.test(value)) {
    return true;
  }
  return false;
};

const RepresentativeInfo = ({ item }) => {
  return (
    <Row
      key={item.UID}
      className="representative-info-wrapper justify-content-center"
    >
      <Col
        xxl={4}
        xl={5}
        lg={6}
        md={8}
        sm={10}
        className="representative-info-img-block mh-100"
      >
        {item.image ? (
          <PreviewImagePT
            item={item.image}
            alt={item.title || ''}
            imageField="image"
            className="ui image representative-info-img rounded-4 object-fit-cover"
            responsive={true}
            loading="lazy"
          />
        ) : null}
        {item.quoted && (
          <div className="representative-info-quoted text-white pb-5 d-flex flex-row align-items-baseline">
            <BiSolidQuoteAltLeft size={40} className="me-2 pt-1" />
            <p className="mb-0">{item.quoted}</p>
          </div>
        )}
      </Col>
      <Col xxl={8} xl={7} lg={6}>
        <Card className="representative-info-card rounded-4 w-100 px-4 py-5">
          <Card.Body className="h-100 d-grid align-content-between">
            <div className="border-start border-2 border-white py-1 ps-3 mb-5">
              <Card.Title className="mb-1">{item.title}</Card.Title>
              {item.rol && <Card.Text className="mb-1">{item.rol}</Card.Text>}
              {item.university_name && (
                <Card.Text className="mb-1">{item.university_name}</Card.Text>
              )}
              {item.linkedin && (
                <a
                  href={flattenToAppURL(item.linkedin)}
                  className="mb-0 text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-labelledby={`Access ${item.title} LinkedIn profile`}
                >
                  {isLinkedinURL(item.linkedin) ? (
                    <BsLinkedin size={25} />
                  ) : (
                    <BsBoxArrowUpRight size={25} />
                  )}
                </a>
              )}
            </div>
            {item.text && (
              <div
                className="overflow-y-auto"
                dangerouslySetInnerHTML={{
                  __html: item.text.data,
                }}
              ></div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default RepresentativeInfo;
