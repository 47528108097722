export const schemaTestimonial = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'description', 'keywords', 'audience', 'limit'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      description: {
        title: 'Description',
        widget: 'textarea',
      },
      keywords: {
        title: 'Keywords',
        type: 'array',
        widget: 'array',
        items: {
          vocabulary: { '@id': 'plone.app.vocabularies.Keywords' },
        },
      },
      audience: {
        title: 'Audience',
        description: 'Add audience to categorize your events',
        widget: 'token',
        vocabulary: {
          '@id': '/@vocabularies/unite.audience',
        },
      },
      limit: {
        title: 'Element limit',
        widget: 'integer',
      },
    },
  };
};

export default schemaTestimonial;
