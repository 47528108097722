import axios from 'axios';

// Define el tipo de acción
export const FETCH_AUDIENCE_SUCCESS = 'FETCH_AUDIENCE_SUCCESS';

// Acción para obtener los datos de audiencia
export const fetchAudience = () => async (dispatch, getState) => {
  const { audience } = getState();
  if (audience.isLoading || audience.items.length > 0) {
    return; // Salir si ya está cargando o si los datos ya existen
  }

  dispatch({ type: 'FETCH_AUDIENCE_START' });
  try {
    const response = await axios.get('/++api++/@audience');
    dispatch({ type: FETCH_AUDIENCE_SUCCESS, payload: response.data.items });
  } catch (error) {
    dispatch({ type: 'FETCH_AUDIENCE_FAILURE', error: error.message });
    console.error('Error fetching audience', error);
  }
};
