import React from 'react';

import RepresentativeInfo from '../../components/Representative/RepresentativeInfo';

const UniteRepresentativeBlockView = (props) => {
  const { properties } = props;

  if (
    !properties ||
    !(
      properties['@type'] === 'unite_representative' ||
      props.contentType === 'unite_representative'
    )
  ) {
    return (
      <div>
        This block is reserved for Unite Representative content type only.
      </div>
    );
  }

  return <RepresentativeInfo item={properties} />;
};

export default UniteRepresentativeBlockView;
