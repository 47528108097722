import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { isInternalURL } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import axios from 'axios';

import { ImArrowDownRight2 } from 'react-icons/im';

import Slider from '../../components/Slider/Slider';
import ResourceCard from '../../components/Resource/ResourceCard';
import { fetchAudience } from '../../../actions/audience';

const View = (props) => {
  const [resources, setResources] = useState([]);
  const { data } = props;
  const dispatch = useDispatch();

  // Accede a los datos de audiencia desde Redux
  const fetchAndMapAudience = useSelector(
    (state) => state.audience?.items || [],
    shallowEqual,
  );

  // Si `fetchAndMapAudience` aún no está disponible, se despacha la acción para obtenerlo
  useEffect(() => {
    if (fetchAndMapAudience.length === 0) {
      dispatch(fetchAudience()); // Despachar la acción si no hay audiencia cargada
    }
  }, [dispatch, fetchAndMapAudience.length]);

  // useEffect para obtener los recursos
  useEffect(() => {
    const getResources = async () => {
      const queryURL = '/++api++/@querystring-search';

      let query = [
        {
          i: 'portal_type',
          o: 'plone.app.querystring.operation.string.is',
          v: ['unite_resource'],
        },
        {
          i: 'review_state',
          o: 'plone.app.querystring.operation.selection.any',
          v: ['published'],
        },
      ];

      if (data.keywords && data.keywords.length > 0) {
        query.push({
          i: 'Subject',
          o: 'plone.app.querystring.operation.list.contains',
          v: data.keywords,
        });
      }

      // Verificar si fetchAndMapAudience y data.audience están definidos
      let mappedAudience = [];
      if (
        fetchAndMapAudience.length > 0 &&
        data.audience &&
        data.audience.length > 0
      ) {
        mappedAudience = data.audience
          .map(
            (aud) =>
              fetchAndMapAudience.find((item) => item.text === aud)?.['@id'],
          )
          .filter(Boolean);
      }

      if (mappedAudience.length > 0) {
        query.push({
          i: 'audience',
          o: 'plone.app.querystring.operation.list.contains',
          v: mappedAudience,
        });
      }

      try {
        const response = await axios.post(queryURL, {
          query: query,
          metadata_fields: '_all',
          fullobjects: 'True',
          limit: data.limit,
          sort_on: 'resource_date',
          sort_order: 'descending',
          b_size: data.limit,
        });

        const items = response.data.items;
        setResources(items);
      } catch (error) {
        console.error('Error fetching resources', error);
      }
    };

    getResources();
  }, [data, fetchAndMapAudience]);

  let resource_cards = [];
  resources.map((resource) =>
    resource_cards.push(
      <ResourceCard
        key={resource.UID}
        title={resource.title}
        description={resource.description}
        date={resource.resource_date}
        related_info={resource.relatedItems}
        links_download={resource.resource_links_downloads}
        categories={resource.subjects}
      />,
    ),
  );

  return (
    <div className="mb-unite">
      {resource_cards && resource_cards.length > 0 && (
        <>
          <Row className="mb-5">
            <Col md={12}>
              <Row className="mb-4">
                <Col md={5} className="mb-sm-4">
                  {data.title ? <h2>{data.title}</h2> : null}
                </Col>
                <Col md={{ span: 6, offset: 1 }}>
                  {data.description ? <p>{data.description}</p> : null}
                </Col>
              </Row>
              <Row>
                <Col
                  md={6}
                  className="d-flex justify-content-start align-items-end"
                >
                  {data.link &&
                    data.btn_text &&
                    (isInternalURL(data.link[0]?.['@id']) ? (
                      <Link
                        to={flattenToAppURL(data.link[0]?.['@id'])}
                        {...(data.openInNewTab && { target: '_blank' })}
                        className="link-readmore"
                      >
                        <ImArrowDownRight2 className="me-2" />
                        {data.btn_text}
                      </Link>
                    ) : (
                      <a
                        href={data.link[0]?.['@id']}
                        rel="noopener noreferrer"
                        {...(data.openInNewTab && { target: '_blank' })}
                        className="text-block"
                      >
                        <ImArrowDownRight2 className="me-2" />
                        {data.btn_text}
                      </a>
                    ))}
                </Col>
              </Row>
            </Col>
          </Row>
          <Slider
            id="resources"
            items={resource_cards}
            items_col="4"
            items_md_col="6"
            items_xs_col="12"
            rotate_color={true}
          />
        </>
      )}
    </div>
  );
};

export default View;
