import React, { useEffect } from 'react';

const View = (props) => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (
      !document.body.classList.contains('section-edit') &&
      !urlParams.has('sfr')
    ) {
      window.location.href = window.location.href + '?sfr';
    }
  }, []);

  return;
};

export default View;
