export const MarqueeFieldsets = ['animationSpeed', 'textBlocks'];

export const MarqueeProperties = (props) => {
  return {
    animationSpeed: {
      title: 'Animation speed (in seconds)',
      widget: 'text',
    },
    textBlocks: {
      title: 'Text Blocks',
      widget: 'object_list',
      schema: MarqueeItemsSchema(props),
      activeObject: props.activeObject,
      setActiveObject: props.setActiveObject,
    },
  };
};

export const MarqueeItemsSchema = (props) => {
  return {
    title: 'Item',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['text', 'link', 'openInNewTab'],
      },
    ],
    properties: {
      text: {
        title: 'Text',
        widget: 'text',
      },
      link: {
        title: 'Internal Link',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      openInNewTab: {
        title: 'Open link in new tab',
        type: 'boolean',
      },
    },
    required: ['text'],
  };
};
