import React from 'react';

import './less/View.less';

const View = (props) => {
  const { data } = props;

  if (!data.url_youtube) {
    return null; // No renderiza nada si no hay URL de YouTube
  }

  const iframeHTML = `
    <div class="ratio ratio-21x9">
      <iframe class="rounded-4" src="${data.url_youtube}" title="YouTube video player"></iframe>
    </div>
  `;

  return (
    <div className="mb-unite">
      <div dangerouslySetInnerHTML={{ __html: iframeHTML }} />
    </div>
  );
};

export default View;
