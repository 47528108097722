import React from 'react';

import './less/View.less';
import Icon from '../../../Utils/Icon/Icon';

const View = (props) => {
  const { data } = props;

  return (
    <div className="more-information-unite bg-light text-decoration-none p-4 mb-3 rounded-4">
      {data.show_icon && (
        <Icon className="float-end ms-3" iconName="info-circle" size={24} />
      )}
      {data.text && (
        <div dangerouslySetInnerHTML={{ __html: data.text.data }} />
      )}
    </div>
  );
};

export default View;
