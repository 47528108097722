// Este es un schema normal. Servirá de schema para los subbloques.
export const SliderBlockSettingsSchema = (props) => {
  return {
    title: 'Slider',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'title',
          'description',
          'image',
          'image_alt',
          'video',
          'video_description',
          'link',
          'text_link',
          'openInNewTab',
        ],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        type: 'string',
        widget: 'richtext',
      },
      description: {
        title: 'Description',
        widget: 'textarea',
      },
      image: {
        title: 'Image',
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
        selectedItemAttrs: ['image_field', 'image_scales'],
        allowedContentTypes: ['Image'],
      },
      image_alt: {
        title: 'Alt image',
        widget: 'text',
      },
      video: {
        title: 'Video',
        description: 'Only visible if image is not set',
        widget: 'object_browser',
        allowedContentTypes: ['File'],
        maximumSelectionSize: 1,
      },
      video_description: {
        title: 'Video description',
        widget: 'textarea',
      },
      link: {
        title: 'Internal Link',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      text_link: {
        title: 'Text Link',
        description:
          'If nothing is entered, the default value is More Information',
        widget: 'text',
      },
      openInNewTab: {
        title: 'Open link in new tab',
        type: 'boolean',
      },
    },
    required: [],
  };
};

/*
Este schema engloba sus propios campos (Título del bloque + Color de fondo)
Y además da una lista de bloques de 'texto' de tamaño indefinido. Se
Crea un campo para esto, con widget 'object_list' y se le asigna un schema para
cada objeto (o subloque) de esta lista
*/

export const SliderHomeBlockSchema = (props) => {
  return {
    title: 'SliderHome Block',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'text',
          'link',
          'openInNewTab',
          'label',
          'onlySliders',
          'sliderBlocks',
        ],
      },
    ],
    properties: {
      text: {
        title: 'Text',
        widget: 'text',
      },
      link: {
        title: 'Internal Link',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      openInNewTab: {
        title: 'Open link in new tab',
        type: 'boolean',
      },
      label: {
        title: 'Label',
        widget: 'text',
      },
      onlySliders: {
        title: 'Only Sliders',
        type: 'boolean',
        default: false,
      },
      sliderBlocks: {
        title: 'Slider Blocks',
        widget: 'object_list',
        schema: SliderBlockSettingsSchema(props),
        activeObject: props.activeObject,
        setActiveObject: props.setActiveObject,
      },
    },
    required: [],
  };
};

export default SliderHomeBlockSchema;
