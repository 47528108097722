// Este es un schema normal. Servirá de schema para los subbloques.
export const FaqsSettingsSchema = (props) => {
  return {
    title: 'Faqs',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['question', 'answer', 'tags'],
      },
    ],
    properties: {
      question: {
        title: 'Question',
        widget: 'textarea',
      },
      answer: {
        title: 'Answer',
        widget: 'richtext',
      },
      tags: {
        title: 'Audience',
        description: 'Add audience to categorize your FAQ',
        widget: 'token',
        vocabulary: {
          '@id': '/@vocabularies/unite.audience',
        },
      },
    },
    required: [],
  };
};

/*
Este schema engloba sus propios campos (Título del bloque + Color de fondo)
Y además da una lista de bloques de 'texto' de tamaño indefinido. Se
Crea un campo para esto, con widget 'object_list' y se le asigna un schema para
cada objeto (o subloque) de esta lista
*/

export const FaqsBlockSchema = (props) => {
  return {
    title: 'Faqs Block',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'showAudiences', 'FaqsBlocks'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      showAudiences: {
        title: 'Show Audiences',
        type: 'boolean',
        default: true,
      },
      FaqsBlocks: {
        title: 'Faqs Blocks',
        widget: 'object_list',
        schema: FaqsSettingsSchema(props),
        activeObject: props.activeObject,
        setActiveObject: props.setActiveObject,
      },
    },
    required: [],
  };
};

export default FaqsBlockSchema;
