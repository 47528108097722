/**
 * Ajusta el brillo de un color hexadecimal.
 *
 * @param {string} color - El color en formato hexadecimal a ajustar. Debe comenzar con '#'.
 * @param {number} amount - La cantidad a ajustar. Puede ser positiva para aclarar o negativa para oscurecer.
 * @returns {string} El color ajustado en formato hexadecimal.
 */

export function colorAdjust(color, amount) {
  return (
    '#' +
    color
      .replace(/^#/, '')
      .replace(/../g, (color) =>
        (
          '0' +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2),
      )
  );
}
