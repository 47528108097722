import {
  MarqueeFieldsets,
  MarqueeProperties,
} from '../../components/Marquee/Schema';

export const MarqueeBlockSchema = (props) => {
  return {
    title: 'Marquee Block',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [...MarqueeFieldsets],
      },
    ],
    properties: {
      ...MarqueeProperties(props),
    },
    required: [],
  };
};

export default MarqueeBlockSchema;
