/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { Link } from 'react-router-dom';

import { When } from '@plone/volto/components/theme/View/EventDatesInfo';
import { flattenToAppURL } from '@plone/volto/helpers';

import Slider from '../../components/Slider/Slider';

import { ImArrowDownRight2 } from 'react-icons/im';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import axios from 'axios';
import { fetchAudience } from '../../../actions/audience';


import './less/View.less';

const View = (props) => {
  const { data } = props;
  const [results, setResults] = useState([]);
  const dispatch = useDispatch();

  // Accede a los datos de audiencia desde Redux
  const fetchAndMapAudience = useSelector(
    (state) => state.audience?.items || [],
    shallowEqual,
  );

  // Si `fetchAndMapAudience` aún no está disponible, se despacha la acción para obtenerlo
  useEffect(() => {
    if (fetchAndMapAudience.length === 0) {
      dispatch(fetchAudience()); // Despachar la acción si no hay audiencia cargada
    }
  }, [dispatch, fetchAndMapAudience.length]);

  useEffect(() => {
    // Obtenemos todos los contenidos de tipo 'unite.testimonial'
    const getResults = async () => {
      const queryURL = '/++api++/@querystring-search';

      let query = [
        {
          i: 'portal_type',
          o: 'plone.app.querystring.operation.string.is',
          v: ['Event'],
        },
        {
          i: 'review_state',
          o: 'plone.app.querystring.operation.selection.any',
          v: ['published'],
        },
        {
          i: 'end',
          o: 'plone.app.querystring.operation.date.afterToday',
          v: [],
        },
      ];

      if (data.keywords && data.keywords.length > 0) {
        query.push({
          i: 'Subject',
          o: 'plone.app.querystring.operation.list.contains',
          v: data.keywords,
        });
      }
      // Verificar si fetchAndMapAudience y data.audience están definidos
      let mappedAudience = [];
      if (
        fetchAndMapAudience.length > 0 &&
        data.audience &&
        data.audience.length > 0
      ) {
        mappedAudience = data.audience
          .map(
            (aud) =>
              fetchAndMapAudience.find((item) => item.text === aud)?.['@id'],
          )
          .filter(Boolean);
      }

      if (mappedAudience.length > 0) {
        query.push({
          i: 'audience',
          o: 'plone.app.querystring.operation.list.contains',
          v: mappedAudience,
        });
      }

      try {
        const response = await axios.post(queryURL, {
          query: query,
          metadata_fields: '_all',
          limit: data.limit,
          sort_on: 'start',
          b_size: data.limit,
        });

        const items = response.data.items;
        setResults(items);
      } catch (error) {
        console.error('Error fetching events', error);
      }
    };

    getResults();
  }, [data.limit, data.keywords]);

  let resultsHtml = [];
  results.forEach((item) => {
    resultsHtml.push(
      <Card key={item['@id']} className="mb-4 event-card bg-unite-card">
        <Card.Body>
          <Card.Text className="badge rounded-3 card-text fs-xxxl mb-0 text-start">
            <When
              start={item.start}
              end={item.end}
              whole_day={item.whole_day}
              open_end={item.open_end}
            />
          </Card.Text>
          <Card.Title>{item.title}</Card.Title>
          <Card.Text
            className="fs-l text-truncate-x"
            style={{ '--text-lines': 5 }}
          >
            {item.description}
          </Card.Text>
          <Card.Text>
            <Link className="fs-xl" to={flattenToAppURL(item['@id'])}>
              <ImArrowDownRight2 className="me-2" />
              Read more
            </Link>
          </Card.Text>
        </Card.Body>
      </Card>,
    );
  });

  return (
    <div className="events-wrapper mb-unite">
      {resultsHtml && resultsHtml.length > 0 && (
        <>
          <Row className="mb-4">
            <Col md={5} className="mb-sm-4">
              {data.title && <h2>{data.title}</h2>}
              {data.link && data.link[0] && (
                <Link
                  className="link-readmore"
                  to={flattenToAppURL(data.link[0]['@id'])}
                >
                  <ImArrowDownRight2 className="me-2" />
                  See all Events
                </Link>
              )}
            </Col>
            {data.description && (
              <Col md={{ span: 6, offset: 1 }}>
                <p>{data.description}</p>
              </Col>
            )}
          </Row>
          <Slider
            id="events"
            items={resultsHtml}
            items_col="6"
            items_md_col="12"
          />
        </>
      )}
    </div>
  );
};

export default View;
