import {
  MarqueeFieldsets,
  MarqueeProperties,
} from '../../components/Marquee/Schema';

export const SectionMarqueeBlockSchema = (props) => {
  return {
    title: 'Section Marquee Block',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'title',
          'description',
          'image',
          'image_caption',
          ...MarqueeFieldsets,
        ],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      description: {
        title: 'Description',
        widget: 'textarea',
      },
      image: {
        title: 'Image',
        description: 'This block is designed to insert a vertical image',
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
        selectedItemAttrs: ['image_field', 'image_scales'],
        allowedContentTypes: ['Image'],
      },
      image_caption: {
        title: 'Image alt',
      },
      ...MarqueeProperties(props),
    },
    required: ['text'],
  };
};

export default SectionMarqueeBlockSchema;
