// Este es un schema normal. Servirá de schema para los subbloques.
export const TabsSettingsSchema = (props) => {
  return {
    title: 'Tabs',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'description', 'image', 'image_alt'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'textarea',
      },
      description: {
        title: 'Description',
        widget: 'richtext',
      },
      image: {
        title: 'Image',
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
        selectedItemAttrs: ['image_field', 'image_scales'],
        allowedContentTypes: ['Image'],
      },
      image_alt: {
        title: 'Alt image',
        widget: 'text',
      },
    },
    required: [],
  };
};

/*
Este schema engloba sus propios campos (Título del bloque + Color de fondo)
Y además da una lista de bloques de 'texto' de tamaño indefinido. Se
Crea un campo para esto, con widget 'object_list' y se le asigna un schema para
cada objeto (o subloque) de esta lista
*/

export const TabsBlockSchema = (props) => {
  return {
    title: 'Tabs Block',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'TabsBlocks'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      TabsBlocks: {
        title: 'Tabs Blocks',
        widget: 'object_list',
        schema: TabsSettingsSchema(props),
        activeObject: props.activeObject,
        setActiveObject: props.setActiveObject,
      },
    },
    required: [],
  };
};

export default TabsBlockSchema;
