import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
  When,
  Recurrence,
} from '@plone/volto/components/theme/View/EventDatesInfo';

import './less/View.less';

const UniteSummaryBlockView = (props) => {
  const { properties } = props;

  return (
    <>
      <Row className="mb-4">
        <Col>
          {properties.description && (
            <p className="description">{properties.description}</p>
          )}
        </Col>
      </Row>
    </>
  );
};

export default UniteSummaryBlockView;
