import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import View from './View';
import { Segment } from 'semantic-ui-react';
import { SidebarPortal } from '@plone/volto/components';
import Faqs from './Data';

const Edit = (props) => {
  const { data, onChangeBlock, block, selected } = props;
  const [textIndex, setTextIndex] = React.useState(0);

  return (
    <>
      <View
        {...props}
        isEditMode
        textIndex={textIndex}
        setTextIndex={setTextIndex}
      />
      <SidebarPortal selected={selected}>
        <Segment.Group raised>
          <Faqs
            {...props}
            data={data}
            block={block}
            onChangeBlock={onChangeBlock}
            activeObject={textIndex}
            setActiveObject={setTextIndex}
          />
        </Segment.Group>
      </SidebarPortal>
    </>
  );
};

export default withBlockExtensions(Edit);
