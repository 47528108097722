import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getNavroot } from '@plone/volto/actions';
import { hasApiExpander, getBaseUrl } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';

import zoomSVG from '@plone/volto/icons/zoom.svg';

const SearchWidget = (props) => {
  const dispatch = useDispatch();

  const pathname = props.pathname;

  const navroot = useSelector((state) => state.navroot?.data);
  useEffect(() => {
    if (!hasApiExpander('navroot', getBaseUrl(pathname))) {
      dispatch(getNavroot(getBaseUrl(pathname)));
    }
  }, [dispatch, pathname]);

  return (
    <Link
      aria-label="Search"
      to="/search-unite"
      onClick={() => {
        const button = document.querySelector('button.hamburger.is-active');
        if (button) {
          button.click();
        }
      }}
      className="icon-link rounded-circle d-flex justify-content-center px-2"
    >
      <Icon name={zoomSVG} size="20px" />
      <span className="visually-hidden">Search</span>
    </Link>
  );
};

export default SearchWidget;
