import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { UniversalLink } from '@plone/volto/components';
import { Container } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { List } from 'semantic-ui-react';
import {
  When,
  Recurrence,
} from '@plone/volto/components/theme/View/EventDatesInfo';

import { BsBoxArrowUpRight } from 'react-icons/bs';

import axios from 'axios';

import './less/View.less';

const messages = defineMessages({
  what: {
    id: 'event_what',
    defaultMessage: 'What',
  },
  when: {
    id: 'event_when',
    defaultMessage: 'When',
  },
  allDates: {
    id: 'event_alldates',
    defaultMessage: 'All dates',
  },
  downloadEvent: {
    id: 'Download Event',
    defaultMessage: 'Download Event',
  },
  where: {
    id: 'event_where',
    defaultMessage: 'Location',
  },
  contactName: {
    id: 'event_contactname',
    defaultMessage: 'Contact',
  },
  contactPhone: {
    id: 'event_contactphone',
    defaultMessage: 'Telephone',
  },
  attendees: {
    id: 'event_attendees',
    defaultMessage: 'Attendees',
  },
  website: {
    id: 'event_website',
    defaultMessage: 'Url',
  },
  visitWebsite: {
    id: 'visit_external_website',
    defaultMessage: 'Visit external website',
  },
  format: {
    id: 'event_format',
    defaultMessage: 'Format',
  },
  audience: {
    id: 'event_audience',
    defaultMessage: 'Audience',
  },
  media: {
    id: 'event_media',
    defaultMessage: 'Media',
  },
  university: {
    id: 'event_university',
    defaultMessage: 'University',
  },
  eventlanguage: {
    id: 'event_language',
    defaultMessage: 'Event Language',
  },
  contactInformation: {
    id: 'event_contactinformation',
    defaultMessage: 'Contact Information',
  },
});

const EventMetadataView = (props) => {
  const content = props.properties;
  const intl = useIntl();
  const [showAllAttendees, setShowAllAttendees] = useState(false);

  const attendeesToShow = showAllAttendees
    ? content?.attendees
    : content?.attendees?.slice(0, 3);

  const [audienceObjects, setAudienceObjects] = useState([]);
  const [formats, setFormats] = useState([]);
  const [universities, setUniversities] = useState([]);

  useEffect(() => {
    const getAudience = async () => {
      const partnerURL = '/++api++/@audience';
      try {
        const response = await axios.get(partnerURL);
        const items = response.data.items;
        setAudienceObjects(items);
      } catch (error) {
        console.error('Error fetching header top links', error);
      }
    };
    const getFormats = async () => {
      const queryURLFormats = '/++api++/@vocabularies/unite.format?b_size=-1';
      try {
        const responseFormats = await axios.get(queryURLFormats);
        setFormats(responseFormats.data.items);
      } catch (error) {
        console.error('Error fetching unite.format', error);
      }
    };

    const getUniversities = async () => {
      const queryURLUniversities =
        '/++api++/@vocabularies/unite.university?b_size=-1';
      try {
        const responseUniversities = await axios.get(queryURLUniversities);
        setUniversities(responseUniversities.data.items);
      } catch (error) {
        console.error('Error fetching unite.university', error);
      }
    };

    getFormats();
    getUniversities();
    getAudience();
  }, []);

  const universitiesObject = universities.find(
    (universitiesItem) => universitiesItem['token'] === content?.university,
  );

  const formatsObject = formats.find(
    (formatsItem) => formatsItem['token'] === content?.formats,
  );

  return (
    <div className="block eventMetadata ">
      <Container className="details-container">
        <div className="event-details mb-3">
          <div className="event-block py-3 border-top border-dark">
            <div className="event-heading fs-xs fw-bolder text-uppercase mb-1">
              {intl.formatMessage(messages.when)}
            </div>
            <div className="event-detail">
              <When
                start={content.start}
                end={content.end}
                whole_day={content.whole_day}
                open_end={content.open_end}
              />
            </div>
          </div>
          {content?.recurrence && (
            <div className="event-block py-3 border-top border-dark">
              <div className="event-heading fs-xs fw-bolder text-uppercase mb-1">
                {intl.formatMessage(messages.allDates)}
              </div>
              <div className="event-detail">
                <Recurrence
                  recurrence={content.recurrence}
                  start={content.start}
                />
              </div>
            </div>
          )}
          {content?.location && (
            <div className="event-block py-3 border-top border-dark">
              <div className="event-heading fs-xs fw-bolder text-uppercase mb-1">
                {intl.formatMessage(messages.where)}
              </div>
              <div className="event-detail">{content?.location}</div>
            </div>
          )}
          {content?.university && (
            <div className="event-block py-3 border-top border-dark">
              <div className="event-heading fs-xs fw-bolder text-uppercase mb-1">
                {intl.formatMessage(messages.university)}
              </div>
              <div className="event-detail">
                {universitiesObject?.title || content?.university.title}
              </div>
            </div>
          )}
          {content?.event_url && (
            <div className="event-block py-3 border-top border-dark">
              <div className="event-heading fs-xs fw-bolder text-uppercase mb-1">
                {intl.formatMessage(messages.website)}
              </div>
              <div className="event-detail">
                <UniversalLink
                  className="event-url py-1 px-3 rounded-pill d-flex align-items-center"
                  href={content.event_url}
                >
                  website <BsBoxArrowUpRight size={12} className="ms-2" />
                </UniversalLink>
              </div>
            </div>
          )}
          {content?.formats && (
            <div className="event-block py-3 border-top border-dark">
              <div className="event-heading fs-xs fw-bolder text-uppercase mb-1">
                {intl.formatMessage(messages.format)}
              </div>
              <div className="event-detail">
                <div className="d-flex flex-wrap">
                  {formatsObject?.title || content?.formats.title}
                </div>
              </div>
            </div>
          )}
          {content?.audience && (
            <div className="event-block py-3 border-top border-dark">
              <div className="event-heading fs-xs fw-bolder text-uppercase mb-1">
                {intl.formatMessage(messages.audience)}
              </div>
              <div className="event-detail">
                <div className="d-flex flex-wrap">
                  {content?.audience.map((item, index, array) => {
                    const audienceObject = audienceObjects.find(
                      (audienceItem) => audienceItem['@id'] === item,
                    );

                    return (
                      <span
                        key={audienceObject?.['@id'] || item.token}
                        className="py-1 px-3 me-2 mb-2 border border-dark rounded-pill"
                      >
                        {audienceObject?.text || item.title}
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          {content?.eventlanguage && (
            <div className="event-block py-3 border-top border-dark">
              <div className="event-heading fs-xs fw-bolder text-uppercase mb-1">
                {intl.formatMessage(messages.eventlanguage)}
              </div>
              <div className="event-detail">{content?.eventlanguage}</div>
            </div>
          )}
          {content.attendees?.length > 0 && (
            <div className="event-block py-3 border-top border-dark">
              <div className="event-heading fs-xs fw-bolder text-uppercase mb-1">
                {intl.formatMessage(messages.attendees)}
              </div>
              <div className="event-detail">
                <List items={attendeesToShow} />
                {content.attendees.length > 3 && !showAllAttendees && (
                  <a
                    className="fw-bolder cursor-pointer"
                    onClick={() => setShowAllAttendees(true)}
                  >
                    {intl.formatMessage({
                      id: 'readMore',
                      defaultMessage: 'READ MORE',
                    })}
                  </a>
                )}
              </div>
            </div>
          )}
        </div>

        {(content?.contact_name || content?.contact_phone) && (
          <div className="event-details">
            <div className="event-heading fw-bolder mb-3">
              {intl.formatMessage(messages.contactInformation)}
            </div>
            <div className="event-content-information text-white rounded-4 px-4 py-2">
              {content?.contact_name && (
                <div className="event-block py-3">
                  <div className="event-heading fs-xs fw-bolder text-uppercase mb-1">
                    {intl.formatMessage(messages.contactName)}
                  </div>
                  <div className="event-detail">
                    {content.contact_email ? (
                      <a
                        className="text-white"
                        href={`mailto:${content.contact_email}`}
                      >
                        {content.contact_name}
                      </a>
                    ) : (
                      content.contact_name
                    )}
                  </div>
                </div>
              )}
              {content?.contact_phone && (
                <div className="event-block py-3 border-top border-white">
                  <div className="event-heading fs-xs fw-bolder text-uppercase mb-1">
                    {intl.formatMessage(messages.contactPhone)}
                  </div>
                  <div className="event-detail">{content?.contact_phone}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default injectIntl(EventMetadataView);
