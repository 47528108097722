import React, { useState, useRef } from 'react';

import { Link } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { FaTimes } from 'react-icons/fa';
import { ImArrowDownRight2 } from 'react-icons/im';

import config from '@plone/volto/registry';
import { isInternalURL, flattenToAppURL } from '@plone/volto/helpers';
import PreviewImagePT from '../../Utils/PreviewImage/PreviewImagePT';

import './less/View.less';

const View = (props) => {
  const { data } = props;
  const [activeItem, setActiveItem] = useState(null);
  const [activeItemId, setActiveItemId] = useState(null);

  const anchorRef = useRef(null);

  function handleActiveItem(item, index = null) {
    setActiveItemId(index);
    setActiveItem(item);
    if (anchorRef.current) {
      anchorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  return (
    <>
      <Row className="mb-unite circles-wrapper py-5 px-4 d-flex align-items-center h-100 row-gap-3 rounded-4">
        <Col xl={5} lg={6} className="position-relative">
          <div
            ref={anchorRef}
            className={`scroll-adjust principal-circle w-100 unite-circle-content bg-white${
              activeItem ? ' active-circle' : ''
            }`}
          >
            {!activeItem ? (
              <>
                <p className="h5 fw-bold">{data.title}</p>
                {data.description && (
                  <p
                    dangerouslySetInnerHTML={{ __html: data.description.data }}
                  ></p>
                )}
              </>
            ) : (
              <>
                {activeItem.image && activeItem.image[0] && (
                  <>
                    <PreviewImagePT
                      item={activeItem.image[0]}
                      className="unite-circle-image"
                      alt={activeItem.image_alt || ''}
                      imageField="image"
                      loading="lazy"
                      decoding="async"
                    />
                  </>
                )}
                <div
                  className="background-circle"
                  style={{
                    '--color-pill': activeItem.pill_hexcolor
                      ? activeItem.pill_hexcolor
                      : '#644380',
                  }}
                ></div>
                <div className="content-circle text-white d-flex flex-column align-items-center">
                  <p className="h5 fw-bold">{activeItem.title}</p>
                  {activeItem.description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: activeItem.description.data,
                      }}
                    ></div>
                  )}
                  {activeItem.text_link && activeItem.link?.[0]?.['@id'] ? (
                    isInternalURL(activeItem.link[0]['@id']) ? (
                      <Link
                        className="btn-circle py-2 px-3 rounded-pill fw-bold bg-white"
                        to={flattenToAppURL(activeItem.link[0]['@id'])}
                        target={activeItem.openInNewTab ? '_blank' : '_self'}
                        aria-labelledby={activeItem.description_link}
                      >
                        <ImArrowDownRight2 className="me-2" />
                        {activeItem.text_link}
                      </Link>
                    ) : (
                      <a
                        className="btn-circle py-2 px-3 rounded-pill fw-bold bg-white"
                        href={activeItem.link[0]['@id']}
                        target={activeItem.openInNewTab ? '_blank' : '_self'}
                        aria-labelledby={activeItem.description_link}
                        rel="noopener noreferrer"
                      >
                        <ImArrowDownRight2 className="me-2" />
                        {activeItem.text_link}
                      </a>
                    )
                  ) : null}
                </div>
              </>
            )}
          </div>
          {activeItem && (
            <button
              aria-label="Close"
              className="close-btn unite-circle-content p-0 position-absolute bg-white"
              onClick={() => handleActiveItem(null)}
            >
              <FaTimes />
            </button>
          )}
        </Col>
        <Col xl={7} lg={6}>
          <Row className="d-flex flew-wrap row-gap-4 justify-content-center">
            {data.items &&
              data.items.map((item, index) => (
              <Col xl={4} lg={6} xs={6} key={index} className="px-4">
                <div
                  key={index}
                  className={`item-circle w-100 unite-circle-content bg-white${
                    activeItemId === index ? ' active-circle' : ''
                  }`}
                  style={{
                    '--color-pill': item.pill_hexcolor
                      ? item.pill_hexcolor
                      : '#644380',
                  }}
                  onClick={() => handleActiveItem(item, index)}
                >
                  {item.image && item.image[0] && (
                    <>
                      <PreviewImagePT
                        item={item.image[0]}
                        className="unite-circle-image"
                        alt={item.image_alt || ''}
                        imageField="image"
                        loading="lazy"
                        decoding="async"
                      />
                    </>
                  )}
                  <div className="background-circle"></div>
                  {item.title && (
                    <div className="item-pill py-1 px-3 rounded-pill fs-s text-white">
                      {item.title}
                    </div>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default View;
