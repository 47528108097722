import React from 'react';

import moment from 'moment';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Link } from 'react-router-dom';

import CarouselImageWithCaption from '../../components/Carousel/CarouselImageWithCaption/CarouselImageWithCaption';

import './less/View.less';

const UniteNewsItemBlockView = (props) => {
  const { data, properties } = props;

  if (
    !properties ||
    !(properties['@type'] === 'News Item' || props.contentType === 'News Item')
  ) {
    return <div> This block is reserved for News Item content type only. </div>;
  }

  let formattedDate = moment(
    properties.effective || properties.created || new Date(),
  ).format('D MMMM YYYY');

  let list_images = [];
  if (properties.image) {
    list_images.push({
      image: properties,
      image_caption: properties.image_caption || '',
      image_alt: properties.image_alt || '',
    });
  }

  if (data.images) {
    for (let img of data.images) {
      if (img.image) {
        list_images.push({
          image: img.image[0],
          image_caption: img.image_caption || '',
          image_alt: img.image_alt || '',
        });
      }
    }
  }

  return (
    <>
      <Row className="mb-4">
        {list_images && list_images.length > 0 && (
          <Col lg={6} md={12} className="mb-2">
            <CarouselImageWithCaption items={list_images} />
          </Col>
        )}
        <Col lg={list_images && list_images.length > 0 ? 6 : 12} md={12}>
          <div className="d-flex flex-wrap">
            {properties.subjects &&
              properties.subjects.length > 0 &&
              properties.subjects.map((item) => (
                <Link className="ui label" to={`/search-unite?Subject=${item}`}>
                  {item}
                </Link>
              ))}
            <span className="text-muted pt-1 mb-1">{formattedDate}</span>
          </div>
          <h1 className="mt-0 h2">{properties.title}</h1>
          {properties.description && (
            <p className="description">{properties.description}</p>
          )}
        </Col>
      </Row>
    </>
  );
};

export default UniteNewsItemBlockView;
