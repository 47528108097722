import React from 'react';
import Disclaimer from '../../components/Disclaimer/Disclaimer';
import { Container } from 'semantic-ui-react';

const UniteDisclaimerBlockView = (props) => {
  const { properties } = props;

  if (
    !properties ||
    !(
      properties['@type'] === 'unite_disclaimer' ||
      props.contentType === 'unite_disclaimer'
    )
  ) {
    return (
      <div>This block is reserved for Unite Disclaimer content type only.</div>
    );
  }

  return (
    <Container>
      <Disclaimer item={properties} />
    </Container>
  );
};

export default UniteDisclaimerBlockView;
