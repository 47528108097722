export const SectionSettingsSchema = (props) => {
  return {
    required: [],
    title: 'Button',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['btn_text', 'link', 'openInNewTab'],
      },
    ],
    properties: {
      btn_text: {
        title: 'Button text',
        widget: 'text',
      },
      link: {
        title: 'Button URL',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      openInNewTab: {
        title: 'Open link in new tab',
        type: 'boolean',
      },
    },
  };
};

/*
Este schema engloba sus propios campos (Título del bloque + Color de fondo)
Y además da una lista de bloques de 'texto' de tamaño indefinido. Se
Crea un campo para esto, con widget 'object_list' y se le asigna un schema para
cada objeto (o subloque) de esta lista
*/

export const SectionBlockSchema = (props) => {
  return {
    title: 'Section Block',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'description', 'SectionBlocks'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      description: {
        title: 'Description',
        widget: 'textarea',
      },
      SectionBlocks: {
        title: 'Button Blocks',
        widget: 'object_list',
        schema: SectionSettingsSchema(props),
        activeObject: props.activeObject,
        setActiveObject: props.setActiveObject,
      },
    },
    required: [],
  };
};

export default SectionBlockSchema;
