import React from 'react';
import config from '@plone/volto/registry';
import Disclaimer from '../../components/Disclaimer/Disclaimer';
import { Container } from 'semantic-ui-react';

const View = (props) => {
  const { data } = props;

  const selectedDisclaimer = data.disclaimer;

  // Verificar si selectedDisclaimer está vacío o no definido
  if (!selectedDisclaimer || selectedDisclaimer.length === 0) {
    // Manejar el caso cuando selectedDisclaimer está vacío o no definido
    console.log('No se ha seleccionado ningún disclaimer.');
  }

  return (
    <Container>
      <Disclaimer item={selectedDisclaimer} />
    </Container>
  );
};

export default View;
