import React, { useState } from 'react';

import { BsArrowLeft } from 'react-icons/bs';
import { BsArrowRight } from 'react-icons/bs';

import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import { Modal } from 'react-bootstrap';

import './less/CarouselImageWithCaption.less';
import PreviewImagePT from '../../../Utils/PreviewImage/PreviewImagePT';

// items acepta un diccionario con las siguientes propiedades:
// - image: objeto con la imagen
// - image_caption: Texto de la imagen
// - image_alt: Texto alternativo de la imagen

const CarouselImageWithCaption = ({ items }) => {
  const [showModal, setShowModal] = useState(false);
  const [imageModal, setImageModal] = useState('');
  const [captionModal, setCaptionModal] = useState('');

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (e) => {
    setImageModal(e.target.src);
    setCaptionModal(e.target.getAttribute('data-caption'));
    setShowModal(true);
  };

  return (
    <>
      {items.length === 1 && (
        <>
          <PreviewImagePT
            key={0}
            onClick={handleShowModal}
            className="rounded-4 w-100 object-fit-contain"
            title="See larger image"
            alt={items[0].image_alt}
            item={items[0].image}
            imageField="image"
            data-caption={items[0].image_caption}
          />
          {items[0].image_caption && (
            <p className="text-muted mt-2 mb-1 fs-s">
              {items[0].image_caption}
            </p>
          )}
        </>
      )}
      {items.length > 1 && (
        <>
          <Carousel
            className="carousel-image-caption"
            interval={null}
            prevIcon={<BsArrowLeft size={40} className="slider-btn-left" />}
            nextIcon={<BsArrowRight size={40} className="slider-btn-right" />}
          >
            {items.map((item, index) => (
              <Carousel.Item key={index}>
                <PreviewImagePT
                  onClick={handleShowModal}
                  className="img-news-item rounded-4 w-100 object-fit-cover"
                  title="See larger image"
                  alt={item.image_alt}
                  item={item.image}
                  imageField="image"
                  data-caption={item.image_caption ? item.image_caption : ''}
                />
                <Carousel.Caption>
                  <p
                    className="text-muted mt-2 mb-1 text-truncate-x fs-s"
                    style={{ '--text-lines': 3 }}
                  >
                    {item.image_caption ? item.image_caption : ''}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </>
      )}
      <Modal show={showModal} onHide={handleCloseModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>{captionModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center align-items-center">
          <Image src={imageModal} alt={captionModal} fluid />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CarouselImageWithCaption;
