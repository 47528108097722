import React from 'react';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';

import moment from 'moment';

import Card from 'react-bootstrap/Card';

import { ImArrowDownRight2 } from 'react-icons/im';

import PreviewImagePT from '../../Utils/PreviewImage/PreviewImagePT';

import './less/ImageWithTextBelow.less';

const ImageWithTextBelow = ({ item, addAudiences = false }) => {
  return (
    <Card className="mb-3 w-100 image-with-text-below ">
      {item.image && (
        <Card.Header>
          <Link
            className="text-block-moreinfo"
            to={flattenToAppURL(item['@id'])}
          >
            <PreviewImagePT
              item={item}
              alt={item.image_alt || item.title}
              imageField="image"
              className="ui image rounded-4 w-100"
            />
          </Link>
        </Card.Header>
      )}
      <Card.Body>
        <div className="d-flex flex-wrap align-items-center row-gap-2 mb-3">
          {addAudiences &&
            item.audience &&
            item.audience.length > 0 &&
            item.audience.map((aud) => (
              <span className="py-1 px-3 me-2 border border-dark rounded-pill">
                {aud.title}
              </span>
            ))}
          <p>{moment(item.effective).format('D MMMM YYYY')}</p>
        </div>
        <Card.Title className="mt-0 pb-2 border-bottom">
          {item?.title}
        </Card.Title>
        {item?.description && <Card.Text>{item.description}</Card.Text>}
      </Card.Body>
      <Card.Footer>
        <Link className="text-block-moreinfo" to={flattenToAppURL(item['@id'])}>
          <ImArrowDownRight2 className="me-2" /> More Info
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default ImageWithTextBelow;
