import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

import NavItem from '@plone/volto/components/theme/Navigation/NavItem';

import './less/Navitems.less';

const NavItems = ({ items, lang, closeMenu }) => {
  const handleItemClick = (e, { name }) => {
    closeMenu();
    var dropdownmenu = document.getElementsByClassName(
      'hamburger hamburger--spin is-active',
    );
    dropdownmenu.length > 0 && dropdownmenu[0].classList.remove('is-active');
    var dropdownmenuitems = document.getElementsByClassName('mobile-menu');
    dropdownmenuitems.length > 0 &&
      dropdownmenuitems[0].classList.remove('mobile-menu-enter-done');
    dropdownmenuitems.length > 0 &&
      dropdownmenuitems[0].classList.add(
        'mobile-menu-exit',
        'mobile-menu-exit-active',
      );
  };

  return (
    <>
      {items.map((item, index) =>
        item && item.items && item.items.length > 0 ? (
          <div key={item.url}>
            <label id={`dropdown-label-${index}`} className="visually-hidden">
              {item.title}
            </label>
            <Dropdown
              text={item.title}
              className="item me-3"
              labeled={true}
              aria-labelledby={`dropdown-label-${index}`}
              id={`dropdown-menu-${index}`}
              key={item.url}
            >
              <Dropdown.Menu
                key={item.url}
                className={`rounded-4 py-2 overflow-hidden${
                  index >= items.length - 5 ? ' left' : ''
                }`}
              >
                {item.items.map((dropdownitem) => (
                  <React.Fragment key={dropdownitem.url}>
                    <Dropdown.Item
                      onClick={handleItemClick}
                      value={dropdownitem.title}
                      text={dropdownitem.title}
                      key={dropdownitem.url}
                      as={Link}
                      to={dropdownitem.url === '' ? '/' : dropdownitem.url}
                      className={`me-3${
                        dropdownitem.items && dropdownitem.items.length > 0
                          ? ' more-items'
                          : ''
                      }`}
                    />
                    {dropdownitem.items &&
                      dropdownitem.items.map((subdropdownitem) => (
                        <Dropdown.Item
                          onClick={handleItemClick}
                          value={subdropdownitem.title}
                          text={subdropdownitem.title}
                          key={subdropdownitem.url}
                          as={Link}
                          to={
                            subdropdownitem.url === ''
                              ? '/'
                              : subdropdownitem.url
                          }
                          className="me-0 ms-3"
                        />
                      ))}
                  </React.Fragment>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : (
          <NavItem item={item} lang={lang} key={item.url} />
        ),
      )}
    </>
  );
};

export default NavItems;
