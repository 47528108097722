/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React, { useEffect, useState } from 'react';

import axios from 'axios';

import config from '@plone/volto/registry';

import { Segment } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Icon from '../../../../components/Utils/Icon/Icon';
import './less/Footer.less';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { siteActions = [] } = useSelector(
    (state) => ({
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );

  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;
  const [partners, setPartners] = useState([]);
  const [footerInfo, setFooterInfo] = useState({});
  const [europeanLogo, setEuropeanLogo] = useState([]);

  useEffect(() => {
    const getPartners = async () => {
      const queryURL = '/++api++/@querystring-search';

      try {
        const response = await axios.post(queryURL, {
          query: [
            {
              i: 'portal_type',
              o: 'plone.app.querystring.operation.string.is',
              v: ['unite_partner'],
            },
            {
              i: 'review_state',
              o: 'plone.app.querystring.operation.selection.any',
              v: ['published'],
            },
          ],
          metadata_fields: '_all',
          fullobjects: 'True',
          sort_on: 'getObjPositionInParent',
          b_size: 100,
        });
        const items = response.data.items;
        setPartners(items);
      } catch (error) {
        console.error('Error fetching partners', error);
      }
    };

    const getEuropeanLogo = async () => {
      const queryURL = '/++api++/@querystring-search';

      try {
        const response = await axios.post(queryURL, {
          query: [
            {
              i: 'portal_type',
              o: 'plone.app.querystring.operation.string.is',
              v: ['Image'],
            },
            {
              i: 'path',
              o: 'plone.app.querystring.operation.string.relativePath',
              v: '/images/european-logos/european-logo',
            },
          ],
          metadata_fields: '_all',
          fullobjects: 'True',
          sort_on: 'getObjPositionInParent',
          limit: 1,
        });
        const items = response.data.items;
        setEuropeanLogo(items);
      } catch (error) {
        console.error('Error fetching partners', error);
      }
    };

    const getFooterInfo = async () => {
      const url = '/++api++/@footer-info';
      try {
        const response = await axios.get(url);
        const items = response.data;
        setFooterInfo(items);
      } catch (error) {
        console.error('Error fetching audience', error);
      }
    };

    getPartners();
    getEuropeanLogo();
    getFooterInfo();
  }, []);

  return (
    <Segment
      role="contentinfo"
      vertical
      padded
      inverted
      id="footer"
      aria-label="Footer"
      className="p-0"
    >
      <div className="footer-wrapper my-3 mx-5 py-4 px-5 rounded-4">
        {partners && partners.length > 0 && (
          <div className="footer-logos d-flex justify-content-between align-items-center flex-wrap column-gap-4 row-gap-3 mb-4">
            {partners.map((partner) => (
              <div key={partner.id} className="m-auto">
                <PreviewImage
                  item={partner.negative_logo}
                  alt={partner.title}
                  title={partner.title}
                  image_field="image"
                  imageField="image"
                  className="negative-logo w-auto"
                  responsive={true}
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        )}
        <Row className="footer-links border-top border-light pt-3 mt-3">
          {footerInfo.follow_us?.items?.length > 0 && (
            <Col lg={3} className="footer-follow-us">
              <h2>Follow us</h2>
              <div className="d-flex flex-wrap column-gap-3 row-gap-3">
                {footerInfo.follow_us.items.map((item) => (
                  <a
                    key={item.id}
                    aria-label={item.text}
                    title={item.text}
                    href={flattenToAppURL(item.link[0]['@id'])}
                    className="icon-link rounded-circle d-flex justify-content-center px-2"
                    rel="noopener noreferrer"
                    target={item.open_in_new_tab ? '_blank' : '_self'}
                  >
                    <Icon iconName={item.icon} size="20px" />
                    <span className="visually-hidden">{item.text}</span>
                  </a>
                ))}
              </div>
            </Col>
          )}
          {(footerInfo.useful_links?.col1?.items?.length > 0 ||
            footerInfo.useful_links?.col2?.items?.length > 0 ||
            footerInfo.useful_links?.col3?.items?.length > 0 ||
            footerInfo.useful_links?.col4?.items?.length > 0) && (
            <Col lg={9} className="footer-useful-links">
              <h2>Useful links</h2>
              <Row>
                <Col lg={3}>
                  <div className="border-start border-light ps-2 d-flex flex-column h-100">
                    {footerInfo.useful_links?.col1?.items.map((item) => (
                      item.link && item.link[0] && (
                        isInternalURL(flattenToAppURL(item.link[0]['@id'])) ? (
                          <Link
                            key={item.id}
                            aria-label={item.text}
                            title={item.text}
                            to={flattenToAppURL(item.link[0]['@id'])}
                            target={item.open_in_new_tab ? '_blank' : '_self'}
                          >
                            {item.text}
                          </Link>
                        ) : (
                          <a
                            key={item.id}
                            aria-label={item.text}
                            title={item.text}
                            href={flattenToAppURL(item.link[0]['@id'])}
                            target={item.open_in_new_tab ? '_blank' : '_self'}
                            rel="noopener noreferrer"
                          >
                            {item.text}
                          </a>
                        )
                      )
                    ))}
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="border-start border-light ps-2 d-flex flex-column h-100">
                    {footerInfo.useful_links?.col2?.items.map((item) => (
                      item.link && item.link[0] && (
                        isInternalURL(flattenToAppURL(item.link[0]['@id'])) ? (
                          <Link
                            key={item.id}
                            aria-label={item.text}
                            title={item.text}
                            to={flattenToAppURL(item.link[0]['@id'])}
                            target={item.open_in_new_tab ? '_blank' : '_self'}
                          >
                            {item.text}
                          </Link>
                        ) : (
                          <a
                            key={item.id}
                            aria-label={item.text}
                            title={item.text}
                            href={flattenToAppURL(item.link[0]['@id'])}
                            target={item.open_in_new_tab ? '_blank' : '_self'}
                            rel="noopener noreferrer"
                          >
                            {item.text}
                          </a>
                        )
                      )
                    ))}
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="border-start border-light ps-2 d-flex flex-column h-100">
                    {footerInfo.useful_links?.col3?.items.map((item) => (
                      item.link && item.link[0] && (
                        isInternalURL(flattenToAppURL(item.link[0]['@id'])) ? (
                          <Link
                            key={item.id}
                            aria-label={item.text}
                            title={item.text}
                            to={flattenToAppURL(item.link[0]['@id'])}
                            target={item.open_in_new_tab ? '_blank' : '_self'}
                          >
                            {item.text}
                          </Link>
                        ) : (
                          <a
                            key={item.id}
                            aria-label={item.text}
                            title={item.text}
                            href={flattenToAppURL(item.link[0]['@id'])}
                            target={item.open_in_new_tab ? '_blank' : '_self'}
                            rel="noopener noreferrer"
                          >
                            {item.text}
                          </a>
                        )
                      )
                    ))}
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="border-start border-light ps-2 d-flex flex-column h-100">
                    {footerInfo.useful_links?.col4?.items.map((item) => (
                      item.link && item.link[0] && (
                        isInternalURL(flattenToAppURL(item.link[0]['@id'])) ? (
                          <Link
                            key={item.id}
                            aria-label={item.text}
                            title={item.text}
                            to={flattenToAppURL(item.link[0]['@id'])}
                            target={item.open_in_new_tab ? '_blank' : '_self'}
                          >
                            {item.text}
                          </Link>
                        ) : (
                          <a
                            key={item.id}
                            aria-label={item.text}
                            title={item.text}
                            href={flattenToAppURL(item.link[0]['@id'])}
                            target={item.open_in_new_tab ? '_blank' : '_self'}
                            rel="noopener noreferrer"
                          >
                            {item.text}
                          </a>
                        )
                      )
                    ))}
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row className="footer-european border-top border-light pt-3 mt-3">
          <Col lg={10} className="d-flex align-items-center">
            <p className="fs-s">{footerInfo.european_text}</p>
          </Col>
          <Col lg={2} className="d-flex justify-content-end align-items-center">
            {europeanLogo.length > 0 && (
              <PreviewImage
                item={europeanLogo[0]}
                alt={europeanLogo[0].title}
                title={europeanLogo[0].title}
                image_field="image"
                imageField="image"
                className="negative-logo w-auto"
                responsive={true}
                loading="lazy"
              />
            )}
          </Col>
        </Row>
        <Row className="footer-bottom border-top border-light pt-3 mt-3">
          <Col lg={3} md={6} sm={6} xs={6} className="footer-firm">
            <p>{footerInfo.firm}</p>
          </Col>
          <Col lg={9} md={6} sm={6} xs={6} className="footer-links">
            <div className="d-flex flex-row flex-wrap column-gap-3 justify-content-end">
              {footerInfo.footer_links?.items.map((item) => (
                item.link && item.link[0] && (
                  isInternalURL(flattenToAppURL(item.link[0]['@id'])) ? (
                    <Link
                      key={item.id}
                      aria-label={item.text}
                      title={item.text}
                      to={flattenToAppURL(item.link[0]['@id'])}
                      target={item.open_in_new_tab ? '_blank' : '_self'}
                    >
                      {item.text}
                    </Link>
                  ) : (
                    <a
                      key={item.id}
                      aria-label={item.text}
                      title={item.text}
                      href={flattenToAppURL(item.link[0]['@id'])}
                      target={item.open_in_new_tab ? '_blank' : '_self'}
                      rel="noopener noreferrer"
                    >
                      {item.text}
                    </a>
                  )
                )
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
