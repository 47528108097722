import React from 'react';

import Marquee from '../../components/Marquee/Marquee';

const View = (props) => {
  const { data } = props;

  return (
    <Marquee
      data={data.textBlocks}
      has_bg={true}
      base_speed={data.animationSpeed}
    />
  );
};

export default View;
