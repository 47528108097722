export const AgoraFeaturedCatalogueBlockSchema = (props) => {
  return {
    title: 'Featured Catalogue Block',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['text'],
      },
    ],
    properties: {
      text: {
        title: 'Text',
        description: 'If none is added, it takes the content title',
        widget: 'text',
      },
    },
    required: [],
  };
};

export default AgoraFeaturedCatalogueBlockSchema;
