import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isInternalURL } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';

import { ImArrowDownRight2 } from 'react-icons/im';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import axios from 'axios';

import PartnerCard from '../../components/Partner/PartnerCard';
import PreviewImagePT from '../../Utils/PreviewImage/PreviewImagePT';

const View = (props) => {
  const [partners, setPartners] = useState([]);
  const { data } = props;

  useEffect(() => {
    // Obtenemos todos los contenidos de tipo 'unite_partner'
    const getPartners = async () => {
      const queryURL = '/++api++/@querystring-search';

      try {
        const response = await axios.post(queryURL, {
          query: [
            {
              i: 'portal_type',
              o: 'plone.app.querystring.operation.string.is',
              v: ['unite_partner'],
            },
            {
              i: 'review_state',
              o: 'plone.app.querystring.operation.selection.any',
              v: ['published'],
            },
          ],
          metadata_fields: '_all',
          fullobjects: 'True',
          sort_on: 'getObjPositionInParent',
          b_size: 100,
        });
        const items = response.data.items;
        setPartners(items);
      } catch (error) {
        console.error('Error fetching partners', error);
      }
    };

    getPartners();
  }, []);

  return (
    <div className="mb-unite">
      {partners && partners.length > 0 && (
        <>
          <Row className="mb-4">
            <Col md={8}>
              {data.title && <h2>{data.title}</h2>}
              {data.description && <p>{data.description}</p>}
            </Col>
            <Col md={4} className="d-flex justify-content-end align-items-end">
              {data.link &&
                data.btn_text &&
                (isInternalURL(data.link[0]?.['@id']) ? (
                  <Link
                    to={flattenToAppURL(data.link[0]?.['@id'])}
                    className="mt-3"
                    {...(data.openInNewTab && { target: '_blank' })}
                  >
                    <Button variant="primary">
                      <ImArrowDownRight2 className="me-2" />
                      {data.btn_text}
                    </Button>
                  </Link>
                ) : (
                  <a
                    href={data.link[0]?.['@id']}
                    rel="noopener noreferrer"
                    className="mt-3"
                    {...(data.openInNewTab && { target: '_blank' })}
                  >
                    <Button variant="primary">
                      <ImArrowDownRight2 className="me-2" />
                      {data.btn_text}
                    </Button>
                  </a>
                ))}
            </Col>
          </Row>
          {(data.title || data.description) && <hr className="hr pt-3" />}
          <Row>
            <Col lg={6} md={12}>
              <Row>
                {partners.map((partner) => {
                  return (
                    <Col md={6} sm={12}>
                      <PartnerCard
                        key={partner.UID}
                        id={partner.id}
                        name={partner.title}
                        location={partner.partner_location}
                        logo={partner.logo}
                        negative_logo={partner.negative_logo}
                        hexcolor={
                          typeof partner.partner_hexcolor === 'object' &&
                          partner.partner_hexcolor !== null
                            ? partner.partner_hexcolor['title'] || '#644380'
                            : partner.partner_hexcolor || '#644380'
                        }
                        url={partner.partner_url}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Col lg={6} md={12}>
              {data.map && data.map.length > 0 ? (
                <PreviewImagePT
                  item={data.map[0]}
                  imageField="image"
                  alt="Unite partners map"
                  title="Unite partners map"
                  className="img-fluid sticky-top"
                  loading="lazy"
                />
              ) : (
                <p className="text-muted fst-italic">No map image</p>
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default View;
