export const schemaWhatsNews = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['keywords'],
      },
    ],
    properties: {
      keywords: {
        title: 'Keywords',
        type: 'array',
        widget: 'array',
        items: {
          vocabulary: { '@id': 'plone.app.vocabularies.Keywords' },
        },
      },
      items_page: {
        title: 'Items per page',
        type: 'integer',
        default: 18,
      },
    },
  };
};

export default schemaWhatsNews;
