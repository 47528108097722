export const schemaPartners = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'title',
          'description',
          'btn_text',
          'link',
          'openInNewTab',
          'map',
        ],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      description: {
        title: 'Description',
        widget: 'textarea',
      },
      btn_text: {
        title: 'Button text',
        widget: 'text',
      },
      link: {
        title: 'Button URL',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      openInNewTab: {
        title: 'Open link in new tab',
        type: 'boolean',
      },
      map: {
        title: 'Map',
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
        selectedItemAttrs: ['image_field', 'image_scales'],
        allowedContentTypes: ['Image'],
      },
    },
  };
};

export default schemaPartners;
