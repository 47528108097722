// import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { isInternalURL } from '@plone/volto/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';

import { ImArrowDownRight2 } from 'react-icons/im';

import './less/Marquee.less';

/**
 * Componente Marquee
 *
 * @param {Array} data - Un array de objetos que representan los elementos del marquee. Cada objeto debe contener las siguientes propiedades:
 *    @property {string} text - El texto que se mostrará para el elemento del marquee.
 *    @property {string} link - El enlace al que se dirigirá al usuario cuando haga clic en el elemento del marquee.
 *    @property {boolean} openInNewTab - Un booleano que indica si el enlace se debe abrir en una nueva pestaña o no.
 * @param {number} base_speed - La velocidad base de desplazamiento del marquee. Un número más alto hará que el marquee se desplace más rápido.
 * @param {boolean} [has_bg] - Un booleano que indica si el marquee tiene un fondo o no. Si es verdadero, el marquee tendrá un fondo. Si es falso, el marquee no tendrá un fondo.
 */

const Marquee = ({ data, base_speed, has_bg = false }) => {
  const repeat = 3;

  const repeatedElements = [...Array(repeat)].map((_, i) => (
    <div
      key={`marquee-${i}`}
      className="d-flex marquee-animation"
      style={{
        '--marquee-speed': base_speed / repeat + 's',
        '--marquee-speed-mb': (base_speed * 2) / repeat + 's',
      }}
    >
      {data &&
        data.map((item) => (
          <div
            className={`text-block me-4${has_bg ? ' py-2' : ''}`}
            key={item['@id']}
          >
            {item.text}
            {item.link &&
              item.link.length > 0 &&
              (isInternalURL(item.link[0]?.getURL) ? (
                <Link
                  className="text-block-moreinfo"
                  to={flattenToAppURL(item.link[0]?.['@id'])}
                  {...(item.openInNewTab && { target: '_blank' })}
                >
                  <ImArrowDownRight2 className="mx-2" />
                  More info
                </Link>
              ) : (
                <a
                  className="text-block-moreinfo"
                  href={flattenToAppURL(item.link[0]?.['@id'])}
                  rel="noopener noreferrer"
                  {...(item.openInNewTab && { target: '_blank' })}
                >
                  <ImArrowDownRight2 className="mx-2" />
                  More info
                </a>
              ))}
          </div>
        ))}
    </div>
  ));

  return (
    <>
      <div
        className={`container-marquee w-100 d-flex${
          has_bg ? ' container-marquee-bg rounded-4 mb-unite p-3' : ''
        }`}
      >
        {repeatedElements}
      </div>
    </>
  );
};

export default Marquee;
