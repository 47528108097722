import config from '@plone/volto/registry';

export const BannerSchema = (props) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'title',
          'subtitle',
          'link',
          'description_link',
          'open_in_new_tab',
          'color',
          'highlighted',
        ],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        type: 'string',
        default: 'Banner title',
      },
      subtitle: {
        title: 'Subtitle',
        type: 'string',
      },
      link: {
        title: 'Link',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      description_link: {
        title: 'Description Link',
        widget: 'text',
      },
      open_in_new_tab: {
        title: 'Open link in new tab',
        type: 'boolean',
      },
      color: {
        title: 'Color',
        type: 'color',
        widget: 'style_simple_color',
        available_colors: config.settings.dark_colors,
      },
      highlighted: {
        title: 'Highlighted',
        type: 'boolean',
        default: false,
      },
    },
    required: ['title', 'link', 'color'],
  };
};

export default BannerSchema;
