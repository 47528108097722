import React from 'react';
import config from '@plone/volto/registry';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

import './less/TestimonialCard.less';

const TestimonialCard = ({ item }) => {
  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;

  return (
    <Card className="testimonial-card">
      <Card.Body className="d-flex flex-column justify-content-between">
        <Row>
          <div className="w-100 mb-4 d-flex justify-content-center align-items-center">
            {item.testimonial_image && (
              <PreviewImage
                item={item.testimonial_image}
                alt={item.title}
                image_field="image"
                imageField="image"
                className="ui image testimonial-img rounded-circle object-fit-cover"
                responsive={true}
                loading="lazy"
              />
            )}
          </div>
          <Card.Text className="fs-s mb-4">{`"${item.testimonial_quoted_text}"`}</Card.Text>
        </Row>
        <Row className="d-flex flex-column  align-items-end">
          <Card.Text className="fw-bold fs-xxl mb-1">{item.title}</Card.Text>
          <Card.Text className="mb-1 fst-italic">
            {item.testimonial_rol}
          </Card.Text>
          <Card.Text>{item.testimonial_university_name}</Card.Text>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TestimonialCard;
