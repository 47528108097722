export const MoreInformationSchema = (props) => {
  return {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['text', 'show_icon'],
      },
    ],
    properties: {
      text: {
        title: 'Text',
        type: 'string',
        widget: 'richtext',
        default: {
          data: '<p><strong>More information</strong></p><p><a href="/">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></p><p><a href="/">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</a></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur cursus <a href="/">aliquam tellus</a> quis semper. Quisque mollis felis a quam fermentum accumsan. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</p>',
        },
      },
      show_icon: {
        title: 'Show information icon',
        type: 'boolean',
      },
    },
    required: ['text'],
  };
};

export default MoreInformationSchema;
