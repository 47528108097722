import React, { useEffect, useState } from 'react';

import { flattenToAppURL } from '@plone/volto/helpers';

import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';

import './less/View.less';
import Icon from '../../Utils/Icon/Icon';
import PreviewImagePT from '../../Utils/PreviewImage/PreviewImagePT';
import Slider from '../../components/Slider/Slider';

const View = (props) => {
  const { data } = props;
  const [boxes, setBoxes] = useState([]);

  useEffect(() => {
    const getBoxes = async () => {
      if (data.items && data.items.length > 0) {
        const boxes = data.items.map((item) => (
          <Card className="mb-3 w-100 image-with-text-below" key={item.id}>
            {item?.image && (
              <Card.Header className="position-relative">
                {item?.link && item.link.length > 0 ? (
                  <a
                    className="text-block-moreinfo"
                    href={flattenToAppURL(item.link[0]['@id'])}
                    rel="noopener noreferrer"
                    {...(item.openInNewTab && { target: '_blank' })}
                  >
                    <PreviewImagePT
                      item={item.image[0]}
                      imageField="image"
                      alt={item.image_alt || ''}
                      className="ui image responsive rounded-4"
                      loading="lazy"
                      showDefault={true}
                    />
                    {item.showMediaIcon && (
                      <Icon
                        iconName="play-fill"
                        type="bs"
                        size={64}
                        className="text-white border border-white border-5 rounded-circle position-absolute top-50 start-50 translate-middle"
                      />
                    )}
                  </a>
                ) : (
                  <>
                    <PreviewImagePT
                      item={item.image[0]}
                      imageField="image"
                      alt={item.image_alt || ''}
                      className="ui image responsive rounded-4"
                      loading="lazy"
                      showDefault={true}
                    />
                    {item.showMediaIcon && (
                      <Icon
                        iconName="play-fill"
                        type="bs"
                        size={64}
                        className="text-white border border-white border-5 rounded-circle position-absolute top-50 start-50 translate-middle"
                      />
                    )}
                  </>
                )}
              </Card.Header>
            )}
            <Card.Body>
              {item?.title && (
                <Card.Title className="mt-0 pb-2 border-bottom">
                  {item.title}
                </Card.Title>
              )}
              {item?.description && (
                <Card.Text
                  dangerouslySetInnerHTML={{
                    __html: item.description.data,
                  }}
                ></Card.Text>
              )}
            </Card.Body>
          </Card>
        ));
        setBoxes(boxes);
      }
    };

    getBoxes();
  }, [data.items]);

  return (
    <Row className="mb-unite boxes-media-wrapper">
      {data.title ? (
        <h2 className="mb-5">{data.title}</h2>
      ) : (
        <div className="mb-5" aria-hidden="true"></div>
      )}
      {boxes.length > 0 && (
        <Slider
          id="boxes-media"
          items={boxes}
          items_col="4"
          items_md_col="6"
          items_xs_col="12"
        />
      )}
    </Row>
  );
};

export default View;
