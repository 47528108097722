// Este es un schema normal. Servirá de schema para los subbloques.
export const ProjectsSettingsSchema = (props) => {
  return {
    title: 'Projects',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'title',
          'description',
          'link',
          'description_link',
          'openInNewTab',
          'image',
          'image_alt',
        ],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'textarea',
      },
      description: {
        title: 'Description',
        type: 'string',
        widget: 'richtext',
      },
      link: {
        title: 'Internal Link',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
      description_link: {
        title: 'Description Link',
        widget: 'text',
      },
      openInNewTab: {
        title: 'Open link in new tab',
        type: 'boolean',
      },
      image: {
        title: 'Image',
        description: 'This block is designed to insert a vertical image',
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
        selectedItemAttrs: ['image_field', 'image_scales'],
        allowedContentTypes: ['Image'],
      },
      image_alt: {
        title: 'Image alt',
      },
    },
    required: [],
  };
};

/*
Este schema engloba sus propios campos (Título del bloque + Color de fondo)
Y además da una lista de bloques de 'texto' de tamaño indefinido. Se
Crea un campo para esto, con widget 'object_list' y se le asigna un schema para
cada objeto (o subloque) de esta lista
*/

export const ProjectsBlockSchema = (props) => {
  return {
    title: 'Projects Block',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'title',
          'description',
          'num_principal_projects',
          'ProjectsBlocks',
        ],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      description: {
        title: 'Description',
        type: 'string',
        widget: 'richtext',
      },
      num_principal_projects: {
        title: 'Number of principal projects',
        description: 'The rest of the projects are hidden through a see more',
        widget: 'number',
        default: 9,
      },
      ProjectsBlocks: {
        title: 'Projects Blocks',
        widget: 'object_list',
        schema: ProjectsSettingsSchema(props),
        activeObject: props.activeObject,
        setActiveObject: props.setActiveObject,
      },
    },
    required: [],
  };
};

export default ProjectsBlockSchema;
