import ResourceCard from '../../components/Resource/ResourceCard';

import { Container } from 'semantic-ui-react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const UniteResourceBlockView = (props) => {
  const { properties } = props;

  if (
    !properties ||
    !(
      properties['@type'] === 'unite_resource' ||
      props.contentType === 'unite_resource'
    )
  ) {
    return (
      <div> This block is reserved for Unite Resource content type only. </div>
    );
  }

  return (
    <Container>
      <Row>
        <Col sm={6} xs={12}>
          <ResourceCard
            key={properties.UID}
            title={properties.title}
            description={properties.description}
            date={properties.resource_date}
            related_info={properties.relatedItems}
            links_download={properties.resource_links_downloads}
            categories={properties.subjects}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default UniteResourceBlockView;
